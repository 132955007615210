import './Checkbox.style.scss'

import { useContext, useEffect, useState } from 'react'

import { exchangeApi } from 'api/exchange.api'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import moment from 'moment'
import { StyledDialog } from 'pages/Settings/Settings.style'
import {
	CurrencyBalance,
	CurrencyBlock,
	CurrencyName,
	StyledDialogForSelectCoin,
	StyledExchangeFormControl,
	StyledExchangeFormDesc,
	StyledExchangeFormInputText,
	StyledExchangeFormInputTitle,
	StyledExchangeFormSubmit,
} from 'pages/SimpleExchange/SampleExchange.styles'
import { StyledSearchIcon } from 'pages/Wallets/Wallets.styles'
import { CentrifugeContext } from 'providers/CentrifugeProvider'
import { Link, useLocation } from 'react-router-dom'
import { MarketPair } from 'types/exchange.types'

import CloseIcon from '@mui/icons-material/Close'
import {
	Box,
	FormControl,
	InputAdornment,
	MenuItem,
	Select,
} from '@mui/material'

import { setInsufficientBalance } from '../../store/exchange/exchange.slice'
import {
	ButtonsBlock,
	Currency,
	DialogContent,
	StyledCoinDialogTitle,
	StyledCurrencyBlock,
	StyledDialogDesc,
	StyledDialogTitle,
	StyledExchangeModalButton,
	StyledExchangeModalToWalet,
	StyledInputSearch,
	StyledSpenBlock,
	StyledSpenBlockAmount,
	StyledSpenBlockTitle,
} from './Select.style'

export const BuyTab = () => {
	const { walletBalance } = useTypedSelector(state => state.wallets)
	const [maxAmount, setMaxAmount] = useState<string | number | undefined>('')
	const [search, setSearch] = useState('')
	const [selectCoinModal, openSelectCoinModal] = useState(false)
	const [baseCurrency, setBaseCurrency] = useState('BTC')
	const [selectedQuoteCurrency, setSelectedQuoteCurrency] = useState('')
	const [quoteCurrency, setQuoteCurrency] = useState<MarketPair[]>([])
	const [showFee, setShowFee] = useState(false)
	const [amountMinimumError, setAmountMinimumError] = useState(false)
	const [amountErrorMessage, setAmountErrorMessage] = useState<string | null>(
		null
	)
	const [fee, setFee] = useState(0)
	const buyFee = useTypedSelector(state => state.profile.me?.volume?.maker_fee)
	const [accepConversionModalBuy, setAccepConversionModalBuy] = useState(false)
	const amount = walletBalance.data?.balances.filter(
		item => item.currency === selectedQuoteCurrency
	)
	const amountNumber = amount?.find(item => item.balance)
	const calculateFee = buyFee && (+buyFee * Number(maxAmount)) / 100
	const [buttonContent, setButtonContent] = useState('Preview conversion')
	const [reciveCount, setReciveCount] = useState<any>([])
	const [price, setPrice] = useState(0)
	const operation = 'buy'
	const dispatch = useAppDispatch()
	const socketContext = useContext(CentrifugeContext)
	const [partialFillednModalBuy, setPartialFillednModalBuy] = useState(false)
	const [flagForModal, setFlagForModal] = useState(true)
	const [flagForPartialFilledModal, setFlagForPartialFilledModal] =
		useState(true)
	const [executedValue, setExecutedValue] = useState('')
	const [defaultQuote, setDefaultQuote] = useState('EUR')
	useEffect(() => {
		if (socketContext.simpleExchange?.status === 'filled') {
			if (flagForModal && socketContext.simpleExchange?.side === 'buy') {
				setAccepConversionModalBuy(true)
				setFlagForModal(false)
			}
		}
	}, [socketContext.simpleExchange])

	useEffect(() => {
		if (socketContext.simpleExchange?.status === 'partial_filled') {
			setExecutedValue(socketContext.simpleExchange?.executedValue)
			if (
				flagForPartialFilledModal &&
				socketContext.simpleExchange?.side === 'buy'
			) {
				setPartialFillednModalBuy(true)
				setFlagForPartialFilledModal(false)
			}
		}
	}, [socketContext.simpleExchange])
	useEffect(() => {
		exchangeApi.simpleExchangeQuoteCoins(baseCurrency).then(res => {
			setQuoteCurrency(res.data.coins)
		})
	}, [])
	const history = useLocation()

	useEffect(() => {
		setAccepConversionModalBuy(false)
		setPartialFillednModalBuy(false)
	}, [history.pathname])
	const { insufficientError } = useTypedSelector(state => state.exchange)

	useEffect(() => {
		exchangeApi
			.simpleExchangeQuote({ baseCurrency, selectedQuoteCurrency, operation })
			.then(res => {
				setPrice(res.data.price)
			})
	}, [baseCurrency, selectedQuoteCurrency])

	const forOneEur = () => {
		return price && (1 / price).toFixed(5)
	}

	const computedwallet = (() => {
		if (!walletBalance) return []
		if (!search) return walletBalance?.data?.balances

		return walletBalance?.data?.balances.filter(
			market =>
				market.currency?.toLowerCase().includes(search.toLowerCase()) ||
				market.currency_full?.toLowerCase().includes(search.toLowerCase())
		)
	})()
	const handleQuoteCurrencyChange = (e: any) => {
		setSelectedQuoteCurrency(e.target.value)
		dispatch(setInsufficientBalance(false))
		setMaxAmount('')
		setAmountErrorMessage('')
		setAmountMinimumError(false)
		setButtonContent('Preview conversion')
		setReciveCount([])
	}
	const yourecive = reciveCount?.toString()
	const balance = amount?.map(item => item.balance).toString()
	let curBalance = Number(balance).toFixed(5)
	const handleSubmit = () => {
		exchangeApi
			.simpleExchange({
				Pair: {
					BaseCurrency: baseCurrency,
					QuoteCurrency: selectedQuoteCurrency,
				},
				Amount: maxAmount,
				Side: operation,
			})
			.then(res => {
				setButtonContent('Preview conversion')
			})
	}
	useEffect(() => {
		exchangeApi.simpleExchangeQuoteCoins(baseCurrency).then(res => {
			setQuoteCurrency(res.data.coins)
			if (res.data.coins.includes('EUR')) {
				setDefaultQuote('EUR')
				setSelectedQuoteCurrency('EUR')
			} else if (res.data.coins.includes('USDT')) {
				setDefaultQuote('USDT')
				setSelectedQuoteCurrency('USDT')
			} else {
				setDefaultQuote('')
				setSelectedQuoteCurrency('')
			}
		})
	}, [baseCurrency])
	const handleSelectBaseCoin = (item: any) => {
		openSelectCoinModal(false)
		setSearch('')
		setMaxAmount('')
		setReciveCount([])
		setButtonContent('Preview conversion')
		exchangeApi.simpleExchangeQuoteCoins(item.currency).then(res => {
			setQuoteCurrency(res.data.coins)
		})
		setBaseCurrency(item.currency)
	}
	const previevConversation = () => {
		const BTCMin = 0.001
		const ETHMin = 0.02
		const USDTMin = 15
		const BNBMin = 0.04
		const BUSDMin = 15
		const USDCMin = 15
		const EURMin = 15
		if (selectedQuoteCurrency === 'BTC' && maxAmount && maxAmount < BTCMin) {
			setAmountMinimumError(true)
			setAmountErrorMessage('Minimum 0,001 BTC')
			return true
		}
		if (selectedQuoteCurrency === 'ETH' && maxAmount && maxAmount < ETHMin) {
			setAmountMinimumError(true)
			setAmountErrorMessage('Minimum 0,02 ETH')
			return true
		}
		if (selectedQuoteCurrency === 'USDT' && maxAmount && maxAmount < USDTMin) {
			setAmountMinimumError(true)
			setAmountErrorMessage('Minimum 15 USDT')
			return true
		}

		if (selectedQuoteCurrency === 'BNB' && maxAmount && maxAmount < BNBMin) {
			setAmountMinimumError(true)
			setAmountErrorMessage('Minimum 0,04 BNB')
			return true
		}
		if (selectedQuoteCurrency === 'BUSD' && maxAmount && maxAmount < BUSDMin) {
			setAmountMinimumError(true)
			setAmountErrorMessage('Minimum 15 BUSD')
			return true
		}
		if (selectedQuoteCurrency === 'USDC' && maxAmount && maxAmount < USDCMin) {
			setAmountMinimumError(true)
			setAmountErrorMessage('Minimum 15 USDC')
			return true
		}
		if (selectedQuoteCurrency === 'EUR' && maxAmount && maxAmount < EURMin) {
			setAmountMinimumError(true)
			setAmountErrorMessage('Minimum 15 EUR')
			return true
		}
		if (+curBalance == 0.0) {
			dispatch(setInsufficientBalance(true))
		}
		if (amount && amount[0].balance < Number(maxAmount)) {
			dispatch(setInsufficientBalance(true))
		} else {
			const receive = () => {
				return price && Number(maxAmount) / +price
			}
			receive && setReciveCount(receive)
			setButtonContent('Accept conversion')
			setShowFee(true)
		}
		calculateFee && setFee(calculateFee)
		console.log(calculateFee)
	}
	const strToFixed = (str: string) => {
		if (str.split('.').length === 2) {
			if (str.indexOf('.') !== -1) {
				return str.split('.')[0] + '.' + str.split('.')[1].slice(0, 5)
			} else {
				return str.split(',')[0] + '.' + str.split(',')[1].slice(0, 5)
			}
		} else {
			return str
		}
	}

	return (
		<Box component={'form'}>
			<div style={{ marginBottom: '20px' }}>
				<StyledExchangeFormInputTitle>
					Select coin to buy/sell
				</StyledExchangeFormInputTitle>
				<StyledExchangeFormControl>
					<Select
						open={false}
						inputProps={{
							MenuProps: {
								MenuListProps: {
									sx: {
										backgroundColor: '#0D0901',
										boxShadow: 'none',
									},
								},
							},
						}}
						onOpen={() => openSelectCoinModal(true)}
						sx={{
							width: '100%',
							border: 'none',
							color: '#E7E7E7',
							padding: 0,
							margin: 0,
							fontSize: '16px',
							fontWeight: 600,
							'.MuiSelect-icon': {
								color: 'white',
							},
							'.MuiSelect-outlined': {
								color: 'white',
							},
						}}
						defaultValue=''
						displayEmpty
						renderValue={() => {
							return (
								<Box sx={{ display: 'flex', gap: 1 }}>
									<img
										width={25}
										height={25}
										src={`/images/coins/${baseCurrency}.svg`}
									/>
									<div style={{ paddingTop: 7 }}>{baseCurrency}</div>
								</Box>
							)
						}}
					/>
				</StyledExchangeFormControl>
			</div>
			{/* Dialog from accept conversation */}
			<StyledDialog
				open={accepConversionModalBuy}
				onClose={() => setAccepConversionModalBuy(false)}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					<StyledDialogTitle>Conversion successful</StyledDialogTitle>
					{/* <StyledDialogDesc>Buy 02-11-2022, 00:17</StyledDialogDesc> */}

					<StyledDialogDesc>
						<span
							style={{
								borderRight: ' 1px solid #D8D8D8',
								paddingRight: 11,
								marginRight: 16,
							}}
						>
							Buy
						</span>{' '}
						{moment(new Date()).format('DD-MM-YYYY, hh:mm')}
					</StyledDialogDesc>
					<DialogContent>
						<StyledSpenBlock>
							<StyledSpenBlockTitle>You spent</StyledSpenBlockTitle>
							<StyledSpenBlockAmount>{maxAmount}</StyledSpenBlockAmount>
						</StyledSpenBlock>
						<StyledCurrencyBlock>
							<img
								width={25}
								height={25}
								src={`/images/coins/${selectedQuoteCurrency}.svg`}
							/>
							<Currency>{selectedQuoteCurrency}</Currency>
						</StyledCurrencyBlock>
					</DialogContent>
					<DialogContent style={{ marginBottom: 24 }}>
						<StyledSpenBlock>
							<StyledSpenBlockTitle>You received</StyledSpenBlockTitle>
							<StyledSpenBlockAmount>
								{yourecive && strToFixed(yourecive?.toString())}
							</StyledSpenBlockAmount>
						</StyledSpenBlock>
						<StyledCurrencyBlock>
							<img
								width={25}
								height={25}
								src={`/images/coins/${baseCurrency}.svg`}
							/>
							<Currency>{baseCurrency}</Currency>
						</StyledCurrencyBlock>
					</DialogContent>
					<ButtonsBlock>
						<Link to='/dashboard/wallets'>
							<StyledExchangeModalToWalet style={{ color: '#fff' }}>
								To wallet
							</StyledExchangeModalToWalet>
						</Link>

						<StyledExchangeModalButton
							onClick={() => {
								setAccepConversionModalBuy(false)
								setMaxAmount('')
								setButtonContent('Preview conversion')
								setReciveCount([])
								setShowFee(false)
							}}
							style={{ color: '#fff' }}
						>
							Another conversion
						</StyledExchangeModalButton>
					</ButtonsBlock>
				</div>
			</StyledDialog>

			{/* Dialog for  partialFilled*/}
			<StyledDialog
				open={partialFillednModalBuy}
				onClose={() => setPartialFillednModalBuy(false)}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					<StyledDialogTitle>Conversion successful</StyledDialogTitle>
					{/* <StyledDialogDesc>Buy 02-11-2022, 00:17</StyledDialogDesc> */}

					<StyledDialogDesc>
						<span
							style={{
								borderRight: ' 1px solid #D8D8D8',
								paddingRight: 11,
								marginRight: 16,
							}}
						>
							Buy
						</span>{' '}
						{moment(new Date()).format('DD-MM-YYYY, hh:mm')}
					</StyledDialogDesc>
					<DialogContent>
						<StyledSpenBlock>
							<StyledSpenBlockTitle>You spent</StyledSpenBlockTitle>
							<StyledSpenBlockAmount>{maxAmount}</StyledSpenBlockAmount>
						</StyledSpenBlock>
						<StyledCurrencyBlock>
							<img
								width={25}
								height={25}
								src={`/images/coins/${selectedQuoteCurrency}.svg`}
							/>
							<Currency>{selectedQuoteCurrency}</Currency>
						</StyledCurrencyBlock>
					</DialogContent>
					<DialogContent style={{ marginBottom: 24 }}>
						<StyledSpenBlock>
							<StyledSpenBlockTitle>You received</StyledSpenBlockTitle>
							<StyledSpenBlockAmount>{executedValue}</StyledSpenBlockAmount>
						</StyledSpenBlock>
						<StyledCurrencyBlock>
							<img
								width={25}
								height={25}
								src={`/images/coins/${baseCurrency}.svg`}
							/>
							<Currency>{baseCurrency}</Currency>
						</StyledCurrencyBlock>
					</DialogContent>
					<ButtonsBlock>
						<Link to='/dashboard/wallets'>
							<StyledExchangeModalToWalet style={{ color: '#fff' }}>
								To wallet
							</StyledExchangeModalToWalet>
						</Link>

						<StyledExchangeModalButton
							onClick={() => {
								setPartialFillednModalBuy(false)
								setMaxAmount('')
								setButtonContent('Preview conversion')
								setReciveCount([])
								setShowFee(false)
							}}
							style={{ color: '#fff' }}
						>
							Another conversion
						</StyledExchangeModalButton>
					</ButtonsBlock>
				</div>
			</StyledDialog>

			{/* Dialod for select coin */}
			<StyledDialogForSelectCoin
				open={selectCoinModal}
				onClose={() => {
					openSelectCoinModal(false)
					setSearch('')
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<StyledCoinDialogTitle>Select currency</StyledCoinDialogTitle>
					<CloseIcon
						onClick={() => {
							openSelectCoinModal(false)
							setSearch('')
						}}
						style={{
							position: 'absolute',
							right: 12,
							cursor: 'pointer',
							top: 12,
						}}
					/>
				</div>
				<FormControl variant='standard'>
					<StyledInputSearch
						inputProps={{ pattern: '[a-z]' }}
						onChange={e => {
							setSearch(e.target.value)
						}}
						onKeyDown={e => {
							const regExp =
								'^[^<>{}"/|;:.,~!?@#$%^=&*\\]\\\\()\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©0-9_+]*$'
							regExp.includes(e.key) && e.preventDefault()
						}}
						style={{ color: '#000', fontSize: '14px', lineHeight: '21px' }}
						id='input-with-icon-adornment'
						placeholder='Search crypto name'
						startAdornment={
							<InputAdornment position='start'>
								<StyledSearchIcon />
							</InputAdornment>
						}
					/>
				</FormControl>{' '}
				{}
				{computedwallet &&
					computedwallet.map(item => {
						return (
							<CurrencyBlock onClick={() => handleSelectBaseCoin(item)}>
								<CurrencyName>
									<img
										width={25}
										style={{ marginRight: 12, marginTop: 8 }}
										height={25}
										src={`/images/coins/${item.currency}.svg`}
									/>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span style={{ fontWeight: 600 }}>
											{item.currency_full}
										</span>
										<span style={{ color: '#80848E' }}>{item.currency}</span>
									</div>
								</CurrencyName>
								<CurrencyBalance>
									<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
										{item.balance.toFixed(2)}
									</span>
									<span style={{ color: '#80848E', paddingTop: 5 }}>
										{item.balance_eur.toLocaleString('en-US', {
											style: 'currency',
											currency: 'EUR',
										})}
									</span>
								</CurrencyBalance>
							</CurrencyBlock>
						)
					})}
			</StyledDialogForSelectCoin>
			<div style={{ marginBottom: '20px' }}>
				<div style={{ display: 'flex' }}>
					<div style={{ width: '60%' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<StyledExchangeFormInputTitle>
								You pay
							</StyledExchangeFormInputTitle>
							<StyledExchangeFormInputTitle>
								Balance:{' '}
							</StyledExchangeFormInputTitle>
						</div>

						<StyledExchangeFormControl>
							<StyledExchangeFormInputText
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setMaxAmount(e.target.value)
									setAmountMinimumError(false)
									setButtonContent('Preview conversion')
									setReciveCount([])
									setShowFee(false)
									// setInsufficientError(false)
									dispatch(setInsufficientBalance(false))
								}}
								type='number'
								error={amountMinimumError || insufficientError}
								placeholder='Enter amount'
								value={maxAmount && strToFixed(maxAmount.toString())}
								onKeyDown={evt =>
									['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
								}
								sx={{ input: { color: 'white' } }}
								style={{
									color: '#fff !important',
									outline: 'none',
								}}
								InputProps={{
									endAdornment: (
										<div
											style={{
												cursor: 'pointer',
												fontFamily: 'Poppins',
												fontWeight: 400,
												fontSize: '14px',
												lineHeight: '21px',
												color: '#9E77ED',
											}}
											onClick={e => {
												if (+curBalance == 0.0) {
													// setInsufficientError(true)
													dispatch(setInsufficientBalance(true))
													setShowFee(false)
												}
												const calculateFee =
													buyFee &&
													(+buyFee * Number(amountNumber?.balance)) / 100
												calculateFee && setFee(calculateFee)
												const num =
													Number(calculateFee) >= 0 &&
													amountNumber?.balance &&
													setMaxAmount(
														amountNumber?.balance - Number(calculateFee)
													)
												return Number(num)
											}}
										>
											MAX
										</div>
									),
									inputProps: { min: 0 },
								}}
							/>
						</StyledExchangeFormControl>
						{amountMinimumError && (
							<p style={{ color: '#D83232', fontSize: 12 }}>
								{amountErrorMessage}
							</p>
						)}
						{insufficientError && (
							<p style={{ color: '#D83232', fontSize: 12 }}>
								Insufficient balance
							</p>
						)}
					</div>

					<div style={{ width: '40%' }}>
						<StyledExchangeFormInputTitle
							style={{ display: 'flex', justifyContent: 'flex-end' }}
						>
							{isNaN(+curBalance) ? 0.0 : curBalance}
						</StyledExchangeFormInputTitle>
						<StyledExchangeFormControl>
							<Select
								onChange={handleQuoteCurrencyChange}
								defaultValue={defaultQuote}
								// value={defaultQuote.length && defaultQuote}
								value={selectedQuoteCurrency}
								inputProps={{
									style: {
										padding: 0,
									},
								}}
								sx={{
									width: '100%',
									border: 'none',
									color: '#E7E7E7',
									height: 50,
									fontSize: '16px',
									fontWeight: 600,
									display: 'flex',
									alignItems: 'center',
									verticalAlign: 'middle',
									padding: 0,
									'.MuiSelect-icon': {
										color: 'white',
									},
									'.MuiSelect-outlined': {
										color: 'none',
									},
									'.MuiSvgIcon-root-393': {
										visibility: 'hidden',
									},
								}}
							>
								{quoteCurrency &&
									quoteCurrency.map((item: any) => (
										<MenuItem key={item} value={item}>
											<img
												width={25}
												style={{ marginRight: 12 }}
												height={25}
												src={`/images/coins/${item}.svg`}
											/>
											<span style={{ position: 'absolute', top: 16, left: 48 }}>
												{item}
											</span>
										</MenuItem>
									))}
							</Select>
						</StyledExchangeFormControl>
					</div>
				</div>
			</div>

			<div style={{ marginBottom: 11 }}>
				<div style={{ display: 'flex' }}>
					<div style={{ width: '60%' }}>
						<StyledExchangeFormInputTitle>
							You receive
						</StyledExchangeFormInputTitle>
						<StyledExchangeFormControl>
							<StyledExchangeFormInputText
								//  placeholder='-----'
								//   value={reciveCount?.toString()}
								placeholder='-----'
								value={yourecive && strToFixed(yourecive?.toString())}
								onKeyDown={evt =>
									['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
								}
								sx={{ input: { color: 'white' } }}
								style={{ color: '#fff !important' }}
							/>
						</StyledExchangeFormControl>
					</div>

					<div style={{ width: '40%' }}>
						<StyledExchangeFormInputTitle style={{ color: '#0D0901' }}>
							r
						</StyledExchangeFormInputTitle>
						<StyledExchangeFormControl>
							<Select
								inputProps={{
									IconComponent: () => null,
								}}
								sx={{
									width: '100%',
									border: 'none',
									color: '#E7E7E7',
									height: 50,
									fontSize: '16px',
									fontWeight: 600,
									'.MuiSelect-icon': {
										color: 'white',
									},
									'.MuiSelect-outlined': {
										color: 'white',
									},
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor: '#fff',
									},
								}}
								defaultValue=''
								displayEmpty
								disabled
								renderValue={value => {
									return (
										<Box sx={{ display: 'flex', gap: 1 }}>
											<img
												width={25}
												height={25}
												src={`/images/coins/${baseCurrency}.svg`}
											/>
											<div style={{ paddingTop: 5, color: '#fff !important' }}>
												{baseCurrency}
											</div>
										</Box>
									)
								}}
							>
								{/* {currencies.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.value}
        </MenuItem>
      ))} */}
							</Select>
						</StyledExchangeFormControl>
					</div>
				</div>
			</div>
			{showFee && (
				<div
					style={{
						marginBottom: 8,
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<StyledExchangeFormDesc>
						1 {selectedQuoteCurrency} ≈ {forOneEur()} {baseCurrency}
					</StyledExchangeFormDesc>
					<StyledExchangeFormDesc>
						Fee: €{fee.toFixed(2)}
					</StyledExchangeFormDesc>
				</div>
			)}

			{buttonContent === 'Preview conversion' && (
				<StyledExchangeFormSubmit
					style={{ marginTop: 12 }}
					disabled={!Boolean(selectedQuoteCurrency) || !Boolean(maxAmount)}
					onClick={previevConversation}
				>
					{buttonContent}
				</StyledExchangeFormSubmit>
			)}
			{buttonContent === 'Accept conversion' && (
				<StyledExchangeFormSubmit onClick={handleSubmit}>
					{buttonContent}
				</StyledExchangeFormSubmit>
			)}
		</Box>
	)
}
