// import img from 'next/image'
import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { Checkbox } from "components/Forms/CustomChecknox";
import { Formik } from "formik";
import { useTypedSelector } from "hooks/useTypedSelector";
import { Link, useLocation } from "react-router-dom";
import * as yup from "yup";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import { register } from '../../../api/authApi'
// import { countries } from '../../../data/country_list'
// import { ICreateUserData } from '../../../ts/interfaces/users.interfaces'
import Tooltip from "@mui/material/Tooltip";

import { countries } from "../../../app.config";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { createUserThunk } from "../../../store/profile/profile.thunk";
import { IUserCreateReq } from "../../../types/profile.types";
import styles from "../auth.module.scss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

interface ISignUp {
	setStatus: Dispatch<SetStateAction<string>>;
	setNotificationEmail: Dispatch<SetStateAction<string>>;
}

const SignUp: FC<ISignUp> = (props) => {
	//States
	const { setStatus, setNotificationEmail } = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isExists, setIsExists] = useState<boolean>(false);

	const profile = useTypedSelector((state) => state.profile);

	let location = useLocation();
	const urlCode: string = new URLSearchParams(location.search).get("code")! || "";

	const [response, getResponse] = React.useState<string>("failed");
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

	const [open, setOpen] = React.useState(false);

	// const handleClick = () => {
	//     setOpen(true);
	// };

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	const values = {
		first_name: "",
		last_name: "",
		country_name: "",
		email: "",
		password: "",
		passwordConfirmation: "",
		policyAgreement: false,
		referral_code: urlCode,
	};

	const validationSchema = yup.object({
		first_name: yup
			.string()
			.required("Required field")
			.max(20, "Length should not exceed 20 characters")
			.matches(/^[a-zA-Z]+$/, "First name must contain only letters"),
		last_name: yup
			.string()
			.required("Required field")
			.max(256, "Length should not exceed 256 characters")
			.matches(/^[a-zA-Z]+$/, "Last name must contain only letters"),
		country_name: yup.string().required("Required field"),
		email: yup.string().required("Required field").email("Wrong email"),
		password: yup
			.string()
			.required("Required field")
			.min(8, "Length must exceed 8 characters")
			.matches(
				/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
				"Password must contain at least 8 charachters, one uppercase, one lowercase, one number and one special case charachter"
			),
		policyAgreement: yup.boolean().oneOf([true], "Agree with the policy"),
		passwordConfirmation: yup.string().oneOf([yup.ref("password")], "Your passwords do not match."),
	});

	//Redux
	const dispatch = useAppDispatch();

	//Handlers

	const onSubmit = async (values: any) => {
		const body = { ...values } as Partial<any>;
		delete body.policyAgreement;
		delete body.passwordConfirmation;
		setIsLoading(true);
		setIsExists(false);
		const createUser = await dispatch(createUserThunk(body as IUserCreateReq));
		setIsLoading(false);
		setOpen(true);

		if (createUser.type === "createUserThunk/rejected") {
			setIsExists(true);
		} else {
			setNotificationEmail(values.email);
			setStatus("success");
		}
	};
	return (
		<>
			<div style={{ marginBottom: 26 }}>
				<h1 className={styles["auth-title"]}>Register</h1>
				<span className={styles["auth-note"]}>
					Already have an account? <Link to={"/signin"}>Log in here</Link>
				</span>
			</div>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={values}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					onSubmit(values);
					// { resetForm } arg
					// resetForm();
				}}>
				{(formik) => (
					<form
						className={styles["auth-form"]}
						onSubmit={(e) => {
							e.preventDefault();
							formik.handleSubmit(e);
						}}>
						<div className={styles["auth-input--container"]}>
							<input
								name='first_name'
								type='text'
								className={formik.errors.first_name ? styles["error"] : ""}
								onChange={(e: any) => {
									const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
									if (e.target.value === "" || re.test(e.target.value)) {
										formik.handleChange(e);
									}
								}}
								value={formik.values.first_name}
								placeholder='First name *'
							/>
							{formik.errors.first_name && (
								<p className={styles["error"]}>{formik.errors.first_name}</p>
							)}
						</div>
						<div className={styles["auth-input--container"]}>
							<input
								name='last_name'
								type='text'
								className={formik.errors.last_name ? styles["error"] : ""}
								onChange={(e: any) => {
									const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
									if (e.target.value === "" || re.test(e.target.value)) {
										formik.handleChange(e);
									}
								}}
								value={formik.values.last_name}
								placeholder='Last name *'
							/>
							{formik.errors.last_name && (
								<p className={styles["error"]}>{formik.errors.last_name}</p>
							)}
						</div>
						<select
							name='country_name'
							className={formik.errors.country_name ? styles["error"] : ""}
							onChange={formik.handleChange}
							value={formik.values.country_name}>
							<option defaultValue={""} hidden>
								Nationality *
							</option>
							{countries.map(({ name, code }, index) => (
								<option key={code} value={name} style={{ color: "black" }}>
									{name}
								</option>
							))}
						</select>
						{formik.errors.country_name && (
							<p className={styles["error"]}>Nationality is required</p>
						)}
						<input
							name='email'
							className={formik.errors.email ? styles["error"] : ""}
							onChange={(e: any) => {
								const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
								if (e.target.value === "" || re.test(e.target.value)) {
									formik.handleChange(e);
								}
							}}
							value={formik.values.email}
							type='email'
							placeholder='Email *'
						/>
						{formik.errors.email && (
							<p style={{ color: "#ea0004", fontSize: 12 }} className='error'>
								{formik.errors.email}
							</p>
						)}
						<Tooltip
							title={
								<ul>
									<li>At least one capital letter</li>
									<li>At least one lowercase letter</li>
									<li>At least one digit</li>
									<li>At least one character</li>
								</ul>
							}>
							<div className={styles["auth-input--wrapper"]}>
								<img
									src='/images/auth/eye.svg'
									width={32}
									height={32}
									alt='eye'
									onMouseDown={() => {
										setShowPassword(true);
									}}
									onMouseUp={() => {
										setShowPassword(false);
									}}
									onMouseLeave={() => {
										if (showPassword) {
											setShowPassword(false);
										}
									}}
								/>
								<input
									id='password'
									className={formik.errors.password ? styles["error"] : ""}
									name='password'
									onCopy={(e) => {
										e.preventDefault();
										return false;
									}}
									onChange={(e: any) => {
										const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
										if (e.target.value === "" || re.test(e.target.value)) {
											formik.handleChange(e);
										}
									}}
									value={formik.values.password}
									type={showPassword ? "text" : "password"}
									placeholder='Password *'
								/>
							</div>
						</Tooltip>
						{formik.errors.password && <p className={styles["error"]}>{formik.errors.password}</p>}

						<Tooltip
							title={
								<ul>
									<li>Must match the password field</li>
								</ul>
							}>
							<div className={styles["auth-input--wrapper"]}>
								<img
									src='/images/auth/eye.svg'
									width={32}
									height={32}
									alt='eye'
									// onClick={(e) => showPass("password")}
									onMouseDown={() => {
										setShowPasswordConfirm(true);
									}}
									onMouseUp={() => {
										setShowPasswordConfirm(false);
									}}
									onMouseLeave={() => {
										if (showPasswordConfirm) {
											setShowPasswordConfirm(false);
										}
									}}
								/>
								<input
									id='passwordConfirm'
									onPaste={(e) => {
										e.preventDefault();
										return false;
									}}
									className={formik.errors.passwordConfirmation ? styles["error"] : ""}
									name='passwordConfirmation'
									onChange={(e: any) => {
										const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
										if (e.target.value === "" || re.test(e.target.value)) {
											formik.handleChange(e);
										}
									}}
									value={formik.values.passwordConfirmation}
									type={showPasswordConfirm ? "text" : "password"}
									placeholder='Password confirmation *'
								/>
							</div>
						</Tooltip>

						{formik.errors.passwordConfirmation && (
							<p className={styles["error"]}>{formik.errors.passwordConfirmation}</p>
						)}
						<input
							name='referral_code'
							onChange={(e: any) => {
								const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
								if (e.target.value === "" || re.test(e.target.value)) {
									formik.handleChange(e);
								}
							}}
							type='text'
							placeholder='Refferal code*'
							value={formik.values.referral_code}
						/>
						<div className={styles["auth-input--wrapper"]}>
							<div style={{ display: "flex", alignItems: "center", paddingTop: 7 }}>
								<Checkbox
									changeEvent={formik.handleChange}
									checked={formik.values.policyAgreement}
								/>
								<div style={{ color: "#fff", paddingRight: "8px" }}>I agree to the</div>{" "}
								<a
									href='/Terms_and_conditions_Bitocol.pdf'
									target='_blank'
									style={{ textDecoration: "underline" }}>
									terms and conditions....{" "}
								</a>
							</div>
						</div>
						{formik.errors.policyAgreement && (
							<p className={styles["error"]}>Policy agreement is not checked</p>
						)}
						<button disabled={isLoading} className={styles["auth-form--btn"]} type='submit'>
							Create account
						</button>
					</form>
				)}
			</Formik>
			{isExists && <p className={styles["error"]}>This email already in use</p>}
			{response === "success" && (
				<div className={styles["overlay"]} onClick={() => setStatus("login")}>
					<div className={styles["modal"]}>
						<img src='/images/auth/letter.svg' width={128} height={128} />
						<h1>Waiting for email verification</h1>
						<p>
							We have emailed you with a link to verify your email address, follow the instructions
						</p>
					</div>
				</div>
			)}

			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='success' sx={{ width: "100%" }}>
					<p>
						We have emailed you with a link to verify your email address, follow the instructions
					</p>
				</Alert>
			</Snackbar>
		</>
	);
};

export default SignUp;
