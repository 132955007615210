export const splitNumber = (num: number | string): string[] => {
    return String(num).split('.')
}

export const getCoinPath = (coin: string) => {
    const coinPaths: Record<string, string> = {
        '1INCH': '1INCH.svg',
        'AAVE': 'AAVE.svg',
        'ADA': 'ADA.svg',
        'ALGO': 'ALGO.svg',
        'ATOM': 'ATOM.svg',
        'BCH': 'BCH.svg',
        'BNB': 'BNB.svg',
        'BTC': 'BTC.svg',
        'CRV': 'CRV.svg',
        'DAI': 'DAI.svg',
        'DOGE': 'DOGE.svg',
        'DOT': 'DOT.svg',
        'ENJ': 'ENJ.svg',
        'EOS': 'EOS.svg',
        'ETC': 'ETC.svg',
        'ETH': 'ETH.svg',
        'FTM': 'FTM.svg',
        'FTT': 'FTT.svg',
        'HBAR': 'HBAR.svg',
        'LINK': 'LINK.svg',
        'LTC': 'LTC.svg',
        'MANA': 'MANA.svg',
        'MATIC': 'MATIC.svg',
        'MKR': 'MKR.svg',
        'NEAR': 'NEAR.svg',
        'NEXO': 'NEXO.svg',
        'OP': 'OP.svg',
        'PAX': 'PAX.svg',
        'PAXG': 'PAXG.svg',
        'RUNE': 'RUNE.svg',
        'SOL': 'SOL.svg',
        'SUSHI': 'SUSHI.svg',
        'TRX': 'TRX.svg',
        'UNI': 'UNI.svg',
        'USDC': 'USDC.svg',
        'USDT': 'USDT.svg',
        'VET': 'VET.svg',
        'XLM': 'XLM.svg',
        'XRP': 'XRP.svg',
        'APE': 'APE.png',
        'AVAX': 'AVAX.png',
        'AXS': 'AXS.png',
        'GALA': 'GALA.png',
        'KSM': 'KSM.png',
        'EUR': 'EUR.svg',
        'SAND': 'SAND.png',
    }

    return coinPaths[coin.replace(/\s/g, '').toUpperCase()]
}