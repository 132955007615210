import {
	Box,
	Button,
	Dialog,
	FormControl,
	Select,
	styled,
	TextField,
} from '@mui/material'

import bgImage from './assets/simple-exchange-bg.svg'

export const StyledContainer = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: '1400px',
	margin: '0 auto',
	padding: '0 25px',
	background: 'url(' + bgImage + ') no-repeat',
	backgroundSize: '700px 700px',
	backgroundPositionX: ' 80%',
	backgroundPositionY: ' -6px',
	fontFamily: 'Poppins',
	// marginLeft:60
}))

export const StyledExchangeFlex = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: '100%',
	display: 'flex',
	alignItems: 'flex-start',
	fontFamily: 'Poppins',
}))

export const StyledExchangeContent = styled(Box)(({ theme }) => ({
	width: '37%',
	marginTop: 32,
	marginLeft: 50,
	fontFamily: 'Poppins',
}))

export const StyledExchangeForm = styled(Box)(({ theme }) => ({
	width: '63%',
	height: 700,
	marginLeft: '90px',
	marginTop: 52,
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormBlock = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: '596px',
	height: 580,
	backgroundColor: '#0D0901 !important',
	borderRadius: '5px',
	padding: '24px 62px',
	color: '#E7E7E7',
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Poppins',
	fontWeight: '600',
	fontSize: '20px',
	textAlign: 'center',
	marginBottom: '20px',
}))

export const StyledExchangeFormSubtitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Poppins',
	fontWeight: '400',
	fontSize: '14px',
	textAlign: 'center',
	marginBottom: '20px',
}))

export const StyledExchangeFormSelect = styled(Button)(({ theme }) => ({
	display: 'inline-block',
	border: '1px solid #9E77ED',
	borderRadius: '25.5px',
	backgroundColor: 'transparent',
	padding: '7px 41px',
	marginRight: '30px',
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormText = styled(Button)(({ theme }) => ({
	display: 'inline-block',
	border: '1px solid #9E77ED',
	borderRadius: '25.5px',
	backgroundColor: 'transparent',
	padding: '7px 41px',
	marginRight: '30px',
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormSubmit = styled(Button)(({ theme }) => ({
	width: '100%',
	display: 'inline-block',
	border: '1px solid #9E77ED',
	borderRadius: '3px',
	textAlign: 'center',
	backgroundColor: '#9E77ED',
	padding: '10px 0',
	color: 'white',
	fontSize: '14px',
	fontWeight: '600',
	textTransform: 'capitalize',
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormInputTitle = styled(Box)(({ theme }) => ({
	color: 'white',
	fontSize: '12px',
	fontWeight: '600',
	marginBottom: '10px',
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormInputText = styled(TextField)(({ theme }) => ({
	width: '100%',
	color: 'white',
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormControl = styled(FormControl)(({ theme }) => ({
	width: '100%',
	backgroundColor: '#0C2250',
	borderRadius: '5px',
	border: 'none',
	fontFamily: 'Poppins',
}))

export const StyledExchangeFormControlSelect = styled(Select)(({ theme }) => ({
	width: '100%',
	border: 'none',
	color: '#E7E7E7',
	fontSize: '16px',
	fontFamily: 'Poppins',
}))

export const StyledExchangeTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Poppins',
	fontSize: '35px',
	fontWeight: '600',
	lineHeight: '52.5px',
	marginBottom: '27px',
}))

export const StyledContent = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: '314px',
	color: '#414E52',
	fontFamily: 'Poppins',
}))

export const StyledContentTitle = styled(Box)(({ theme }) => ({
	fontWeight: '500',
	fontSize: '20px',
	marginBottom: '36px',
	fontFamily: 'Poppins',
}))

export const StyledContentFlex = styled(Box)(({ theme }) => ({
	width: '100%',
}))

export const StyledContentFlexBlock = styled(Box)(({ theme }) => ({
	display: 'flex',
	gap: 20,
	alignItems: 'center',
	marginBottom: '30px',
}))

export const StyledContentFlexText = styled(Box)(({ theme }) => ({
	fontSize: '16px',
	// marginLeft: "20px"
}))
export const StyledExchangeFormDesc = styled('span')(({ theme }) => ({
	fontSize: '10px',
	fontFamily: 'Poppins',
	fontWeight: 500,
	color: '#fff',
	marginTop: 8,
}))
export const StyledBottomContent = styled('div')(({ theme }) => ({
	marginTop: 126,
}))
export const StyledRedText = styled('span')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	color: '#D83232',
	display: 'block',
	marginBottom: 8,
}))
export const StyledText = styled('span')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	color: '#9E77ED',
}))

export const CurrencyBlock = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	padding: '10px 36px ',
}))
export const CurrencyBalance = styled('div')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	display: 'flex',
	justifyContent: 'flex-end',
	flexDirection: 'column',
}))

export const CurrencyName = styled('div')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontWeight: 500,
	fontSize: '16px',
	display: 'flex',
	verticalAlign: 'middle',

	lineHeight: '24px',
	color: '#9E77ED',
	cursor: 'pointer',
}))
export const StyledDialogForSelectCoin = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		background: '#FFF',
		width: 406,
		height: 458,
		color: '#000',
		'& div': {
			color: '#000',
		},
		'& button': {
			color: '#000',
		},
	},
}))
