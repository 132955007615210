import {MarketPair} from "../../../../types/exchange.types";
import {FC} from "react";
import {Box, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {CoinIcon} from "../../atoms/CoinIcon/CoinIcon";
import { CSSProperties } from "react";


interface IProps {
    value: MarketPair,
    style: CSSProperties,
    onClick: (pair: MarketPair) => void
}

export const MarketPlacePairItem: FC<IProps> = ({value, onClick, style}) => {
    const {pair, lastPrice, change24H, volume24H} = value

    return <ListItemButton
        onClick={() => onClick(value)}
        component={'div'}
        style={style}
        sx={{
            '.MuiListItemSecondaryAction-root': {
                width: 22
            },
            padding: 0
        }}>
        <ListItemIcon>
            <Box display={'flex'} alignItems={'center'} sx={{width: 30, height: 30}}>
                <CoinIcon coinName={pair.split('/')[0]} style={{width: 30, height: 30}}/>
            </Box>
        </ListItemIcon>
        <ListItemText
            disableTypography
            primary={
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box>
                        <Typography component={'span'}>{pair.split('/')[0]}/</Typography>
                        <Typography component={'span'} color={'#80848E'}>{pair.split('/')[1]}</Typography>
                    </Box>

                    <Typography>
                        {lastPrice}
                    </Typography>
                </Box>
            }
            secondary={
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography sx={{color: Number(change24H) >= 0 ? '#419E6A' : '#f44336'}}>{Number(change24H).toFixed(2)}%</Typography>
                    <Typography color={'#80848E'}>Vol {volume24H}</Typography>
                </Box>
            }/>
    </ListItemButton>
}