import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Input,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	styled,
	TextField,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { FC, ReactNode } from 'react'
import * as yup from 'yup'
import { IOrderCreateReq } from '../../types/exchange.types'

interface IProps {
	onChange: (data: IOrderCreateReq) => void
	onCancel: () => void
}

const initialValues: Partial<IOrderCreateReq> = {
	Type: 'limit',
	Side: 'buy',
	TimeInForce: '5m',
	Price: 1,
}

const validationSchema = yup.object({
	PairId: yup.string().required('This Field is required'),
	Size: yup.string().required('This Field is required'),
})

export const CreateDeposit: FC<IProps> = ({ onChange, onCancel }) => {
	const { handleChange, errors, touched, handleSubmit } = useFormik({
		initialValues,
		validationSchema,
		onSubmit(data) {
			onChange(data as IOrderCreateReq)
		},
	})

	return (
		<Box component={'form'} onSubmit={handleSubmit}>
			{/*PairId*/}
			<FormControl fullWidth>
				<InputLabel
					sx={{
						left: '-14px',
					}}
					id={'PairId'}
				>
					Select Coin
				</InputLabel>
				<StyledMuiSelect
					name={'PairId'}
					onChange={handleChange}
					input={
						<Input
							sx={{
								borderBottom: 'none',
								'&::before': {
									borderColor: '#0C285F !important',
								},
								mb: 4,
							}}
						/>
					}
					inputProps={{ 'aria-label': 'Without label' }}
					IconComponent={() => <KeyboardArrowDownIcon color={'primary'} />}
				>
					{coins.map(({ name, img, value }, index) => (
						<MenuItem value={value} key={index}>
							<img src={img} alt={name} />
							{name}
						</MenuItem>
					))}
				</StyledMuiSelect>
				{errors.PairId && (
					<Typography color={'error'} variant={'caption'}>
						{errors.PairId}
					</Typography>
				)}
			</FormControl>

			{/*    Amount*/}
			<TextField
				label={'Amount'}
				name={'Size'}
				id={'Size'}
				onChange={handleChange}
				variant={'standard'}
				fullWidth
				error={touched.Size && Boolean(errors.Size)}
				helperText={touched.Size && (errors.Size as ReactNode)}
				InputProps={{
					endAdornment: (
						<Button size={'small'} variant={'text'}>
							MAX
						</Button>
					),
				}}
			/>

			{/*    Available*/}

			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'center'}
				mt={2.5}
			>
				<p className={'web20 subtitle-color'}>Available</p>
				<div>
					<span className={'web21 white-color'}>0.00</span>{' '}
					<span className={'web21 subtitle-1-color'}>USDT</span>
				</div>
			</Box>

			{/*    Time*/}
			<FormControl sx={{ mt: 8 }} fullWidth>
				<FormLabel id='TimeInForce' sx={{ color: 'black', display: 'flex' }}>
					<Typography variant={'caption'} sx={{ mr: 1 }}>
						Expiration time
					</Typography>
					<BootstrapTooltip title={tooltipText}>
						<img src='/images/exchange/question.svg' alt='question' />
					</BootstrapTooltip>
				</FormLabel>
				<RadioGroup
					onChange={handleChange}
					row
					sx={{ justifyContent: 'space-between' }}
					aria-labelledby='TimeInForce'
					defaultValue='5m'
					name='TimeInForce'
				>
					{times.map(time => (
						<FormControlLabel
							key={time}
							sx={{
								'.Mui-checked': {
									'.MuiTouchRipple-root': {
										color: '#9E77ED !important',
									},
								},
							}}
							control={
								<Radio
									sx={{
										'.MuiTouchRipple-root': {
											color: '#0B2A65 !important',
										},
									}}
								/>
							}
							value={time}
							label={time}
						/>
					))}
				</RadioGroup>
			</FormControl>
			<Typography
				sx={{ mt: 4, display: 'flex', alignItems: 'center' }}
				color={'#9E77ED'}
				variant={'h5'}
				textAlign={'left'}
			>
				See deposit history <ArrowForwardIcon />
			</Typography>

			<Box
				display={'flex'}
				justifyContent={'right'}
				alignItems={'center'}
				mt={6}
			>
				<Button
					onClick={onCancel}
					variant={'text'}
					sx={{ color: '#80848E' }}
					size={'medium'}
				>
					Cancel
				</Button>

				<Button type={'submit'} variant={'outlined'} size={'medium'}>
					Confirm deposit
				</Button>
			</Box>
		</Box>
	)
}

const StyledMuiSelect = styled(Select)`
	.MuiSelect-select {
		display: flex;
		justify-content: left;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
			margin-right: 8px;
		}
	}
`

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#0049db',
		padding: '20px 16px 20px 24px',
		fontSize: 12,
	},
}))

const times = ['5m', '10m', '30m', '1h']

const tooltipText =
	'Cancelled transactions are finalized only expiration time, before releasing locked funds. You can define the expiration time of your deposits/withdrawals on the exchange.\n' +
	'** Please use appropriate gas fees. Transactions need to be confirmed before expiration time, otherwise, will be deemed invalid.'

const coins = [
	{
		name: 'BTC',
		value: 1,
		img: '/images/coins/bitcoin.svg',
	},
]
