import {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useSnackbar } from 'notistack';

import styled from '@emotion/styled';
import { Grid } from '@mui/material';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ExchangeLayout } from '../../layouts/ExchangeLayout/ExchangeLayout';
import {
  currenciesGetThunk,
  orderCreateThunk,
  ordersGetThunk,
} from '../../store/exchange/exchange.thunk';
import { setUpdatedTokens } from '../../store/profile/profile.slice';
import {
  getWalletsThunk,
  getWalletThunk,
} from '../../store/wallets/wallets.thunk';
import { IOrderCreateReq } from '../../types/exchange.types';
import {
  ExchangeBalances,
} from './components/ExchangeBalances/ExchangeBalances';
import { ExchangeBuySell } from './components/ExchangeBuySell/ExchangeBuySell';
import { ExchangeHeader } from './components/ExchangeHeader/ExchangeHeader';
import { ExchangeOrders } from './components/ExchangeOrders/ExchangeOrders';
import {
  ExchangeTradingChart,
} from './components/ExchangeTradingChart/ExchangeTradingChart';
import { ExchangeBook } from './components/ExhangeBook/ExchangeBook';

interface IProps {
}




const Exchange: FC<IProps> = ({}) => {

    //Redux
    const dispatch = useAppDispatch()
    const {orders} = useTypedSelector(state => state.exchange)
    const walletsBalance = useTypedSelector(state => state.wallets.walletBalance)

    //Handlers
    const handleSubmit = useCallback(async (data: IOrderCreateReq) => {
        dispatch(orderCreateThunk(data))
        //dispatch(ordersGetThunk())
    }, [])

    //Effects

    useEffect(() => {
        dispatch(ordersGetThunk())
        dispatch(getWalletsThunk())
        // enqueueSnackbar('TEST', {
        //     variant: 'success'
        // })
    }, [])


    const updateList = () => dispatch(ordersGetThunk())

    const tokensUpdated = useTypedSelector(state => state.profile.tokensUpdated)
    const currencies = useTypedSelector((state) => state.exchange.currencies);
    const isLogin = useTypedSelector(state => state.profile.isLogin)

    // const [connection, setConnection] = useState<Centrifuge | null>(null);
    // const [sub1, setSub1] = useState<any>(null);
    // const [sub2, setSub2] = useState<any>(null);
    // const [sub3, setSub3] = useState<any>(null);

    const [connected, setConnected] = useState(false)

    // const createConnection = () => {
    //     const sessionToken = sessionStorage.getItem("accessToken")
    //     const localToken = localStorage.getItem("accessToken")
    //     const centrifuge = new Centrifuge(
    //         `${wsBaseUr}/connection/websocket`,
    //         {
    //             token: sessionToken ? sessionToken : localToken,
    //             maxReconnectDelay: 0
    //         }
    //     );
    //
    //     centrifuge
    //         .on("connecting", function (ctx) {
    //             // console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
    //             // 3000-3499, 4000-4499, >=5000
    //             if ((ctx.code > 3000 && ctx.code < 3499) || (ctx.code > 4000 && ctx.code < 4499) || ctx.code >=5000) {
    //                 setConnection(null);
    //                 setConnected(false);
    //                 createConnection()
    //             }
    //         })
    //         .on("connected", function (ctx) {
    //             // console.log(`connected over ${ctx.transport}`);
    //             setConnection(centrifuge);
    //         })
    //         .on("error", function (ctx) {
    //             // console.log(`centrifuge error:`, ctx);
    //         })
    //         .on("disconnected", function (ctx) {
    //             // console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
    //         })
    //         .connect();
    // };

    const subscribeToChannels = (userId: number | undefined, pairId: number, currency: any) => {
        // dispatch(setSocketLoading({
        //     tickers: true,
        //     level2: true
        // }))
        // try {
        //     const sub = connection?.subscriptions()[`ticker:${pairId}`] ? connection?.subscriptions()[`ticker:${pairId}`] : connection?.newSubscription(`ticker:${pairId}`);
        //     setSub1(sub)
        //     sub?.removeAllListeners()

        //     sub
        //         ?.on("publication", function (ctx: any) {
        //             // console.log(`ticker:${pairId} publication:`, ctx);
        //             // container.innerHTML = ctx.data.value;
        //             dispatch(setSocketLoading({
        //                 tickers: false
        //             }))
        //             dispatch(changeTicker(ctx.data));
        //         })
        //         .on("subscribing", function (ctx: any) {
        //             // console.log(`subscribing ticker:${pairId}: ${ctx.code}, ${ctx.reason}, ${currency.BaseCurrency}/${currency.QuoteCurrency}`);
        //         })
        //         .on("subscribed", function (ctx: any) {
        //             // console.log("subscribed", ctx);
        //         })
        //         .on("unsubscribed", function (ctx: any) {
        //             // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
        //         })
        //         .on("error", function (ctx: any) {
        //             // console.log(`error ticker:${pairId}: ${ctx.code}: ${ctx.code}, ${ctx.reason}`);
        //         })
        //         .subscribe();

        //     const sub2 = connection?.subscriptions()[`level2:${pairId}`] ? connection?.subscriptions()[`level2:${pairId}`] : connection?.newSubscription(`level2:${pairId}`);
        //     setSub2(sub2)

        //     sub2
        //         ?.on("publication", function (ctx: any) {
        //             // console.log("publication level2 :");
        //             // console.log(ctx);
        //             dispatch(setSocketLoading({
        //                 level2: false
        //             }))
        //             dispatch(addLevel2Socket(ctx.data));
                    
        //             // container.innerHTML = ctx.data.value;
        //         })
        //         .on("subscribing", function (ctx: any) {
        //             console.log('subscribing... socket')
        //             // console.log(`subscribing level2:${pairId}: ${ctx.code}, ${ctx.reason}, ${currency.BaseCurrency}/${currency.QuoteCurrency}`);
        //         })
        //         .on("subscribed", function (ctx: any) {
        //             console.log('subscribed... socket')
        //             // console.log("subscribed level2 :");
        //             // console.log(ctx.data);
        //             dispatch(addLevel2Socket(ctx.data));
        //         })
        //         .on("unsubscribed", function (ctx: any) {
        //             console.log('unsubscribed... socket')
        //             // console.log(`unsubscribed level2 : ${ctx.code}, ${ctx.reason}`);
        //         })
        //         .subscribe();

        //     const sub3 = connection?.subscriptions()[`order:${pairId}:${userId}`] ? connection?.subscriptions()[`order:${pairId}:${userId}`] : connection?.newSubscription(`order:${pairId}:${userId}`);
        //     setSub3(sub3)

        //     sub3
        //         ?.on("publication", function (ctx: any) {
        //             // console.log("publication order:");
        //             // console.log(ctx);
        //             dispatch(addOrderSocket(ctx.data));
        //         })
        //         .on("subscribing", function (ctx: any) {
        //             // console.log(`subscribing order:${pairId}: ${ctx.code}, ${ctx.reason}, ${currency.BaseCurrency}/${currency.QuoteCurrency}`);
        //         })
        //         .on("subscribed", function (ctx: any) {
        //             // console.log("subscribed", ctx);
        //         })
        //         .on("unsubscribed", function (ctx: any) {
        //             // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
        //         })
        //         .subscribe();

        // } catch (error) {
        //     console.log(error);
        // }

    };

    useEffect(() => {
        // console.log('createConnection()');
        // createConnection();
    }, []);

    useEffect(() => {
        if (tokensUpdated) {
            // console.log('createConnection()');
            // createConnection();
            dispatch(setUpdatedTokens(false))
        }
    }, [tokensUpdated]);

    useEffect(() => {
        if (isLogin && (!currencies || !currencies?.length)) {
            dispatch(currenciesGetThunk());
        }
    }, [currencies, isLogin])

    const selectedPair = useTypedSelector(state => state.exchange.selectedPair)

    useEffect(() => {
        if (selectedPair) {
            dispatch(getWalletThunk(selectedPair.QuoteCurrency))
            setConnected(false);
        }
    }, [selectedPair])

    // useEffect(() => {
    //     if (isLogin && (selectedPair && id && !connected && connection)) {
    //         // console.log('============= subscribeToChannels =============');
    //         sub1?.unsubscribe()
    //         sub1?.removeAllListeners()
    //         sub2?.unsubscribe()
    //         sub2?.removeAllListeners()
    //         sub3?.unsubscribe()
    //         sub3?.removeAllListeners()
    //         setConnected(true);
    //         subscribeToChannels(id, selectedPair.ID, selectedPair);
    //     }
    // }, [isLogin, currencies, id, connected, connection, selectedPair,  sub1, sub2, sub3]);
    //

    //UI

    // const ExchangeBalancesUI = useMemo(() => {
    //     return !wallets.loading && wallets.data && <ExchangeBalances
    //         items={wallets.data}/>
    // }, [wallets])


    return <ExchangeLayout>
        <Grid sx={{minHeight: '100vh'}} style={{  maxHeight: "calc(100vh - 62px)",
  overflow: "auto",
  minHeight: "calc(100vh - 81px)"}} container height={'100%'} position={'relative'}>
            <OrderBook item xs={2.7}>
                <ExchangeBook/>
            </OrderBook>
            <Center sx={{padding: '0 5px'}} item xs={6.7} gap={1}>
                <ExchangeHeader/>
                <ExchangeTradingChart/>
                <ExchangeOrders updateList={updateList} items={orders || []}/>
            </Center>

            <Left item xs={2.5}>
                <ExchangeBuySell onSubmit={handleSubmit}/>
                {walletsBalance.data && <ExchangeBalances items={walletsBalance.data}/>}
            </Left>
        </Grid>
    </ExchangeLayout>
}


const OrderBook = styled(Grid)`
  // background: rgba(0, 73, 219, 0.05);
`

const Center = styled(Grid)`
  // background: rgba(0, 73, 219, 0.05);
`

const Left = styled(Grid)`
  // background: rgba(0, 73, 219, 0.05);
`
export default Exchange