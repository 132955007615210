import {AppDrawer} from "./AppDrawer";
import {EDrawerID} from "../../types/common.types";
import {MarketPlacePairs} from "../UI/organisms/MarketPlacePairs/MarketPlacePairs";

export const MarketPlaceDrawer = () => {
    return <AppDrawer drawerId={EDrawerID.markets} anchor={'left'}
                      drawerProps={{
                          sx:{
                              '& .MuiPaper-root': {
                                  background: '#001030',
                                  height: 'calc(100vh - 81px)',
                                  top: 81,
                                  left: 92,
                              },
                              '& .MuiBackdrop-root': {
                                  background: 'rgba(37, 50, 79, 0.15)',
                                  backdropFilter: 'blur(10px)',
                                  height: 'calc(100vh - 81px)',
                                  top: 81,
                                  left: 92,
                                  width: 'calc(100vw - 107px)',
                              },
                          }
                      }}
    >
        <MarketPlacePairs/>
    </AppDrawer>
}