import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Button, styled } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'

export const StyledGridItemPlug = styled('div')(({ theme }) => ({
	padding: 30,
	background: '#FFFFFF',
	marginBottom: 23,
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}))

export const StyledGridItemPlugMain = styled('div')(({ theme }) => ({
	padding: 30,
	background: '#FFFFFF',
	marginBottom: 23,
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	paddingBottom: 120,
}))

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
	padding: 20,
	maxHeight: 'calc(100vh - 0)',
	overflow: 'auto',
	minHeight: 'calc(100vh - 81px)',
}))

export const StyledGridItemTitle = styled(Grid)(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: 20,
	lineHeight: '30px',
	textAlign: 'center',
	color: '#000000',
	marginBottom: 44,
}))

export const StyledButtonLink = styled(Button)(({ theme }) => ({
	textTransform: 'unset',
	color: '#9E77ED',
}))

export const StyledBalanceTitle = styled('div')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: 16,
	lineHeight: '24px',
	textAlign: 'center',
	color: '#80848E',
}))

export const StyledBalanceValue = styled('div')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: 35,
	lineHeight: '52px',
	textAlign: 'center',
	color: '#000000',
	marginBottom: 40,
}))

export const StyledBalanceValueCurrency = styled('span')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 300,
	fontSize: 35,
	lineHeight: '52px',
	textAlign: 'center',
	color: '#000000',
	paddingRight: '3px',
}))

export const StyledInput = styled(Input)(({ theme }) => ({
	color: '#000000',
	fontSize: '16px !important',
	width: '416px',
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: '#80848E',
	fontSize: '16px !important',
}))

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
	'& p': {
		color: '#000000',
		fontSize: '16px !important',
	},
}))

export const StyledPaymentMethodtitle = styled('div')(({ theme }) => ({
	marginTop: 74,
	color: '#000000',
	marginBottom: 30,
	fontSize: 16,
	lineHeight: '24px',
	textAlign: 'left',
}))

export const StyledPaymentMethodFormControl = styled(FormControl)(
	({ theme }) => ({
		width: 420,
		margin: '0 auto',
		display: 'block',
		marginBottom: '45px',
	})
)

export const StyledPaymentMethod = styled(Box)(({ theme }) => ({
	// width: 420,
	// margin: "0 auto",
	marginBottom: 20,
}))

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	'& span:nth-child(2)': {
		color: '#000000',
		fontSize: '16px !important',
	},
	'& span:nth-child(1)': {
		'& span': {
			color: '#9E77ED',
		},
	},
}))

export const WorkItemTitle = styled('div')(({ theme }) => ({
	color: '#000000',
	marginBottom: 6,
	fontWeight: 600,
	fontSize: 12,
	lineHeight: '18px',
}))

export const WorkItemText = styled('div')(({ theme }) => ({
	color: '#000000',
	marginBottom: 6,
	fontWeight: 400,
	fontSize: 12,
	lineHeight: '20px',
}))

export const WorkItem = styled('div')(({ theme }) => ({
	marginBottom: 20,
}))

export const StyledButton = styled(Button)(({ theme }) => ({
	width: 228,
	color: '#9E77ED',
	textDecoration: 'unset',
	border: '1px solid #9E77ED',
	marginBottom: 50,
	marginTop: 95,
	'&:disabled': {
		opacity: 0.7,
		color: '#80848E',
		border: '1px solid #80848E',
	},
}))

export const StyledSpepsText = styled('div')(({ theme }) => ({
	fontSize: 14,
	lineHeight: '21px',
	textAlign: 'center',
	color: '#80848E',
	maxWidth: 340,
	margin: '0 auto',
	marginTop: -30,
	marginBottom: 55,
}))

export const StyledVerificationItem = styled(Grid)(({ theme }) => ({
	fontSize: 12,
	lineHeight: '18px',
	marginBottom: 32,
	fontWeight: 'bold',
	'&:last-child': {
		marginBottom: 0,
	},
}))

export const StyledCheckCircleIcon = styled(CheckCircleIcon)(() => ({
	marginRight: 8,
	width: 15,
	height: 15,
	color: '#419E6A',
}))

export const StyledCancelIcon = styled(CancelIcon)(() => ({
	marginRight: 8,
	width: 15,
	height: 15,
	color: '#80848E',
}))

export const StyledInputBRadius = styled('input')(({ theme }) => ({
	width: 349,
	border: '1px solid #E5E7EB',
	borderRadius: '16px',
	background: '#F9FAFB',
	padding: '13px 20px',
	marginRight: '22px',
}))
export const StyledInputDateBRadius = styled('input')(({ theme }) => ({
	width: 162,
	border: '1px solid #E5E7EB',
	borderRadius: '16px',
	background: '#F9FAFB',
	padding: '13px 20px',
	marginTop: '27px',
}))
export const CardNumberBlock = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
}))
