import {createTheme} from '@mui/material'
import {ThemeOptions} from "@mui/material/styles/createTheme";


const theme: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        }
    },
    typography: {
        fontFamily: '"Poppins", sans-serif',
        fontSize: 13,
        fontWeightRegular: 600,
        body1: {
            fontSize: '12px !important',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '18px'
        },
        h1: {
            fontSize: 40,
            fontWeight: 600,
            lineHeight: '60px'
        },
        h3: {
            fontSize: '30px',
            fontWeight: 600,
        },
        h4: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px'
        },
        h5: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px'
        },
        h6: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '21px'
        },
        caption: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px'
        },
        body2: {
            fontSize: '12px !important',
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '18px'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    fontFamily: '\'Poppins\', sans-serif;'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#52555E',
                    borderBottom: 'none !important',
                    padding: 0,
                    fontSize: 12,
                    '.MuiTableCell-root': {
                        borderBottom: 'none !important',
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                standard: {
                    fontSize: '14px !important',
                    fontWeight: 400,
                    lineHeight: '21px',
                    color: '#80848E',
                    // top: -11,
                },
                animated: {
                    color: '#80848E !important'
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&::before': {
                        borderColor: '#EEEEEE !important',
                    },
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                    '&.Mui-selected': {
                        //color: '#419E6A',
                        //background: 'rgba(65, 158, 106, 0.15)',
                    }
                },
                textColorPrimary: {
                    color: '#80848E',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 600
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    //background: '#419E6A',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-sizeMedium': {
                        padding: '10px 40px'
                    }
                },
                containedError: {
                    color: '#D83232',
                    background: 'rgba(216, 50, 50, 0.15)',
                    '&:hover': {
                        color: 'white'
                    }
                },
                sizeMedium: {
                    padding: '10px 40px'
                },
                sizeLarge: {
                    padding: '14px 130px',
                    fontSize: 16,
                    fontWeight: 400
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    svg: {
                        'path': {
                            fill: '#0C285F'
                        }
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                    paddingLeft: 0,
                    paddingRight: 0
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginRight: 14,
                    minWidth: 'unset'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {}
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: 21
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#f44336'
                }
            }
        },

    }
}

export const defaultTheme = createTheme(theme)

export const themeDark = createTheme({
    ...theme,
    palette: {
        background: {
            default: '#01091d00'
        },
        primary: {
            main: '#01C2FF',
            contrastText: '#80848E',
        },
        secondary: {
            main: '#52555E',
        },
        success: {
            main: '#419E6A'
        },
        mode: 'dark'
    },
    components: {
        ...theme.components,
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'white'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&::before': {
                        borderColor: '#0C285F !important',
                    },
                },
            }
        },
    }
})
