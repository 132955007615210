import { defaultSelectedPairId } from '../../app.config';
import {
  Currency,
  ILevel2,
  IMarket,
  IMarketsList,
  IOrder,
  ITicker,
  ITrending,
} from '../../types/exchange.types';

interface IState {
  socketLoading: {
    tickers: boolean,
    level2: boolean
  };
  isExchangeLoading: boolean;
  insufficientError: boolean;
  sellInsufficientError: boolean;
  orders?: IOrder[];
  cancelOrder: {
    loading: boolean;
    error: null;
    response: null;
  };
  currencies: Currency[];
  level2: ILevel2;
  tickers: ITicker | null;
  selectedPair?: Currency,
  markets: IMarket[],
  marketsList: IMarketsList | null,
  selectedPairID: number,
  trending: {
    loading: boolean,
    error: any,
    data: ITrending[]
  }
  pairChangingCounter: number
}

export const exchangeState: IState = {
  socketLoading: {
    tickers: true,
    level2: true
  },
  isExchangeLoading: false,
  insufficientError: false,
  sellInsufficientError: false,
  cancelOrder: {
    loading: false,
    error: null,
    response: null,
  },
  markets: [],
  currencies: [],
  trending: {
    loading: false,
    error: null,
    data: []
  },
  marketsList: null,
  level2: {
    Asks: [[]],
    Bids: [[]],
    Seq: 0,
    PairId: '0',
    // Size: string;
  },
  tickers: null,
  selectedPair: {
    "ID": 38,
    "CreatedAt": "2022-10-03T08:46:36.381989Z",
    "UpdatedAt": "2022-10-03T08:46:36.38199Z",
    "BaseCurrency": "BTC",
    "QuoteCurrency": "USDT",
    "BaseMinSize": "0.00001",
    "BaseMaxSize": "10000000",
    "BaseScale": 8,
    "QuoteMinSize": "0",
    "QuoteMaxSize": "0",
    "QuoteScale": 8,
    "OrderOffset": 0
  },
  selectedPairID: defaultSelectedPairId,
  pairChangingCounter: 0
};
