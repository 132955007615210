import { Box, Skeleton, Typography } from '@mui/material'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { CentrifugeContext } from 'providers/CentrifugeProvider'
import { FC, useCallback, useContext, useEffect } from 'react'
import { pairTickersGetThunk } from 'store/exchange/exchange.thunk'
import { MarketPlaceDrawer } from '../../../../components/Drawers/MarketPlaceDrawer'
import { CoinIcon } from '../../../../components/UI/atoms/CoinIcon/CoinIcon'
import { useDrawerManager } from '../../../../hooks/useDrawerManager'
import { themeDark } from '../../../../styles/theme'
import { EDrawerID } from '../../../../types/common.types'
import { StyledSelectPair } from './styles'

interface IProps {}

export const ExchangeHeader: FC<IProps> = ({}) => {
	const tickers = useTypedSelector(state => state.exchange.tickers)
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const { socketLoading } = useTypedSelector(state => state.exchange)
	const dispatch = useAppDispatch()
	const socketContext = useContext(CentrifugeContext)

	useEffect(() => {
		if (selectedPair) {
			dispatch(pairTickersGetThunk(selectedPair.ID))
		}
	}, [selectedPair])

	const { setDrawerId } = useDrawerManager()

	const handleClick = useCallback(() => {
		setDrawerId(EDrawerID.markets)
	}, [])

	return (
		<Box
			component={'div'}
			sx={{ background: '#0049DB0D', marginBottom: '5px' }}
			height={75}
			display={'flex'}
			alignItems={'start'}
			justifyContent={'space-between'}
			pt={20 / 8}
			pl={1.5}
			pb={20 / 8}
			pr={30 / 8}
		>
			<MarketPlaceDrawer />
			<Box
				display={'flex'}
				lineHeight={1}
				alignItems={'center'}
				height={'100%'}
			>
				{selectedPair && <CoinIcon coinName={selectedPair?.BaseCurrency} />}

				<Box lineHeight={1}>
					<Typography component={'span'} variant={'body2'}>
						{selectedPair?.BaseCurrency}
					</Typography>
					<Typography
						component={'span'}
						style={{ color: themeDark.palette.primary.contrastText }}
						variant={'body2'}
					>
						/{selectedPair?.QuoteCurrency}
					</Typography>
				</Box>
			</Box>

			<Box
				display={'flex'}
				lineHeight={1}
				height={'100%'}
				justifyContent={'left'}
				alignItems={'start'}
				flexDirection={'column'}
			>
				{/* <StyledSelect
          value={currentPairId}
          onChange={(e) => setCurrentPairId(e?.target?.value)}
					IconComponent={Icon}
        >
          {pairs?.map((pair, index) => {
            return (
              <MenuItem
                key={pair.ID}
                value={pair.ID}
              >{`${pair.BaseCurrency}/${pair.QuoteCurrency}`}</MenuItem>
            );
          })}
        </StyledSelect> */}
				<StyledSelectPair onClick={handleClick}>
					<Typography>All markets</Typography>
					<p>
						<img src='/images/exchange/chewronDown.svg' alt='' />
					</p>
				</StyledSelectPair>
			</Box>
			<Box
				display={'flex'}
				lineHeight={1}
				height={'100%'}
				justifyContent={'left'}
				alignItems={'start'}
				flexDirection={'column'}
				width={'160px'}
			>
				<Typography sx={{ color: 'secondary.main' }}>
					Last market price
				</Typography>
				{!socketContext.market ? (
					<Skeleton width={100} height={21} />
				) : (
					<Typography variant={'h6'} sx={{ color: 'success.main' }}>
						{socketContext.market.lastMarketPrice
							? Number(socketContext.market.lastMarketPrice).toFixed(5)
							: ''}
					</Typography>
				)}
			</Box>

			<Box
				display={'flex'}
				lineHeight={1}
				height={'100%'}
				justifyContent={'left'}
				alignItems={'start'}
				flexDirection={'column'}
				width={'240px'}
			>
				<Box>
					<Typography component={'span'} sx={{ color: 'secondary.main' }}>
						24h change:{' '}
					</Typography>
					{!socketContext.market ? (
						<Skeleton
							width={70}
							height={16}
							style={{
								display: 'inline-flex',
								position: 'relative',
								top: '1px',
							}}
						/>
					) : (
						<Typography
							component={'span'}
							sx={{
								color:
									Number(socketContext.market.change24H) < 0
										? '#f44336'
										: 'success.main',
							}}
						>
							{socketContext.market.change24H || ''}
						</Typography>
					)}
				</Box>
				<Box>
					<Typography component={'span'} sx={{ color: 'secondary.main' }}>
						Market volume:{' '}
					</Typography>
					{!socketContext.market ? (
						<Skeleton
							width={70}
							height={16}
							style={{
								display: 'inline-flex',
								position: 'relative',
								top: '1px',
							}}
						/>
					) : (
						<Typography component={'span'}>
							{socketContext.market.marketVolume}{' '}
						</Typography>
					)}
				</Box>
			</Box>

			<Box
				display={'flex'}
				lineHeight={1}
				height={'100%'}
				justifyContent={'left'}
				alignItems={'start'}
				flexDirection={'column'}
			>
				<Box>
					<Typography component={'span'} sx={{ color: 'secondary.main' }}>
						24h High:{' '}
					</Typography>
					{!socketContext.market ? (
						<Skeleton
							width={70}
							height={16}
							style={{
								display: 'inline-flex',
								position: 'relative',
								top: '1px',
							}}
						/>
					) : (
						<Typography component={'span'}>
							{socketContext.market.high24H
								? Number(socketContext.market.high24H).toFixed(5)
								: ''}
						</Typography>
					)}
				</Box>
				<Box>
					<Typography component={'span'} sx={{ color: 'secondary.main' }}>
						24h Low:{' '}
					</Typography>
					{!socketContext.market ? (
						<Skeleton
							width={70}
							height={16}
							style={{
								display: 'inline-flex',
								position: 'relative',
								top: '1px',
							}}
						/>
					) : (
						<Typography component={'span'}>
							{socketContext.market.low24h
								? Number(socketContext.market.low24h).toFixed(5)
								: ''}
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	)
}
