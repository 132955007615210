import {Box, Button} from "@mui/material";
import {tradingViewGraphUrl} from "../../../../app.config";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import {storageService} from "../../../../services/storage.service";
import { apiBaseUr } from "../../../../app.config";

export const ExchangeTradingChart = ({}) => {

    const urlParams = new URLSearchParams(window.location.search);
    const pair = urlParams.get('pair')

    const ref = useRef(null)
    const {selectedPair} = useTypedSelector(state => state.exchange)
    const [PAIR, setPAIR] = useState(pair)
    const [twWidget, setTwWidget] = useState(null)
    // const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
    //
    // useEffect(() => {
    //     if(selectedPair && ref.current) {
    //         // ref.current.contentWindow.postMessage('message', selectedPair)
    //     }
    // }, [selectedPair, ref])

    // useEffect(() => {
    //     if(ref.current) {
            // const options = {
            //     // debug: true, // uncomment this line to see Library errors and warnings in the console
            //     fullscreen: true,
            //     symbol: 'BTC/USDT',
            //     interval: '1D',
            //     container: ref.current,
            //
            //     //	BEWARE: no trailing slash is expected in feed URL
            //     datafeed: jsApi,
            //     library_path: "http://localhost/charting_library/",
            //     locale: getLanguageFromURL() || "en",
            //     enabled_features: ["study_templates"],
            //     charts_storage_url: 'https://saveload.tradingview.com',
            //     charts_storage_api_version: "1.1",
            //     client_id: 'tradingview.com',
            //     user_id: 'public_user_id',
            //     // theme: 'Dark',
            //     "hide_top_toolbar": true,
            //     "withdateranges": false,
            //     overrides: {
            //         "paneProperties.background": "#011137",
            //     },
            //     // disabled_features: ["header_widget", "left_toolbar" ,"use_localstorage_for_settings"],
            //
            //     // background: rgb(22, 31, 97);
            //     // background: linear-gradient(334deg, rgba(22,31,97,1) -25%, rgba(0, 0, 0, 1) 98%);
            //
            // }
            //
            // const widget = new window.TradingView.widget(options); // Create TV chart
        // }
    // }, [ref])

    useLayoutEffect(() => {
        if(ref && ref.current) {
            ref.current.contentWindow.postMessage({
                token: storageService.getAccessToken,
                message: 'PassTokens'
            }, '*')
        }
    }, [ref])

    useEffect(() => {
        if(ref && ref.current) {
            ref.current.contentWindow.postMessage({
                pair: `${selectedPair.BaseCurrency}/${selectedPair.QuoteCurrency}`,
                message: 'setPair'
            }, '*')

            setPAIR(`${selectedPair.BaseCurrency}/${selectedPair.QuoteCurrency}`)
        }

        if (twWidget) {
            twWidget.options.symbol = `BINANCE:${selectedPair.BaseCurrency}${selectedPair.QuoteCurrency}`
            twWidget.reload()
        }
    }, [selectedPair])

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://s3.tradingview.com/tv.js'
        script.async = true
        script.onload = () => {
            if (window.TradingView) {
                const tw = window.twWidget = new window.TradingView.widget({
                    width: '100%',
                    height: '560px',
                    "symbol": `BINANCE:${selectedPair.BaseCurrency}${selectedPair.QuoteCurrency}`,
                    "interval": "D",
                    "timezone": "Etc/UTC",
                    "theme": "dark",
                    "style": "1",
                    "locale": "en",
                    "toolbar_bg": "#000",
                    "enable_publishing": false,
                    "allow_symbol_change": false,
                    "container_id": "tradingview",
                    custom_css_url: '/tradingview/styles/custom.css',
                    studies_overrides: {
                        
                    }
                  });

                  setTwWidget(tw)
            }
        }

        document.body.appendChild(script)
    }, [])

    return (
        <>
            <Box width={"100%"} height={523} >
                {/* <iframe
                    title="TradingView"
                    ref={ref}
                    src={`${tradingViewGraphUrl}?token=${storageService.getAccessToken}&pair=${PAIR}&pairID=${selectedPair.ID}&baseUrl=${apiBaseUr}`}
                    style={{height: "100%", width: "100%", border: "none"}}
                /> */}
                <div className="tradingview-widget-container">
                    <div id="tradingview"></div>
                </div>
            </Box>
        </>
    );
};

//
// function getLanguageFromURL() {
//     const regex = new RegExp('[\\?&]lang=([^&#]*)');
//     const results = regex.exec(window.location.search);
//     return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
// }
//
// const configurationData = {
//     supports_marks: false,
//     supports_timescale_marks: false,
//     supports_time: true,
//     supported_resolutions: []
// }
//
//
// const externalData = {
//     pairs: [],
//     accessToken:
//         'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NjUzMzQ1MjQsInN1YiI6Imphc2lrLmFzaWsxNEBnbWFpbC5jb20iLCJ0eXBlIjoiYWNjZXNzIn0.4ulpfZ1Xm3KrAuD7FwXNqmXGWINxkA7paGRZ4xRBNWc'
//     ,
//     selectedPairId: '38'
// }
// const supportedResolutions = [1, 3, 5, 15, 30, 60, 120, 240, 360, 720, 1440]

// const jsApi = {
//     onReady: cb => {
//         console.log('=====onReady running')
//         setTimeout(() => cb(configurationData), 0)
//     },
//     searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
//         console.log('====Search Symbols running')
//     },
//     resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
//         // expects a symbolInfo object in response
//         console.log('======resolveSymbol running')
//         // console.log('resolveSymbol:',{symbolName})
//         var split_data = symbolName.split(/[:/]/)
//         // console.log({split_data})
//         var symbol_stub = {
//             name: symbolName,
//             description: '',
//             type: 'crypto',
//             session: '24x7',
//             timezone: 'Etc/UTC',
//             ticker: symbolName,
//             exchange: split_data[0],
//             minmov: 1,
//             pricescale: 100000000,
//             has_intraday: true,
//             intraday_multipliers: ['1', '60'],
//             supported_resolution: supportedResolutions,
//             volume_precision: 8,
//             data_status: 'streaming',
//         }
//
//         if (split_data[2] && split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
//             symbol_stub.pricescale = 100
//         }
//         setTimeout(function () {
//             onSymbolResolvedCallback(symbol_stub)
//             console.log('Resolving that symbol....', symbol_stub)
//         }, 0)
//
//         // onResolveErrorCallback('Not feeling it today')
//
//     },
//     getBars: function (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
//         console.log('=====getBars running')
//         try {
//             fetch(`http://192.248.179.197:8030/api/v1/pairs/${externalData.selectedPairId}/candles?to=${periodParams.to}&countback=${periodParams.countBack}`, {
//                 headers: {
//                     Authorization: externalData.accessToken
//                 }
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     console.log(res)
//                     if (Array.isArray(res) && res.length) {
//                         onHistoryCallback(res.map(res => ({
//                             ...res,
//                             time: res.time * 1000
//                         })))
//                     } else {
//                         onHistoryCallback([], {noData: true})
//                     }
//                 })
//         } catch (e) {
//             onErrorCallback(e)
//         }
//     },
//     subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
//         console.log('=====subscribeBars runnning')
//         window.interval = setInterval(function () {
//             fetch(`http://192.248.179.197:8030/api/v1/pairs/${externalData.selectedPairId}/candles?resolution=${'1W'}`, {
//                 headers: {
//                     Authorization: externalData.accessToken
//                 }
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (Array.isArray(res) && res.length) {
//                         onRealtimeCallback({
//                             ...res[res.length - 1],
//                             time: res[res.length - 1].time * 1000
//                         }, {noData: true})
//                     } else {
//                         onRealtimeCallback([], {noData: true})
//                     }
//                 })
//
//         }, 1000 * 60) // 60s update interval
//     },
//     unsubscribeBars: subscriberUID => {
//         console.log('=====unsubscribeBars running')
//     },
//     calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
//         //optional
//         console.log('=====calculateHistoryDepth running')
//         // while optional, this makes sure we request 24 hours of minute data at a time
//         // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
//         return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined
//     },
//     getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
//         //optional
//         // console.log('=====getMarks running')
//     },
//     getTimeScaleMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
//         //optional
//     },
//     getServerTime: cb => {
//         console.log('=====getServerTime running')
//     }
// }