import {FC} from 'react'
import {Button, Typography} from "@mui/material";
import {IFieldValue} from "../../../../types/form.types";

interface IProps {
    selected?: boolean
    value: IFieldValue
    onClick: () => void
}

export const BSelectButton: FC<IProps> = ({selected, value, onClick}) => {
    return <Button
        onClick={onClick}
        sx={{
            width: 65,
            height: 25,
            background: 'rgba(1, 87, 255, 0.05)'
        }}
        type={'button'}>
        <Typography color={selected ? 'white' : '#52555E'}>
            {value.label}
        </Typography>
    </Button>
}
