import './Wallets.styles';
import './wallets.module.scss';

import {
  forwardRef,
  useEffect,
  useState,
} from 'react';

import { AdditionalLayout } from 'components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { sortBy } from 'lodash';
import {
  NavLink,
  useNavigate,
} from 'react-router-dom';
import {
  setSelectedPair,
  setSelectedPairId,
} from 'store/exchange/exchange.slice';
import {
  getWalletBalanceThunk,
  getWalletsThunk,
} from 'store/wallets/wallets.thunk';
import {
  setSelectedDepositWallet,
  setSelectedWithdrawWallet
} from 'store/wallets/wallets.slice';
import { getCoinPath } from 'utils/common.utils';

import {
  Box,
  styled,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { currenciesGetThunk } from 'store/exchange/exchange.thunk';
import { addPairChangingCounter } from 'store/exchange/exchange.slice';

import {
  StyledBalanceTitle,
  StyledBalanceValue,
  StyledButton,
  StyledButtonHistory,
  StyledButtonTable,
  StyledButtonWithdraw,
  StyledCoinImg,
  StyledLeftPadding,
  StyledSearchIcon,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellHead,
  StyledTableCellHeadCoin,
  StyledTableCellThin,
  StyledTableRow,
  StyledTextField,
  StyledTitle,
  WalletsWrap,
} from './Wallets.styles';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledFieldWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: 50,
  paddingLeft: '64px'
}));

const Wallets = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate()
  const [search, setSearch] = useState<string>('')
  const [list, setList] = useState<any[] | null>(null);

  const { data, error, loading } = useTypedSelector(
    (state) => state.wallets.wallets
  );


  const walletsBalance = useTypedSelector(state => state.wallets.walletBalance)
  const {currencies} = useTypedSelector(state => state.exchange)

  useEffect(() => {
    if (!list) {
      dispatch(getWalletsThunk());
    }
  }, [list]);

  useEffect(() => {
    dispatch(getWalletBalanceThunk());
    dispatch(currenciesGetThunk())
  }, []);

  useEffect(() => {
    if (data) {
      setList(data);
    }
  }, [data]);

  const [open, setOpen] = useState(false);

  //Handlers

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (walletsBalance.error) {
      setOpen(true);
    }
  }, [walletsBalance.error]);

  const searchWallet = (value: string) => {
    if(!value) return setList(data)

    if (data) {
      const _data = data.filter((item) => item.currency.toLowerCase().indexOf(value.toLowerCase()) != -1);
      setList(_data);
    }
  };

  const computedSearchWallet = (() => {
    if(!walletsBalance.data?.balances ) return []
    if(!search) return walletsBalance.data?.balances 
    return walletsBalance.data?.balances.filter(market => market.currency?.toLowerCase().includes(search.toLowerCase()))
  })()
  // const resWithoutEur = computedSearchWallet.filter(item => item.currency !== "EUR")

  const setSelectedPairForTrade = (pair: string) => {
    const pairBody = currencies?.find(currency => currency.BaseCurrency === pair && currency.QuoteCurrency === 'USDT')
    
    if (pairBody) {
      dispatch(setSelectedPairId(pairBody.ID))
      dispatch(setSelectedPair(pairBody))
      dispatch(addPairChangingCounter())
      nav(`/exchange`)
    } else {
      const pairBody = currencies?.find(currency => currency.BaseCurrency === pair)
      if (pairBody) {
        if (Array.isArray(pairBody)) {
          dispatch(setSelectedPairId(pairBody[0].ID))
          dispatch(setSelectedPair(pairBody[0]))
          dispatch(addPairChangingCounter())
          nav(`/exchange`)
        } else {
          dispatch(setSelectedPairId(pairBody.ID))
          dispatch(setSelectedPair(pairBody))
          dispatch(addPairChangingCounter())
          nav(`/exchange`)
        }
      } else {
        const pairBody = currencies?.find(currency => currency.BaseCurrency === 'BTC' && currency.QuoteCurrency === 'USDT')
        if (pairBody) {
          dispatch(setSelectedPairId(pairBody.ID))
          dispatch(setSelectedPair(pairBody))
          dispatch(addPairChangingCounter())
          nav(`/exchange`)
        }
      } 
    }
  }

  const setWalletToWithdraw = (coin: string) => {
    dispatch(setSelectedWithdrawWallet(coin))
  }

  const setWalletToDeposit = (coin: string) => {
    dispatch(setSelectedDepositWallet(coin))
  }

  return (
    <AdditionalLayout >
      <WalletsWrap>
      <StyledLeftPadding>
        <Box display="flex" className='wallets' alignItems="center">
          <StyledTitle>Spot Wallet</StyledTitle>
          <NavLink to="deposit">
            <StyledButton variant="outlined">Deposit</StyledButton>
          </NavLink>
          <NavLink to="withdraw">
            <StyledButtonWithdraw variant="outlined">
              Withdraw
            </StyledButtonWithdraw>
          </NavLink>
          <NavLink to="transaction-history">
            <StyledButtonHistory variant="outlined">
            Transaction History
            </StyledButtonHistory>
          </NavLink>
        </Box>
      </StyledLeftPadding>
      <StyledBalanceTitle>Total balance</StyledBalanceTitle>
      <StyledBalanceValue>
      {walletsBalance.data ? Number(walletsBalance.data.total).toLocaleString('en-US', { style: 'currency', currency: 'EUR' }) : ''} 
        {/* ≈{" "} */}
        {/* <StyledBalanceValueCurrency>€12.520,45</StyledBalanceValueCurrency> */}
      </StyledBalanceValue>

      <StyledFieldWrapper>
        <StyledTextField
          id="input-with-icon-textfield"
          onChange={(e) => setSearch(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <StyledSearchIcon />
            </InputAdornment>
          }
          placeholder="Search for coin"
        />
      </StyledFieldWrapper>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <StyledTableCellHeadCoin align="left">
                <Box display="flex" alignItems="center">
                  <span>Crypto coin</span>
                  
                </Box>
              </StyledTableCellHeadCoin>
              <StyledTableCellHead align="left">
                <Box display="flex" alignItems="center">
                  <span>Quantity</span>
                  
                </Box>
              </StyledTableCellHead>
              <StyledTableCellHead align="left">
                <Box display="flex" alignItems="center">
                  <span>BTC Value</span>
                  {/* <div>
                    <StyledSortImg src="/images/triangle.svg" alt="triangle" />
                    <StyledSortImg src="/images/triangle.svg" alt="triangle" />
                  </div> */}
                </Box>
              </StyledTableCellHead>
              <StyledTableCellHead align="left">
                <Box display="flex" alignItems="center">
                  <span>EUR Value</span>
                </Box>
              </StyledTableCellHead>
              {/* <TableCell align="left" />
              <TableCell align="left" />
              <TableCell align="left" /> */}
            </TableRow>
          </TableHead>
          <StyledTableBody>
            {
              (walletsBalance.data && walletsBalance.data.balances.length) &&
              sortBy(computedSearchWallet, 'balance').reverse().map((wallet, index) => (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell padding="checkbox" />
                  <StyledTableCell align="left">
                    <Box display="flex" alignItems="center">
                      <StyledCoinImg src={`/images/coins/${getCoinPath(wallet.currency)}`} />
                      <span>{wallet.currency}</span>
                      {/* <StyledShortName>{row.currency}</StyledShortName> */}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">{wallet.balance.toFixed(5)}</StyledTableCell>
                  <StyledTableCell align="left">{wallet.balance_btc.toFixed(5)}</StyledTableCell>
                  <StyledTableCell align="left">€{wallet.balance_eur.toFixed(2)}</StyledTableCell>

                  <StyledTableCellThin align="center">
                    <NavLink to="deposit">
                      <StyledButtonTable variant="text" onClick={() => setWalletToDeposit(wallet.currency)}>Deposit</StyledButtonTable>
                    </NavLink>
                  </StyledTableCellThin>
                  <StyledTableCellThin align="center">
                    <NavLink to="withdraw">
                      <StyledButtonTable variant="text" onClick={() => setWalletToWithdraw(wallet.currency)}>Withdraw</StyledButtonTable>
                    </NavLink>
                  </StyledTableCellThin>
                  <StyledTableCellThin align="center">
                    <StyledButtonTable variant="text" onClick={() => setSelectedPairForTrade(wallet.currency)}>Trade</StyledButtonTable>
                  </StyledTableCellThin>

                  <StyledTableCellThin />
                </StyledTableRow>
              ))
          }
          </StyledTableBody>
        </Table>
      </TableContainer>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {
              walletsBalance.error?.detail ||
              walletsBalance.error?.message || error?.detail || error?.message || "An error has occurred"
          }
        </Alert>
      </Snackbar>
      </WalletsWrap>
     

      
    </AdditionalLayout>
  );
};

export default Wallets;
