import styled from '@emotion/styled'
import { Input, InputAdornment, InputLabel } from '@mui/material'

export const StyledForm = styled.form`
	padding: 13px;
	padding-top: 26px;
`
export const StyledInput = styled(Input)(({ theme }) => ({
	color: '#000000',
	fontSize: '16px !important',
	width: '588px',

	'&::after': {
		borderBottom: '2px solid #9E77ED',
	},

	// maxWidth:'588px'
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: '#80848E',
	fontSize: '16px !important',
}))

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
	'& p': {
		color: '#000000',
		fontSize: '16px !important',
	},
}))
