import { FC } from 'react';

import { useTypedSelector } from 'hooks/useTypedSelector';

interface IProps {
    level: number,
    progress: number
}

const TradingTierBlock: FC<IProps> = ({level, progress}) => {
    const volume = useTypedSelector(state => state.wallets.volume.data)
    return (
        <div className='tradingTierBlock'>
            <p className='tradingTierBlock_title'>Trading tier</p>
            <div className='tradingTierBlock_levelBlock'>
                <p className='tradingTierBlock_content'>Tier</p>
                <div className='tradingTierBlock_levelBox'>
                    <p className='tradingTierBlock_levelBoxText'>Level {volume?.currentLevel}</p>
                </div>
            </div>
            <div className='tradingTierBlock_currencyBox'>
                <p className='tradingTierBlock_content'>30d volume</p>
                <p className='tradingTierBlock_currency'>€ {volume?.volume30D}</p>
            </div>
            <div className='tradingTierBlock_levelProgressBar' style={{backgroundSize: `${((volume?.volume30D! - Number(volume?.levelFromVolume)) * 100) / (Number(volume?.levelToVolume) - Number(volume?.levelFromVolume))}%`}}>
                <p className='tradingTierBlock_levelProgressbarText'>Lvl. {volume?.currentLevel}</p>
                <p className='tradingTierBlock_levelProgressbarText'>Lvl. {volume?.nextLevel}</p>
            </div>
        </div>
    )
}

export default TradingTierBlock;