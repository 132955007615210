import {
  ICurrency,
  IGetWalletCurrency,
  ITransferWallet,
  IVolume,
} from '../types/exchange.types';
import { instance } from './axios';

export const walletsApi = {
  getWalletCurrency(data?: IGetWalletCurrency): Promise<ICurrency[]> {
    return instance.get('/api/v1/wallet/currency', {
      params: data
    })
  },
  getBalance(): Promise<any> {
    return instance.get("/api/v1/balance");
  },
  postTransfer(data: ITransferWallet) {
    return instance.post('/api/v1/wallet/transfer', data)
  },
  widthdrawFee(data: ITransferWallet) {
    return instance.post('/api/v1/wallet/fee', data)
  },
  getWalletBalance(): Promise<any> {
    return instance.get('/api/v1/wallet/balances')
  },

  getTransactions() {
    return instance.get('/api/v1/wallet/transactions')
  },
  getRates(): Promise<{
    status: string,
    message: string,
    order: {
      baseCurrency: string,
      quoteCurrency: string,
      price: string
    }[]
  }> {
    return instance.get('/api/v1/rates/eur/list')
  },
  getVolume(): Promise<IVolume> {
    return instance.get('/api/v1/volumes')
  }
};
