import { Button, styled } from '@mui/material'

export const HelpdeskLayout = styled('div')(({ theme }) => ({
	background: '#F5F5F5',
	display: 'flex',
	// marginTop: 20,
	//boxShadow: "0 4px 5px rgba(0, 0, 0, 0.25)",
	paddingBottom: '10px',
	paddingRight: 35,
	// minHeight: "calc(100vh - 81px)",
	// maxHeight: "calc(100vh - 0)",
	// marginBottom: "10px",
	// overflow: "auto",
}))

export const HTags = styled('div')(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	position: 'relative',
	marginTop: 15,

	'& > *:first-child': {
		marginLeft: 0,
	},
}))

export const HTagsInput = styled('input')(() => ({
	width: 100,
	appearance: 'none',
	zIndex: 1,
	webkitAppearance: 'none',
	mozAppearance: 'none',
	cursor: 'pointer',
	margin: '0 7px',
	height: 40,
	border: 'solid 1px #D1D1D1',

	'&:focus': {
		transition: 'all .10s linear',
	},
}))

export const HTagText = styled('p')(() => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',

	'&:focus': {
		transition: 'all .10s linear',
	},
}))

export const HelpdeskQAWrapper = styled('div')(({ theme }) => ({
	maxWidth: '62.5%',
	marginRight: 20,
	padding: '40px 68px 40px 40px',
	height: 902, //752 by UI, 862 by ourselves
	background: '#FFFFFF',
	borderRadius: '5px',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	flexBasis: '62.5%',
}))

export const HelpdeskRoutingInfo = styled('div')(({ theme }) => ({
	maxWidth: '37.5%',
	height: 434,
	padding: '29px 40px',
	background: '#FFFFFF',
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridColumnGap: '1rem',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	borderRadius: '5px',
	flexBasis: '37.5%',
}))

export const HelpdeskTitle = styled('p')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '20px',
	lineHeight: '30px',

	color: '#000000',
}))

export const HelpdeskSubtitle = styled('p')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '1rem',
	lineHeight: '15px',

	color: '#000000',
}))

export const HelpdeskContent = styled('p')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '20px',
	marginTop: 20,

	color: '#000000',
}))

export const HLabelBox = styled('div')(() => ({
	marginTop: 20,
}))

export const HLabelInput = styled('input')(() => ({
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '21px',
	fontFamily: 'Poppins',
	height: 40,
	paddingLeft: 10,
	width: '100%',
	marginTop: 25,
	border: '1px solid #D1D1D1',

	'&:focus': {
		outline: 'none',
	},
}))

export const HTextarea = styled('textarea')(({ theme }) => ({
	width: '100%',
	height: 195,
	fontFamily: 'Poppins',
	padding: '13px 10px',
	fontStyle: 'normal',
	border: '1px solid #D1D1D1',
	marginTop: 20,
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '21px',
	resize: 'none',

	'&:focus': {
		outline: 'none',
	},
	'&:placeholder': {
		color: '#A8A8A8',
	},
	color: '#000',
}))

export const StyledButton = styled(Button)(({ theme }) => ({
	width: 190,
	height: 35,
	fontFamily: 'Poppins',
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '14px',
	alignItems: 'center',
	textAlign: 'center',
	marginTop: 50,
	textTransform: 'capitalize',
	border: '1.5px solid #9E77ED',
	borderRadius: 4,
	'&.Mui-disabled': {
		borderColor: 'rgba(0, 0, 0, 0.26)',
	},
}))

export const InfoItem = styled('div')(() => ({
	height: 90,
	display: 'flex',
	flexDirection: 'column',
}))

export const HelpdeskInfoTitle = styled('h2')(() => ({
	fontFamily: 'Poppins',
	fontWeight: 600,
	fontSize: '20px',
	lineHeight: '30px',

	textAlign: 'center',
	color: '#000000',
}))

export const InfoItemTitle = styled('p')(() => ({
	fontFamily: 'Poppins',
	fontWeight: 600,
	fontSize: '0.75rem',
	lineHeight: '18px',

	color: '#000000',
}))

export const InfoItemContent = styled('p')(() => ({
	fontFamily: 'Poppins',
	fontWeight: 400,
	fontSize: '0.75rem',
	lineHeight: '18px',

	color: '#000000',
}))
