// import Overview from 'pages/Overview';
import React, { Suspense, useEffect, useLayoutEffect } from "react";

import { useCentrifuge } from "hooks/useCentrifuge";
import { useSnackbar } from "notistack";
//const Affiliates = lazy(() => import('pages/Affiliates'));
import Affiliates from "pages/Affiliates";
import AffiliatesMakeARequest from "pages/Affiliates/makeRequest";
import WithDrawComisson from "pages/Affiliates/WithdrawComission";
//const AuthPage = lazy(() => import('pages/auth'));
import AuthPage from "pages/auth";
import { ForgotPasswordEnterEmail } from "pages/auth/children/ForgotPasswordPage/EnterEmail";
import { SendedVerificationCodeToEmail } from "pages/auth/children/ForgotPasswordPage/SendedVerificationCodeToEmail";
//const RefreshPasswordPage = lazy(() => import('pages/auth/children/RefreshPasswordPage'))
import RefreshPasswordPage from "pages/auth/children/RefreshPasswordPage";
//const ConfirmEmailPage = lazy(() => import('pages/ConfirmEmail'))
import ConfirmEmailPage from "pages/ConfirmEmail";
//const Deposit = lazy(() => import('pages/Deposit'));
import Deposit from "pages/Deposit";
import { DepositError } from "pages/DepositError";
import { DepositSuccess } from "pages/DepositSuccess";
//Exchange Module
//const ExchangePage = lazy(() => import('pages/Exchange'));
import ExchangePage from "pages/Exchange";
//const OrdersPage = lazy(() => import('pages/Exchange/children/OrdersPage/OrdersPage'));
import OrdersPage from "pages/Exchange/children/OrdersPage/OrdersPage";
//const Markets = lazy(() => import('pages/Markets'));
import Markets from "pages/Markets";
// import Markets from 'pages/Markets';
//const Overview = lazy(() => import('pages/Overview'));
import Overview from "pages/Overview";
//const Settings = lazy(() => import('pages/Settings'));
import Settings from "pages/Settings";
import SimpleExchange from "pages/SimpleExchange";
//const Trade = lazy(() => import('pages/Trade'))
import Trade from "pages/Trade";
//const Verification = lazy(() => import('pages/Verification'));
import Verification from "pages/Verification";
//const Wallets = lazy(() => import('pages/Wallets'));
import Wallets from "pages/Wallets";
//const DepositCrypto = lazy(() => import('pages/Wallets/components/Deposit'))
import DepositCrypto from "pages/Wallets/components/Deposit";
//const TransactionHistory = lazy(() => import('pages/Wallets/components/History'))
import TransactionHistory from "pages/Wallets/components/History";
//const WithdrawCrypto = lazy(() => import('pages/Wallets/components/Withdraw'))
import WithdrawCrypto from "pages/Wallets/components/Withdraw";
import Withdraw from "pages/Withdraw";
import CentrifugeProvider from "providers/CentrifugeProvider";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../hooks/useAppDispatch";
import { useTypedSelector } from "../hooks/useTypedSelector";
import Helpdesk from "../pages/Helpdesk";
import { storageService } from "../services/storage.service";
import { getMarketListThunk } from "../store/exchange/exchange.thunk";
import { getMeThunk } from "../store/profile/profile.thunk";
import { getWalletBalanceThunk } from "../store/wallets/wallets.thunk";
import { Currency } from "../types/exchange.types";

const authModulePaths = [
	"/auth",
	"/auth/forgot-password",
	"/auth/refresh_email",
	"/auth/confirm_email",
];

export const MainRouting = () => {
	//Router
	const location = useLocation();
	const nav = useNavigate();
	const [createConnection, socket, changePair] = useCentrifuge();
	const { enqueueSnackbar } = useSnackbar();
	const { msg, timeStamp, variant } = useTypedSelector((state) => state.notistack);

	//Redux
	const dispatch = useAppDispatch();
	const { isLogin } = useTypedSelector((state) => state.profile);
	const { pairChangingCounter, selectedPair } = useTypedSelector((state) => state.exchange);

	//Effects
	useLayoutEffect(() => {
		// if(storageService.isHaveTokens) {
		//     dispatch(getMeThunk())
		//     dispatch(getWalletBalanceThunk());
		//     dispatch(getMarketListThunk())
		//     createConnection()
		// }
	}, []);

	useEffect(() => {
		if (storageService.isHaveTokens) {
			dispatch(getMeThunk());
			dispatch(getWalletBalanceThunk());
			dispatch(getMarketListThunk());
			createConnection();
		}
	}, [storageService.isHaveTokens]);

	// useEffect(() => {
	//     if (isLogin === false && !authModulePaths.includes(location.pathname)) {
	//         nav('/auth')
	//     }
	// }, [isLogin, location])

	//Watcher for pair changing
	useEffect(() => {
		if (pairChangingCounter >= 1 && selectedPair) {
			changePair(selectedPair as Currency);
		}
	}, [selectedPair, pairChangingCounter]);

	useEffect(() => {
		if (msg) {
			enqueueSnackbar(msg, {
				variant,
			});
		}
	}, [timeStamp]);

	return (
		<Suspense fallback={"Loading..."}>
			<CentrifugeProvider contextValue={socket}>
				<Routes>
					{storageService.isHaveTokens ? (
						<>
							<Route path='/' element={<Navigate to='/dashboard' />} />
							<Route path='/dashboard' element={<Navigate to='/dashboard/overview' />} />
							<Route path='/dashboard/' element={<Navigate to='/dashboard/overview' />} />
							<Route path='exchange'>
								<Route path={""} element={<ExchangePage />} />
								<Route path={"orders"} element={<OrdersPage />} />
							</Route>
							<Route path='/dashboard/'>
								<Route path='overview' element={<Overview />} />
								<Route path='verification'>
									<Route path={""} element={<Verification />} />
								</Route>
								<Route path='helpdesk' element={<Helpdesk />} />
								<Route path='affiliates' element={<Affiliates />} />
								<Route path='deposit' element={<Deposit />} />
								<Route path='withdraw' element={<Withdraw />} />
								<Route path='settings' element={<Settings />} />
								<Route path='wallets' element={<Wallets />} />
								<Route path='wallets' element={<Wallets />} />

								<Route path='wallets/deposit' element={<DepositCrypto />} />
								<Route path='wallets/withdraw' element={<WithdrawCrypto />} />
								<Route path='wallets/transaction-history' element={<TransactionHistory />} />
							</Route>

							<Route path='helpdesk' element={<Helpdesk />} />
							<Route path='affiliates'>
								<Route path='' element={<Affiliates />} />
							</Route>
							<Route path='deposit' element={<Deposit />} />
							<Route path='withdraw' element={<Withdraw />} />
							<Route path='settings' element={<Settings />} />
							<Route path='wallets' element={<Wallets />} />
							<Route path='wallets/deposit' element={<DepositCrypto />} />
							<Route path='wallets/withdraw' element={<WithdrawCrypto />} />
							<Route path='wallets/transaction-history' element={<TransactionHistory />} />
							<Route path={"/make-a-request"} element={<AffiliatesMakeARequest />} />
							<Route path={"/withdraw-comission"} element={<WithDrawComisson />} />
							<Route path={"/markets"} element={<Markets />} />
							<Route path='/trade' element={<Trade />} />
							<Route path='/simple-exchange' element={<SimpleExchange />} />
							<Route path='/payments/success' element={<DepositSuccess />} />
							<Route path='/payments/cancel/' element={<DepositError />} />
							<Route path='/payments/failure/' element={<DepositError />} />
						</>
					) : (
						<>
							<Route path={"/auth"}>
								<Route path='' element={<Navigate to='/signin' />} />
								<Route path='forgot-password' element={<ForgotPasswordEnterEmail />} />
								<Route path='refresh_email' element={<RefreshPasswordPage />} />
								<Route path={"confirm_email"} element={<ConfirmEmailPage />} />
							</Route>
							<Route
								path='/sended-verification-code-to-email/'
								element={<SendedVerificationCodeToEmail />}
							/>
							<Route path={"/signup"} element={<AuthPage status='signup' />} />
							<Route path={"/signin"} element={<AuthPage status='login' />} />
						</>
					)}
					<Route
						path='*'
						element={<Navigate to={storageService.isHaveTokens ? "/" : "/signin"} />}
					/>
				</Routes>
			</CentrifugeProvider>
		</Suspense>
	);
};
