import { Box } from '@mui/material';

export const SuccessCopy = (props: {text: string}) => {
    return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'10px'} position='relative' top='-22px'>
        <img src="/images/common/checkmark-green.svg" alt="Check mark"/>
        <Box>
            <p className={'web11 success-color'}>Success</p>
            <p className={'web10 subtitle-1-color'}>{props.text} copied</p>
        </Box>
    </Box>
}