export const networks = [
    {
        value: "ERC20",
        label: "Ethereum (ERC20)",
    },
    {
        value: "BEP20",
        label: "Binance Smart Chain (BEP20)",
    },
    {
        value: "BTC",
        label: "Bitcoin",
    },
];
