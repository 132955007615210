import { FC, useState } from 'react'

import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { ChangeAddress } from 'pages/Settings/components/ChangeAddress'
import { ChangePhone } from 'pages/Settings/components/ChangePhone'
import { StyledDialog } from 'pages/Settings/Settings.style'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton } from '@mui/material'

import { IVerification } from '../../../../types/common.types'
import {
	IConfirmationGet,
	IStatusConfirmation,
} from '../../../../types/confirmation.types'

interface IProps {
	// confirmationInfo: IConfirmationGet | undefined,
	verification: IVerification | undefined
	confirmationInfo: IConfirmationGet | undefined
	statusHandler: (value: IStatusConfirmation) => void
}

const VerificationLayout: FC<IProps> = ({
	verification,
	statusHandler,
	confirmationInfo,
}) => {
	// TODO modal must be integrated further
	const dispatch = useAppDispatch()
	// useEffect(() => {
	//     //dispatch(confirmationGetThunk());
	// }, [statusHandler]);

	// const {confirmationInfo} = useTypedSelector((state) => state.dashboard);
	const [openModal, setOpenModal] = useState(false)
	const { data: addressData } = useTypedSelector(state => state.profile.address)
	const me = useTypedSelector(state => state.profile.me)
	const [openPhone, setOpenPhone] = useState(false)
	const [openAddress, setOpenAddress] = useState(false)

	return (
		<>
			<ChangePhone open={openPhone} setOpen={setOpenPhone} />
			<ChangeAddress open={openAddress} setOpen={setOpenAddress} />
			{/*<WhyDenialModal /> Modal for why not accepted status*/}

			<div className='verification--card'>
				<div className='verification--desc-container'>
					<Box
						display='flex'
						alignItems='flex-end'
						justifyContent='space-between'
					>
						<div>
							<h4>Verification</h4>
							<p className='text'>
								Please verify the information listet below in order to start
								trading, depositing or withdrowing digital assets.
							</p>
							{confirmationInfo?.applicant?.moderation_comment ||
							(confirmationInfo?.applicant &&
								confirmationInfo?.applicant.reject_type === 'RETRY') ? (
								<div className='doc-fail-text'>
									Your ID Verification is not succesfull,
									<span
										onClick={() => setOpenModal(true)}
										style={{
											display: 'inline-block',
											borderBottom: '1px solid #D83232',
											cursor: 'pointer',
											paddingBottom: '2px',
										}}
									>
										find out why
									</span>
								</div>
							) : null}
						</div>
						<div className='verification--badge-container'>
							<div className='verification--level'>
								<span>{me ? me.verification.level : 1}</span>
								<span>Level</span>
							</div>
							<img
								alt=''
								src='/images/verification/badge.svg'
								width={136}
								height={136}
							/>
						</div>
					</Box>

					<div className='verification--description'></div>
				</div>
			</div>
			<div className='verification--card'>
				<div className='verification--steps'>
					<div className='verification--step'>
						<div className='verification--step-info'>
							<h4>Email verification</h4>
							<p>Maximize security and protect funds</p>
						</div>

						{verification?.email ? (
							<div className='verification--step-success'>
								<span>Verified!</span>
							</div>
						) : (
							<div onClick={setOpenPhone.bind(null, true)}>
								<Button
									style={{
										color: '#9E77ED',
									}}
								>
									Verify
								</Button>
							</div>
						)}
					</div>
					{/* Start */}
					<div className='verification--step'>
						<div className='verification--step-info'>
							<h4>Phone number verification</h4>
							<p>Maximize security and protect funds</p>
						</div>
						{verification?.phone ? (
							<div className='verification--step-success'>
								<span>Verified!</span>
							</div>
						) : (
							<div onClick={setOpenPhone.bind(null, true)}>
								<Button
									style={{
										color: '#9E77ED',
									}}
								>
									Verify
								</Button>
							</div>
						)}
					</div>
					<div className='verification--step'>
						<div className='verification--step-info'>
							<h4>Identity verification</h4>
							<p>Verify your identity with documents</p>
						</div>

						{confirmationInfo && (
							<>
								{confirmationInfo?.applicant &&
								confirmationInfo.applicant?.result_doc ? (
									<Verified />
								) : confirmationInfo.applicant?.status_doc === 'new' ? (
									<div>
										<Button
											disabled
											onClick={() => {
												statusHandler('identity')
											}}
											style={{
												color: 'rgba(0, 0, 0, 0.4)',
											}}
										>
											{' '}
											Pending
										</Button>
									</div>
								) : confirmationInfo.applicant?.reject_type === 'RETRY' ? (
									<div
										onClick={() => {
											statusHandler('identity')
										}}
									>
										<Button
											style={{
												color: '#EFB008',
												border: '1px solid #EFB008',
											}}
										>
											{' '}
											Retry
										</Button>
									</div>
								) : confirmationInfo.applicant?.reject_type === 'FINAL' ? (
									<div>
										<Button
											disabled={
												confirmationInfo.applicant?.reject_type === 'FINAL'
											}
											onClick={() => {
												statusHandler('identity')
											}}
											style={{
												color: '#D83232',
												border: '1.5px solid #D83232',
												padding: 4,
											}}
										>
											{' '}
											Not accepted
										</Button>
									</div>
								) : (
									<div
										onClick={() => {
											statusHandler('identity')
										}}
									>
										<Button
											style={{
												color: '#9E77ED',
											}}
										>
											{' '}
											Verify
										</Button>
									</div>
								)}
							</>
						)}
					</div>
					<div className='verification--step'>
						<div className='verification--step-info'>
							<h4>Address information</h4>
							<p>Enter your address information</p>
						</div>
						{addressData ? (
							<div className='verification--step-success'>
								<span>Verified!</span>
							</div>
						) : (
							<div onClick={setOpenAddress.bind(null, true)}>
								<Button
									style={{
										color: '#9E77ED',
									}}
								>
									Enter
								</Button>
							</div>
						)}
					</div>
					{confirmationInfo?.applicant && (
						<div className='verification--step'>
							<div className='verification--step-info'>
								<h4>Complete facial scan</h4>
								<p>Make a scan of your face to compare with ID</p>
							</div>
							{confirmationInfo.applicant && (
								<>
									{confirmationInfo.applicant &&
										confirmationInfo.applicant.result_selfie === true && (
											<Verified />
										)}
									{confirmationInfo.applicant.status_selfie === 'new' && (
										<div>
											<Button
												disabled={
													confirmationInfo.applicant.status_selfie === 'new'
												}
												style={{
													color:
														confirmationInfo.applicant.status_selfie === 'new'
															? 'rgba(0, 0, 0, 0.4)'
															: '#9E77ED',
												}}
											>
												Pending
											</Button>
										</div>
									)}
									{confirmationInfo.applicant.status_selfie === null && (
										<div onClick={() => statusHandler('facial-scan')}>
											<Button
												style={{
													color: '#9E77ED',
												}}
											>
												Verify
											</Button>
										</div>
									)}
								</>
							)}
						</div>
					)}
					<StyledDialog open={openModal} onClose={setOpenModal}>
						<div style={{ padding: '38px 38px 38px 46px', width: 552 }}>
							<div className='modal-head'>
								<h3 className='modal-title'> Verification ID denied</h3>
								<IconButton onClick={setOpenModal.bind(null, false)}>
									<CloseIcon />
								</IconButton>
							</div>
							<span className='red-text'>
								Please solve the following issue(s) and re-upload your document
							</span>
							<br />

							<div>
								{confirmationInfo?.applicant?.document.map((item: any) => {
									return item.active ? (
										<div>
											<div style={{ marginTop: 12, fontWeight: 600 }}>
												{item.id_doc_sub_type}{' '}
											</div>
											<span
												style={{
													fontFamily: 'Poppins',
													fontWeight: 400,
													fontSize: ' 12px',
													lineHeight: '18px',
													color: '#80848E',
												}}
											>
												{item.moderation_comment}
											</span>
										</div>
									) : (
										''
									)
								})}
							</div>
							<div className='modal-content'>
								{' '}
								{confirmationInfo?.applicant?.moderation_comment}
							</div>
							<Button
								style={{
									width: 191,
									height: 39,
									color: '#9E77ED',
									display: 'flex',
									justifyContent: 'center',
									border: '1.5px solid #9E77ED',
									margin: '50px auto 0 auto',
									textTransform: 'initial',
								}}
								onClick={() => statusHandler('identity')}
								variant='outlined'
							>
								Send again
							</Button>
						</div>
					</StyledDialog>
				</div>
			</div>
		</>
	)
}

const Verified = () => (
	<div className='verification--step-success'>
		<span>Verified!</span>
	</div>
)

export default VerificationLayout
