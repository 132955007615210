import { FC, MouseEvent, useState } from 'react'

import styled from '@emotion/styled'
import {
	Box,
	Icon,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material'

import { CoinIcon } from '../../../../components/UI/atoms/CoinIcon/CoinIcon'

interface IProps {
	value: {
		address: string
		currency: string
		network: string
		balance: number
		balance_eur: number
		balance_btc: number
	}
}

export const ExchangeBalanceItem: FC<IProps> = ({ value }) => {
	const { balance, currency, balance_eur } = value

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	return (
		<ListItem
			component={'div'}
			sx={{
				'.MuiListItemSecondaryAction-root': {
					width: 22,
				},
				paddingRight: 6,
			}}
			secondaryAction={
				<>
					<IconButton
						onClick={handleClick}
						id={'menu'}
						sx={{ paddingRight: 0 }}
					>
						<img
							src={`/images/exchange/moreVertOutlined${
								open ? 'Active' : ''
							}.svg`}
							alt='more vert'
						/>
					</IconButton>
					<StyledMenu
						id='menu'
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'menu',
						}}
					>
						<MenuItem sx={{ display: 'flex' }} onClick={handleClose}>
							<img src='/images/exchange/deposit.svg' alt='deposit' />
							<Typography
								sx={{ marginLeft: '21px' }}
								color={'#01143B'}
								variant={'h5'}
							>
								Deposit
							</Typography>
						</MenuItem>
						<MenuItem sx={{ display: 'flex' }} onClick={handleClose}>
							<img src='/images/exchange/withdraw.svg' alt='deposit' />
							<Typography
								sx={{ marginLeft: '21px' }}
								color={'#01143B'}
								variant={'h5'}
							>
								Withdraw
							</Typography>
						</MenuItem>
					</StyledMenu>
				</>
			}
		>
			<ListItemIcon>
				<Icon
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CoinIcon
						coinName={value.currency}
						style={{ width: 20, height: 20, marginRight: 0 }}
					/>
				</Icon>
			</ListItemIcon>
			<ListItemText
				disableTypography
				primary={
					<Box
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}
					>
						<Typography>{currency}</Typography>
						<Typography>{balance.toFixed(2)}</Typography>
					</Box>
				}
				secondary={
					<Box
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}
					>
						<Typography sx={{ color: 'secondary.main' }}>{currency}</Typography>
						<Typography sx={{ color: 'secondary.main' }}>
							≈{' '}
							{balance_eur.toLocaleString('en-US', {
								style: 'currency',
								currency: 'EUR',
							})}
						</Typography>
					</Box>
				}
			/>
		</ListItem>
	)
}

const StyledMenu = styled(Menu)`
	.MuiPaper-root {
		background: #9e77ed;
		color: #01143b;
		transform: translateX(-50px) translateY(-50px) !important;

		&:last-of-type {
			transform: translateX(-50px) translateY(-20px) !important;
		}
	}
`
