import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { useFormik } from 'formik'
import { StyledBTCTextField } from 'pages/Exchange/Exchange.style'
import { CentrifugeContext } from 'providers/CentrifugeProvider'
import * as yup from 'yup'

import styled from '@emotion/styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
	Box,
	Button,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	Typography,
} from '@mui/material'

import { BSelectButtons } from '../../../../components/UI/organisms/BSelectButtons/BSelectButtons'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { selectBalanceByCurrency } from '../../../../store/selectors/exchange.selectors'
import { IOrderCreateReq } from '../../../../types/exchange.types'

interface IProps {
	onSubmit: (data: IOrderCreateReq) => void
}

const initialValues: Partial<IOrderCreateReq> = {
	Type: 'market',
	Side: 'buy',
	TimeInForce: '',
	Price: '',
	TriggerPrice: '',
	ShowTriggerPrice: false,
	ShowPrice: true,
	Pair: {
		PairId: 0,
		BaseCurrency: '',
		QuoteCurrency: '',
	},
}

const validationSchema = yup.object({
	ShowTriggerPrice: yup.boolean(),
	Type: yup.string().required('This Field is required'),
	Price: yup.string().when('ShowPrice', {
		is: true,
		then: yup.string().required('This Field is required'),
	}),
	Size: yup.string().required('This Field is required'),
	TriggerPrice: yup.string().when('ShowTriggerPrice', {
		is: true,
		then: yup.string().required('This Field is required'),
	}),
})

export const ExchangeBuySell: FC<IProps> = ({ onSubmit }) => {
	//States
	const [value, setValue] = useState(0)
	const selectedWallet = useTypedSelector(state => state.wallets.selectedWallet)
	const volume = useTypedSelector(state => state.profile.me?.volume)
	const socketContext = useContext(CentrifugeContext)
	const [openShark, setOpenShark] = useState(false)
	const {
		handleSubmit,
		handleChange,
		values,
		errors,
		touched,
		setFieldValue,
		setFieldTouched,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit(data) {
			const orderFormData = { ...data }
			delete orderFormData.ShowTriggerPrice
			delete orderFormData.ShowPrice

			if (!orderFormData.TriggerPrice) {
				orderFormData.TriggerPrice = null
			}

			if (!orderFormData.Price) {
				orderFormData.Price = null
			}

			onSubmit(orderFormData as IOrderCreateReq)
		},
	})

	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const { tickers } = useTypedSelector(state => state.exchange)
	const selectedWalletSell = useTypedSelector(
		selectBalanceByCurrency(selectedPair ? selectedPair.QuoteCurrency : '')
	)
	const selectedWalletBuy = useTypedSelector(
		selectBalanceByCurrency(selectedPair ? selectedPair.BaseCurrency : '')
	)

	//Handler
	const handleChangeTab = useCallback((_: any, value: number) => {
		setValue(value)
		if (value) {
			setFieldValue('Side', 'sell')
		} else {
			setFieldValue('Side', 'buy')
		}
	}, [])

	const handleClickSelectButton = (valuePercent: number) => {
		setOpenShark(true)
		if (!value) {
			if (values.Type === 'market') {
				if (computedSelectedSideInfo.balance) {
					const part =
						(valuePercent / 100) *
						(valuePercent === 100
							? computedSelectedSideInfo.balance -
							  (computedSelectedSideInfo.balance / 100) *
									Number(volume?.maker_fee)
							: computedSelectedSideInfo.balance)
					setFieldValue('Size', String(part / Number(values.Price)))
				}
			} else {
				if (values.Price && computedSelectedSideInfo.balance) {
					const part =
						(valuePercent / 100) *
						(valuePercent === 100
							? computedSelectedSideInfo.balance -
							  (computedSelectedSideInfo.balance / 100) *
									Number(volume?.maker_fee)
							: computedSelectedSideInfo.balance)
					setFieldValue('Size', String(part / Number(values.Price)))
				}
			}
		} else {
			if (values.Type === 'market') {
				if (computedSelectedSideInfo.balance) {
					const part =
						(valuePercent / 100) *
						(valuePercent === 100
							? computedSelectedSideInfo.balance -
							  (computedSelectedSideInfo.balance / 100) *
									Number(volume?.taker_fee)
							: computedSelectedSideInfo.balance)
					setFieldValue('Size', String(part))
				}
			} else {
				if (values.Price && computedSelectedSideInfo.balance) {
					const part =
						(valuePercent / 100) *
						(valuePercent === 100
							? computedSelectedSideInfo.balance -
							  (computedSelectedSideInfo.balance / 100) *
									Number(volume?.taker_fee)
							: computedSelectedSideInfo.balance)
					setFieldValue('Size', String(part))
				}
			}
		}
	}

	//Computed
	const computeDisabledForm = useMemo((): boolean => {
		// @ts-ignore
		return (
			!selectedWallet ||
			selectedWallet.balance <= 0 ||
			+values!['Size']! > selectedWallet.balance
		)
	}, [selectedWallet, values.Size])

	const computedSelectedSideInfo = useMemo(() => {
		const data = {
			total: '0.00',
			currency: selectedWalletSell?.currency,
			balance: selectedWalletSell?.balance,
		}

		if (!value) {
			if (values.Price && values.Size) {
				data.total = (Number(values.Price) * Number(values.Size)).toFixed(2)
			} else if (values.Size && tickers?.lastMarketPrice) {
				data.total = (
					Number(tickers?.lastMarketPrice) * Number(values.Size)
				).toFixed(2)
			}
		} else {
			if (values.Price && values.Size) {
				data.total = String(Number(values.Size).toFixed(5))
			} else if (values.Size && tickers?.lastMarketPrice) {
				data.total = String(Number(values.Size).toFixed(5))
			}
		}

		if (value === 1) {
			data.currency = selectedWalletBuy?.currency
			data.balance = selectedWalletBuy?.balance
			return data
		}
		return data
	}, [value, values, selectedWalletSell, selectedWalletBuy])

	//Effects
	useEffect(() => {
		if (selectedPair) {
			setFieldValue('Pair', {
				PairId: selectedPair.ID,
				BaseCurrency: selectedPair.BaseCurrency,
				QuoteCurrency: selectedPair.QuoteCurrency,
			})
		}
	}, [selectedPair])

	useEffect(() => {
		if (values.Type === 'market' && socketContext.market) {
			setFieldValue('Price', socketContext.market.lastMarketPrice)
		}
	}, [values.Type, socketContext.market])

	useEffect(() => {
		// обнуление при выборе типа ордера
		if (values.Type !== 'market') {
			setFieldValue('Price', '')
			setFieldValue('Size', '')
		}

		// Обновление правил валидации при выборе типа ордера
		if (values.Type === 'stop_loss' || values.Type === 'take_profit') {
			setFieldValue('ShowTriggerPrice', true)
			setFieldValue('ShowPrice', false)
		} else if (values.Type === 'stop_limit') {
			setFieldValue('ShowTriggerPrice', true)
			setFieldValue('ShowPrice', true)
		} else {
			setFieldValue('ShowTriggerPrice', false)
			setFieldValue('ShowPrice', true)
		}
	}, [values.Type])

	return (
		<Box width={'100%'} sx={{ background: '#0049DB0D' }}>
			{/* <pre style={{color: '#fff'}}>{JSON.stringify(computedSelectedSideInfo, null, 2)}</pre> */}
			<Tabs
				sx={{
					width: '100%',
				}}
				value={value}
				onChange={handleChangeTab}
				aria-label='basic tabs example'
				TabIndicatorProps={{
					style: {
						backgroundColor: !value ? '#419E6A' : '#D83232',
					},
				}}
			>
				<Tab
					sx={{
						width: '50%',
						'&.Mui-selected': {
							color: '#419E6A',
							background: 'rgba(65, 158, 106, 0.15)',
						},
					}}
					label='Buy'
				/>
				<Tab
					sx={{
						width: '50%',
						'&.Mui-selected': {
							color: '#D83232',
							background: 'rgba(216, 50, 50, .15)',
						},
					}}
					label='Sell'
				/>
			</Tabs>

			<StyledForm onSubmit={handleSubmit}>
				<FormControl fullWidth>
					<InputLabel id='Type'>List</InputLabel>
					<Select
						name={'Type'}
						displayEmpty
						value={values.Type}
						onChange={handleChange}
						input={
							<Input
								sx={{
									borderBottom: 'none',
									'&::before': {
										borderColor: '#0C285F !important',
									},
									mb: 4,
									img: {
										marginRight: '10px',
									},
								}}
							/>
						}
						inputProps={{ 'aria-label': 'Without label' }}
						IconComponent={() => <KeyboardArrowDownIcon color={'primary'} />}
					>
						{coins.map(({ name, value }, index) => (
							<MenuItem value={value} key={index}>
								{name}
							</MenuItem>
						))}
					</Select>
					{errors.Type && (
						<Typography color={'error'} variant={'caption'}>
							{errors.Type}
						</Typography>
					)}
				</FormControl>
				{values.ShowPrice && (
					<Box>
						<StyledBTCTextField
							sx={{ width: '100%' }}
							disabled={values.Type !== undefined && values.Type === 'market'}
							name={'Price'}
							type='number'
							onKeyDown={evt =>
								['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
							}
							error={!!errors.Price}
							helperText={touched.Price && errors.Price}
							onChange={handleChange}
							value={values.Price}
							label={'Price'}
							InputLabelProps={{
								shrink: values.Type !== undefined,
							}}
							InputProps={{
								endAdornment: (
									<Typography variant={'h6'}>
										{selectedPair && selectedPair.QuoteCurrency}
									</Typography>
								),
							}}
							variant={'filled'}
						/>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Typography
								sx={{
									display: 'flex',
									alignItems: 'center',
									color: 'success.main',
								}}
							>
								<ArrowDropDownIcon />
								{selectedPair && selectedPair.BaseMinSize}
							</Typography>

							<Typography
								sx={{ display: 'flex', alignItems: 'center' }}
								color={'error'}
							>
								<ArrowDropDownIcon />
								{selectedPair && selectedPair.QuoteMinSize}
							</Typography>
						</Box>
					</Box>
				)}
				{values.ShowTriggerPrice && (
					<Box style={{ marginBottom: '20px' }}>
						<StyledBTCTextField
							sx={{ width: '100%' }}
							name={'TriggerPrice'}
							type='number'
							onKeyDown={evt =>
								['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
							}
							error={!!errors.TriggerPrice}
							helperText={touched.TriggerPrice && errors.TriggerPrice}
							onChange={handleChange}
							value={values.TriggerPrice}
							label={'Trigger price'}
							InputLabelProps={{
								shrink: values.Type !== undefined,
							}}
							InputProps={{
								endAdornment: (
									<Typography variant={'h6'}>
										{selectedPair && selectedPair.QuoteCurrency}
									</Typography>
								),
							}}
							variant={'filled'}
						/>
					</Box>
				)}

				<StyledBTCTextField
					sx={{
						width: '100%',
					}}
					name={'Size'}
					type='number'
					onFocus={() => setOpenShark(true)}
					onBlur={() => setOpenShark(false)}
					onKeyDown={evt => {
						return (
							['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
						)
					}}
					onChange={handleChange}
					error={!!(touched.Size && errors.Size)}
					value={values.Size}
					label={'Amount'}
					helperText={touched.Size && errors.Size}
					InputLabelProps={{
						shrink: openShark,
					}}
					InputProps={{
						endAdornment: (
							<Typography variant={'h6'}>
								{selectedPair && selectedPair.BaseCurrency}
							</Typography>
						),
					}}
					variant={'filled'}
				/>

				<Box>
					<BSelectButtons
						onClick={handleClickSelectButton}
						items={[
							{
								value: 25,
								id: '1',
								label: '25%',
							},
							{
								value: 50,
								id: '2',
								label: '50%',
							},
							{
								value: 75,
								id: '3',
								label: '75%',
							},
							{
								value: 100,
								id: '4',
								label: '100%',
							},
						]}
						style={{ mt: 4, mb: 5 }}
					/>
				</Box>
				<Box>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Typography variant={'h4'} color={'#52555E'}>
							Total
						</Typography>
						<Box>
							<Typography component={'span'} variant={'h4'}>
								{computedSelectedSideInfo.total}{' '}
							</Typography>

							<Typography component={'span'} color={'#80848E'} variant={'h4'}>
								{computedSelectedSideInfo.currency}
							</Typography>
						</Box>
					</Box>

					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Typography color={'#52555E'}>Available</Typography>
						<Box>
							<Typography component={'span'} variant={'body2'}>
								{computedSelectedSideInfo.balance}{' '}
							</Typography>

							<Typography
								component={'span'}
								color={'#80848E'}
								variant={'body2'}
							>
								{computedSelectedSideInfo.currency}
							</Typography>
						</Box>
					</Box>
					<Button
						disabled={computeDisabledForm}
						type={'submit'}
						sx={{ width: '100%', mt: 2.5 }}
						variant={'outlined'}
						color={value ? 'error' : 'success'}
					>
						{value ? 'Sell' : 'Buy'}
					</Button>
				</Box>
			</StyledForm>
		</Box>
	)
}

const StyledForm = styled.form`
	padding: 13px;
	padding-top: 26px;
`

const coins = [
	{
		name: 'Limit',
		value: 'limit',
	},
	{
		name: 'Market',
		value: 'market',
	},
	{
		name: 'Stop loss',
		value: 'stop_loss',
	},
	{
		name: 'Stop limit',
		value: 'stop_limit',
	},
	{
		name: 'Take profit',
		value: 'take_profit',
	},
]
