import {FC, Fragment} from 'react'
import {IFieldValue} from "../../../../types/form.types";
import {Box} from "@mui/material";
import {BSelectButton} from "../../molecules/BSelectButton/BSelectButton";
import {SxProps} from "@mui/system";

interface IProps {
    items: IFieldValue<number>[]
    style?: SxProps
    onClick: (percent: number) => void
}

export const BSelectButtons: FC<IProps> = ({items, style, onClick}) => {
    return <Box sx={style} display={'flex'} justifyContent={'space-between'}
                alignItems={'center'} width={'100%'} flexWrap={'wrap'}>
        {
            items.map(item => <Fragment key={item.id}><BSelectButton onClick={() => onClick(item.value)} value={item}/></Fragment>)
        }
    </Box>
}
