import {FC, ReactNode} from 'react'
import {DefaultLayout} from "../DefaultLayout/DefaultLayout";
import {ExchangeNav} from "../../pages/Exchange/components/ExchangeNav/ExchangeNav";
import {Box} from "@mui/material";

interface IProps {
    children: ReactNode
}

export const ExchangeLayout: FC<IProps> = ({children}) => {
    return <DefaultLayout mode={'dark'}>
        <ExchangeNav/>
        <Box width={'100%'} height={'100%'} pl={12}>
            {
                children
            }
        </Box>
    </DefaultLayout>
}
