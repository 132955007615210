import { CSSProperties } from "react";
import {MarketPlacePairItem} from "../../molecules/MarketPlacePairItem/MarketPlacePairItem";
import { useTypedSelector } from "hooks/useTypedSelector";
import {
  addPairChangingCounter,
  setSelectedPair,
  setSelectedPairId
} from "../../../../store/exchange/exchange.slice";
import {MarketPair} from "../../../../types/exchange.types";
import { useAppDispatch } from "hooks/useAppDispatch";
import {useDrawerManager} from "hooks/useDrawerManager";

export const Row = ({index, style, data}: {index: number, style: CSSProperties, data: MarketPair[]}) => {
  const dispatch = useAppDispatch()
  const {setDrawerId} = useDrawerManager()
  const {currencies} = useTypedSelector(state => state.exchange)

  const handleClick = (pair: MarketPair) => {
    const filteredCurrencies = currencies?.filter(currency => String(currency.ID) === pair.pairId)
    if (filteredCurrencies && filteredCurrencies.length) {
      dispatch(setSelectedPair(filteredCurrencies[0]))
    }
    
    dispatch(setSelectedPairId(+pair.pairId))
    setDrawerId(null)
    dispatch(addPairChangingCounter())
  }

  return (
      <MarketPlacePairItem
          style={style}
          key={index}
          onClick={handleClick}
          value={data[index]}
      />
  )
}