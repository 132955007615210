import {
  FC,
  useEffect,
} from 'react';

import { AdditionalLayout } from 'components';
import {
  currenciesGetThunk,
  getTrendingThunk,
} from 'store/exchange/exchange.thunk';
import { getNewsThunk } from 'store/news/news.thunk';
import { getWalletBalanceThunk } from '../../store/wallets/wallets.thunk';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  getRatesThunk,
  getVolumeThunk,
  getWalletsThunk,
} from '../../store/wallets/wallets.thunk';
import AffilatesBlock from './components/AffilatesBlock';
import AnnoucementsBlock from './components/AnnoucementsBlock';
import NotificationBlock from './components/NotificationBlock';
import OverviewBlock from './components/OverviewBlock';
import TradingTierBlock from './components/TradingTierBlock';
import TrendingBlock from './components/TrendingBlock';
import { getMeThunk } from '../../store/profile/profile.thunk';

const Overview: FC = (): JSX.Element | null => {

	const dispatch = useAppDispatch()
	
	useEffect(() => {
		dispatch(getMeThunk())
		dispatch(getWalletsThunk())
		dispatch(getRatesThunk())
		dispatch(getVolumeThunk())
		dispatch(getTrendingThunk())
		dispatch(currenciesGetThunk())
		dispatch(getNewsThunk())
		// dispatch(getBalanceThunk())
		dispatch(getWalletBalanceThunk())
	}, [])

	return (
		<AdditionalLayout>
			<div className="dashboard">
				<div>
					<NotificationBlock />
					<OverviewBlock />
					{/*<HistoryBlock />*/}
				</div>

				<div>
					<AffilatesBlock />
					<TrendingBlock />
					<TradingTierBlock  level={1} progress={100}/>
					<AnnoucementsBlock />
				</div>
			</div>
		</AdditionalLayout>
	)
}

export default Overview
// export default withAuth(Overview)
