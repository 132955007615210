import { Box, Icon, TextField, Typography } from '@mui/material'
import { CentrifugeContext } from 'providers/CentrifugeProvider'
import { useContext, useMemo, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { MarketPair } from '../../../../types/exchange.types'
import { Row } from './MarketPlacePairsRow'

export const MarketPlacePairs = () => {
	const [search, setSearch] = useState<string>('')
	const marketList = useTypedSelector(state => state.exchange.marketsList)
	const socketContext = useContext(CentrifugeContext)

	//UI
	const computedCurrencies = useMemo(() => {
		if (!marketList) return null
		if (!search) return marketList.order
		return marketList.order?.reduce((acc: MarketPair[], currency) => {
			if (currency.pair.toLowerCase().includes(search)) {
				acc.push(currency)
			}
			return acc
		}, [])
	}, [search, marketList])

	// useEffect(() => {
	//     if (socketContext.rate && marketList) {
	//         const tmpOrderList: MarketPair[] = []
	//         marketList.order.forEach(market => {

	//         })
	//     }
	// }, [socketContext.rate])

	return (
		<Box width={317} mx={20 / 8} my={30 / 8}>
			<Box>
				<TextField
					fullWidth
					variant={'standard'}
					onChange={e => setSearch(e.target.value.toLowerCase())}
					sx={{
						'.MuiInput-root': {
							paddingBottom: '5px',
						},
					}}
					InputProps={{
						startAdornment: (
							<Icon>
								<img src='/images/exchange/magnify.svg' alt='Magnidy' />
							</Icon>
						),
					}}
				></TextField>
			</Box>

			<Box mt={4}>
				<Box
					display={'flex'}
					alignItems={'center'}
					justifyContent={'space-between'}
					mb={1.5}
				>
					<Typography variant={'caption'} sx={{ color: '#52555E' }}>
						Asset
					</Typography>

					<Typography variant={'caption'} sx={{ color: '#52555E' }}>
						Last price
					</Typography>
				</Box>

				<Box style={{ height: 'calc(100vh - 240px)' }}>
					{computedCurrencies && (
						<AutoSizer>
							{({ width, height }: any) => (
								<List
									className='List'
									height={height}
									width={width}
									itemCount={computedCurrencies.length}
									itemData={computedCurrencies}
									itemSize={54}
								>
									{Row}
								</List>
							)}
						</AutoSizer>
					)}
				</Box>
			</Box>
		</Box>
	)
}
