import {createContext, FC, ReactNode, useState} from 'react'
import {EDrawerID} from "../types/common.types";

interface IProps {
    children: ReactNode
}

interface IDrawerManager {
    drawerId: EDrawerID | null
    setDrawerId: (modalId: EDrawerID | null) => void
}

export const DrawerManagerContext = createContext<IDrawerManager>({
    drawerId: null,
    setDrawerId: () => {
    }
})

export const DrawerManagerProvider: FC<IProps> = ({children}) => {

    const [drawerId, setDrawerId] = useState<EDrawerID | null>(null)

    return <DrawerManagerContext.Provider value={{
        drawerId,
        setDrawerId
    }}>
        {
            children
        }
    </DrawerManagerContext.Provider>
}
