import './sample-exchange.module.scss';

import React from 'react';

import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout';
import ExchangeContent from './components/ExchangeContent/ExchangeContent';
import SelectAndConvertForm from './components/SelectForm/SelectForm';
import {
  StyledContainer,
  StyledExchangeFlex,
} from './SampleExchange.styles';

const index = () => {
  return (
    <DefaultLayout mode='light'>
      <div style={{background: "#EBEBEB", minHeight: 'calc(100vh - 81px)'}}> 
      <StyledContainer>
            <StyledExchangeFlex>
                <ExchangeContent />
                <SelectAndConvertForm />
            </StyledExchangeFlex>
        </StyledContainer>
      </div>
        
    </DefaultLayout>
  );
}

export default index;
