import {instance} from "./axios"

export interface ITicket {
    subject: string,
    text: string,
    tagIds: string[]
}

export interface ITicketItem {
    ticket_id: string,
    subject: string,
    status: string,
    created_at: Date,
    updated_at: Date
}

export interface ITag {
    ID: string,
    name: string,
    checked?: boolean
}

export const ticketApi = {
    getAllTags(): Promise<ITag[]> {
        return instance.get('/api/v1/ticket_tags')
    },
    getAllTickets(): Promise<ITicketItem[]> {
        return instance.get('/api/v1/ticket')
    },
    createTicket(data: ITicket): Promise<any> {
        return instance.post('/api/v1/ticket', data)
    },
}
