import './History.style.scss'

import { useState } from 'react'

import { AdditionalLayout } from 'components'
import { useNavigate } from 'react-router-dom'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { InputAdornment, Menu, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'

import {
	StyledFieldWrapper,
	StyledSearchIcon,
	StyledTextField,
} from '../CommonComponents.styles'
import { CryptoTab } from './CryptoTab'
import { StyledTitle, StyledType } from './History.styles'

const Tabs = () => {
	const [selectedCurrency, setSelectedCurrency] = useState('')
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [search, setSearch] = useState<string>('')
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const navigate = useNavigate()
	const toHelpDesk = () => {
		navigate('/dashboard/helpdesk')
	}
	const handleSelect = (value: string) => {
		if (value) {
			setSelectedCurrency(value)
			setAnchorEl(null)
		} else {
			setSelectedCurrency('')
			setAnchorEl(null)
		}
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<AdditionalLayout>
			<div style={{ backgroundColor: '#fff', padding: 30, margin: 0 }}>
				<StyledTitle>Transaction history </StyledTitle>
				<div className='container'>
					<div style={{ paddingLeft: '17px' }}>
						<StyledType>Type</StyledType>
						<div style={{ display: 'flex' }}>
							<Button
								style={{
									background: '#fff',
									border: '1px solid #DBD9D9',
									borderRadius: 6,
									color: '#000000',
									padding: 0,
									height: 30,
									width: 127,
									fontFamily: 'Poppins',
									fontStyle: 'normal',
									fontWeight: 400,
									fontSize: '12px',
									lineHeight: '18px',
									textTransform: 'capitalize',
								}}
								disableElevation
								onClick={handleClick}
								endIcon={<KeyboardArrowDownIcon />}
							>
								{selectedCurrency ? selectedCurrency : 'Select a type'}
							</Button>
							<Menu
								variant='selectedMenu'
								id='demo-customized-menu'
								MenuListProps={{
									'aria-labelledby': 'demo-customized-button',
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
							>
								<MenuItem onClick={() => handleSelect('all')} disableRipple>
									All
								</MenuItem>
								<MenuItem onClick={() => handleSelect('deposit')} disableRipple>
									Deposit
								</MenuItem>
								<MenuItem
									onClick={() => handleSelect('withdraw')}
									disableRipple
								>
									Widthdraw
								</MenuItem>
							</Menu>
							<StyledFieldWrapper style={{ marginLeft: '66px' }}>
								<StyledTextField
									id='input-with-icon-textfield'
									onChange={e => setSearch(e.target.value)}
									startAdornment={
										<InputAdornment position='start'>
											<StyledSearchIcon />
										</InputAdornment>
									}
									placeholder='Search for coin'
								/>
							</StyledFieldWrapper>
						</div>
					</div>
					<Typography
						onClick={toHelpDesk}
						style={{
							fontFamily: 'Poppins',
							fontWeight: 400,
							fontSize: '12px',
							lineHeight: '18px',
							textDecorationLine: 'underline',
							color: '#9E77ED',
							cursor: 'pointer',
							margin: '0 0 38px 0',
						}}
					>
						Deposit has not arrived? Click here for helpdesk
					</Typography>

					<CryptoTab search={search} selectedCurrency={selectedCurrency} />
				</div>
			</div>
		</AdditionalLayout>
	)
}

export default Tabs
