import './Tab.styles.scss';

import React, {
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { BuyTab } from 'components/Forms/BuyTab';
import { SellTab } from 'components/Forms/SellTab';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getWalletsThunk } from 'store/wallets/wallets.thunk';

import {
  StyledExchangeForm,
  StyledExchangeFormBlock,
  StyledExchangeFormSubtitle,
  StyledExchangeFormTitle,
} from '../../SampleExchange.styles';

const SelectAndConvertForm = () => {
  const {walletBalance} = useTypedSelector(state => state.wallets)
  
  const dispatch = useAppDispatch()
	
	useEffect(() => {
		dispatch(getWalletsThunk())
	}, [])
    const [toggleState, setToggleState] = useState(1);
      const toggleTab = (index: SetStateAction<number>) => {
      setToggleState(index);
    };
  return (
    <>
      <StyledExchangeForm>
        <StyledExchangeFormBlock>
          <StyledExchangeFormTitle>Select and convert</StyledExchangeFormTitle>

          <StyledExchangeFormSubtitle>
            Select the currency, payment method and convert!
          </StyledExchangeFormSubtitle>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
              <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            Buy
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Sell
          </button>
        </div>
          </div>
 <div className="content-tabs">
          <div
            className={toggleState === 1 ? "contents  active-content" : "contents"}
          >
            <BuyTab/>
          </div>
  
          <div
            className={toggleState === 2 ? "contents  active-content" : "contents"}
          >
           <SellTab/>
          </div>
        </div>
        </StyledExchangeFormBlock>

      </StyledExchangeForm>
    </>
  );
};

export default SelectAndConvertForm;
