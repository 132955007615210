import {
	ChangeEvent,
	FC,
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'

import { Box, IconButton, Typography } from '@mui/material'

import { BFileUploadImg } from '../../atoms/BFileUploadImg/BFileUploadImg'

interface IProps {
	label: string
	onChange: (file: any) => void
	error?: string
	maxLength?: number
	disabled: boolean
	files: File[]
}

export const BFileUpload: FC<IProps> = ({
	label,
	onChange,
	error,
	disabled,
	files,
}) => {
	//States
	const [filesSrc, setFilesSrc] = useState<string[]>([])

	//refs
	const ref = useRef<HTMLInputElement>(null)

	//Handlers
	const handleClick = useCallback(() => {
		ref.current!.click()
	}, [ref.current])

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.files && e.target.files.length) {
				const reader = new FileReader()
				reader.readAsDataURL(e.target.files![0])

				reader.onloadend = () => {
					setFilesSrc(value => [...value, reader.result as string])
				}
				onChange([...files, ...Array.from(e.target.files as unknown as File[])])
			}
			e.target.value = ''
		},
		[filesSrc, files]
	)

	const handleClickClose = useCallback(
		(index: number) => {
			const newValue = files.filter((_, i) => i !== index)
			onChange(newValue)
			setFilesSrc(value => value.filter((_, i) => i !== index))
		},
		[files, filesSrc]
	)

	useEffect(() => {
		if (files.length === 0) setFilesSrc([])
	}, [files])

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
		>
			<Typography color={'#80848E'} variant={'caption'}>
				{label}
			</Typography>
			<input
				onChange={handleChange}
				style={{ display: 'none' }}
				ref={ref}
				type={'file'}
			/>
			{filesSrc.length ? (
				<Box display={'flex'} mt={2} justifyContent={'center'} gap={4}>
					{filesSrc.map((file, index) => (
						<Fragment key={index}>
							<BFileUploadImg
								index={index}
								src={file}
								onClose={handleClickClose}
							/>
						</Fragment>
					))}
				</Box>
			) : null}
			<IconButton
				onClick={handleClick}
				disabled={disabled}
				sx={{
					border: '1px solid #9E77ED',
					width: 62,
					height: 62,
					mt: '26px',
					filter: disabled ? 'grayscale(100%)' : '',
				}}
			>
				<img src={'/images/common/uploadIcon.svg'} />
			</IconButton>
			<Typography sx={{ mt: 2 }} color={'error'} variant={'caption'}>
				{error}
			</Typography>
		</Box>
	)
}
