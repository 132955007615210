import SearchIcon from '@mui/icons-material/Search'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { Button, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'

export const StyledGridItem = styled(Grid)(({ theme }) => ({
	background: '#FFFFFF',
	padding: 30,
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	borderRadius: 5,
}))

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
	padding: 20,
}))

export const StyledGridItemTitle = styled('div')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: 20,
	lineHeight: '30px',
	textAlign: 'center',
	color: '#000000',
	marginBottom: 46,
}))

export const StyledBalanceTitle = styled('div')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: 16,
	lineHeight: '24px',
	textAlign: 'center',
	color: '#80848E',
}))

export const StyledBalanceValue = styled('div')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: 35,
	lineHeight: '52px',
	textAlign: 'center',
	color: '#000000',
	marginBottom: 70,
}))

export const StyledBalanceValueCurrency = styled('span')(({ theme }) => ({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 300,
	fontSize: 35,
	lineHeight: '52px',
	textAlign: 'center',
	color: '#000000',
}))

export const StyledInput = styled(Input)(({ theme }) => ({
	color: '#000000',
	fontSize: '16px !important',
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	color: '#80848E',
	fontSize: '16px !important',
}))

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
	'& p': {
		color: '#000000',
		fontSize: '16px !important',
	},
}))

export const StyledPaymentMethodtitle = styled('div')(({ theme }) => ({
	marginTop: 74,
	color: '#80848E',
	marginBottom: 30,
	fontSize: 16,
	lineHeight: '24px',
	textAlign: 'center',
}))

export const StyledPaymentMethodFormControl = styled(FormControl)(
	({ theme }) => ({
		width: 420,
		margin: '0 auto',
		display: 'block',
		marginBottom: 80,
	})
)

export const StyledPaymentMethod = styled(Box)(({ theme }) => ({
	// width: 420,
	// margin: "0 auto",
	marginBottom: 20,
}))

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	'& span:nth-child(2)': {
		color: '#000000',
		fontSize: '16px !important',
	},
	'& span:nth-child(1)': {
		'& span': {
			color: '#9E77ED',
		},
	},
}))

export const WorkItemTitle = styled('div')(({ theme }) => ({
	color: '#000000',
	marginBottom: 6,
	fontWeight: 600,
	fontSize: 16,
	lineHeight: '24px',
}))

export const WorkItemText = styled('div')(({ theme }) => ({
	color: '#000000',
	marginBottom: 6,
	fontWeight: 400,
	fontSize: 16,
	lineHeight: '24px',
}))

export const WorkItem = styled('div')(({ theme }) => ({
	marginBottom: 20,
}))

export const StyledButton = styled(Button)(({ theme }) => ({
	width: 228,
}))

export const StyledWrapper = styled('div')(({ theme }) => ({
	background: '#FFFFFF',
	padding: 30,
	marginTop: 40,
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	borderRadius: '5px',
}))

export const StyledSelectWrapper = styled('div')(({ theme }) => ({
	width: 320,
	// margin: "0 auto",
	borderBottom: '1px solid #EEEEEE',
	paddingBottom: 12,
	marginBottom: 52,
}))

export const StyledSelectSubWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	cursor: 'pointer',
	'&.networkSelect .MuiSvgIcon-root': {
		display: 'none',
	},
}))

export const StyledSelectTitle = styled('div')(({ theme }) => ({
	marginBottom: 17,
	fontSize: 14,
	color: '#80848E',
}))

export const StyledSelectImage = styled('img')(({ theme }) => ({
	marginRight: 30,
}))

export const StyledSelectValue = styled('span')(({ theme }) => ({
	fontWeight: 600,
}))

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	color: '#000',
	'& .MuiPaper-root': {
		background: '#FFFFFF !important',
		color: '#000000',
		width: 341,
		height: 410,
	},
}))

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
	color: '#000000',
	textAlign: 'center',
	marginBottom: 35,
	fontSize: 15,
}))

export const StyledInputSearch = styled(Input)(({ theme }) => ({
	width: 237,
	margin: '0 auto',
	marginBottom: 31,
	'& input': {
		'&::placeholder': {
			color: '#80848E',
			opacity: 1,
		},
	},
}))

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
	color: '#80848E',
}))

export const StyledCurrencyWrapper = styled(Box)(({ theme }) => ({
	marginLeft: 54,
	marginBottom: 30,
	color: '#000000',
	fontWeight: 600,
	fontSize: 14,
	cursor: 'pointer',
}))

export const StyledNetworkWrapper = styled('div')(({ theme }) => ({
	marginLeft: 26,
	marginBottom: 30,
	color: '#000000',
	fontWeight: 600,
	fontSize: 14,
	cursor: 'pointer',
}))

export const StyledCurrencyImage = styled('img')(({ theme }) => ({
	marginRight: 20,
}))
export const StyledCurrencySmallImage = styled('img')(({ theme }) => ({
	marginRight: 20,
	width: '27.57px',
	height: '26.35px',
}))

export const StyledCurrencyValue = styled('span')(({ theme }) => ({
	color: '#80848E',
	fontWeight: 400,
}))

export const StyledNetworkText = styled('div')(({ theme }) => ({
	color: '#B8B8B8',
	fontWeight: 400,
	marginBottom: 30,
	textAlign: 'center',
}))

export const StyledMainWrapper = styled('div')(({ theme }) => ({
	width: 400,
	margin: '0 auto',
}))

export const StyledAddressTitle = styled('div')(({ theme }) => ({
	fontWeight: 600,
	marginTop: 15,
	marginBottom: 11,
}))

export const StyledAddress = styled('div')(({ theme }) => ({
	marginBottom: 38,
}))

export const StyledDialogTitleQr = styled(DialogTitle)(({ theme }) => ({
	fontWeight: 600,
	fontSize: 20,
	marginBottom: 11,
	color: '#000000',
	textAlign: 'center',
}))

export const StyledNetworkTextQr = styled('div')(({ theme }) => ({
	color: '#000000',
	fontWeight: 400,
	marginBottom: 37,
	textAlign: 'center',
}))

export const StyledWarningAmberRoundedIcon = styled(WarningAmberRoundedIcon)(
	({ theme }) => ({
		color: '#01C2FF',
		marginRight: 18,
	})
)

export const StyledWarningWrapper = styled(Box)(({ theme }) => ({
	marginTop: 42,
	fontWeight: 600,
}))

export const StyledWarningText = styled('div')(({ theme }) => ({
	fontWeight: 400,
	fontSize: 12,
	marginTop: 4,
}))
// WITHDRAW

export const StyledTextField = styled(Input)(({ theme }) => ({
	color: '#80848E !important',
	fontFamily: 'Poppins !important',
	fontSize: '14px !important',
	width: 320,
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		display: 'none',
	},
	'& input[type=number]': {
		MozAppearance: 'textfield',
	},
	'&.MuiInput-root:hover': {
		bgcolor: 'transparent',
	},
}))

export const StyledFieldWrapper = styled('div')(({ theme }) => ({
	marginBottom: 46,
}))

export const StyledWithdrawWrapper = styled('div')(({ theme }) => ({
	width: 320,
	margin: '0 auto',
}))

export const StyledInfoTitle = styled('div')(({ theme }) => ({
	fontWeight: 600,
	color: '#80848E',
	marginBottom: 5,
}))

export const StyledInfoWrapper = styled(Box)(({ theme }) => ({
	marginBottom: 39,
}))

export const StyledInfoValue = styled('div')(({ theme }) => ({
	fontWeight: 400,
	color: '#80848E',
}))

export const StyledInfoAmount = styled('div')(({ theme }) => ({
	fontWeight: 600,
	color: '#000000',
	marginBottom: 13,
	fontSize: 30,
}))

export const StyledInfoFee = styled('div')(({ theme }) => ({
	fontWeight: 400,
	color: '#80848E',
	fontSize: 10,
}))

export const StyledWithdrawButton = styled(Button)(({ theme }) => ({
	width: 95,
}))
