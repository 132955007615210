import React, { FC, useState } from "react";

import { NavLink, useLocation } from "react-router-dom";

// import Image from 'next/image'
// import Link from 'next/link'
// import { useRouter } from 'next/router'
import { MenuItem } from "@mui/material";

import { LinkDesc, LinkTitle, LinkTitleBeta, StyledMenu } from "./Header.styles";

interface IHeaderNavItem {
	title: string;
	icon: string;
	link: string;
	activeIcon: string;
	index: number;
}

const HeaderNavItem: FC<IHeaderNavItem> = (props): JSX.Element => {
	const { icon, activeIcon, title, link, index } = props;
	const location = useLocation();
	let isActive = location.pathname.includes(link);
	const isSimpleExchange = location.pathname;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	if (link === "/dashboard" && location.pathname.includes("make-a-request")) {
		isActive = true;
	}

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			{
				<NavLink
					to={link}
					className={isActive ? "headerNavItem headerNavItemActive" : "headerNavItem"}>
					{title}
				</NavLink>
			}
		</>
	);
};
export default HeaderNavItem;
