import countriesRaw from "utils/countries.json";

import { ICountry } from "./types/profile.types";

export const tradingViewGraphUrl =
  process.env.NODE_MODE === "production" ? "/charting_library/" : "/charting_library/";
//export const apiBaseUr = process.env.NODE_ENV === 'production' ? 'https://my.hotcode.kz/api/' : 'http://192.248.179.197:8030'
export const apiBaseUr = "https://my.hotcode.kz/api/"
//export const apiBaseUr = 'https://my.hotcode.kz/api/'
//export const apiBaseUr = 'http://192.248.179.197:8030'
export const ratesUrl = "http://192.248.190.250:8080";
//export const wsBaseUr = process.env.NODE_ENV === 'production' ? 'wss://ws.bittrade.com' : 'ws://192.248.190.250:8000'
export const wsBaseUr = "wss://my.hotcode.kz/centrifugo"
//export const wsBaseUr = 'ws://192.248.190.250:8000'

export const countries = countriesRaw as unknown as ICountry[];

export const defaultSelectedPairId = 38;

console.log("ENV", process.env);
