import {FC, SyntheticEvent, useEffect, useMemo, useState, useContext} from 'react'
import {Box, Button, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs} from "@mui/material";
import {ExchangeLayout} from "../../../../layouts/ExchangeLayout/ExchangeLayout";
import {useAppDispatch} from "../../../../hooks/useAppDispatch";
import {ordersGetThunk, currenciesGetThunk} from "../../../../store/exchange/exchange.thunk";
import {CreateOrderModal} from "../../../../components/Modals/CreateOrderModal";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import moment from "moment";
import {Cancel} from "@mui/icons-material";
import { Currency } from 'types/exchange.types';
import {
    StyledSnackbar,
    StyledWarningRoundedIcon,
    StyledWrapper,
    StyledButtonWrapper,
    StyledIconButton,
} from 'pages/Exchange/components/ExchangeOrders/ExchangeOrders.styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CentrifugeContext } from '../../../../providers/CentrifugeProvider';
import { IOrder } from '../../../../types/exchange.types';
import {
    orderCancelThunk,
    allOrderCancelThunk
} from '../../../../store/exchange/exchange.thunk';

interface IProps {
}

const OrdersPage: FC<IProps> = ({}) => {
    const socketContext = useContext(CentrifugeContext)

    //Redux
    const dispatch = useAppDispatch()
    const {
        orders,
        currencies
    } = useTypedSelector(state => state.exchange)

    //States
    const [value, setValue] = useState<string>('All')
    const [currentOrder, setCurrentOrder] = useState<any>(null);
    const [allOrders, setAllorders] = useState<any>(null);
    const [compOrders, setCompOrders] = useState<IOrder[]>([])

    //Handlers
    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleClose = () => setCurrentOrder(null)
    const handleCloseClearAllOrders = () => setAllorders(null)

    const handleCancelDeposit = () => {
        dispatch(orderCancelThunk(currentOrder?.ID || currentOrder?.id));
        handleClose()
    };

    const handleClearAllOrders = () => {
        const orderIds: number[] = []
        allOrders.forEach((order: any) => orderIds.push(order.id))
        dispatch(allOrderCancelThunk(orderIds))
        handleCloseClearAllOrders()
    }

    //Computed

    const pairs = useMemo(() => {
        const allPairs: Record<number, string> = {}

        if (currencies && currencies.length) {
            currencies?.forEach((currency: Currency) => {
                allPairs[currency.ID] = `${currency.BaseCurrency}/${currency.QuoteCurrency}`
            })
        }

        return allPairs
    }, [currencies])

    const ordersComputed = useMemo(() => {
        if(!compOrders) return []
        if(value === 'Open') return compOrders.filter(order => order.status === 'open')
        return compOrders
    }, [compOrders, value])


    //Effects

    useEffect(() => {
        dispatch(ordersGetThunk())
        dispatch(currenciesGetThunk())
    }, [])

    useEffect(() => {
      if (orders && orders.length) {
        setCompOrders(orders)
      }
    }, [orders])

    useEffect(() => {
      if (socketContext.order && compOrders.length) {
        const changedOrder = compOrders.filter(order => String(order.id) === socketContext.order?.id)[0]
        const changedOrders = compOrders.filter(order => String(order.id) !== socketContext.order?.id)

        if (changedOrder) {
          changedOrder.filledSize = socketContext.order.filledSize
          changedOrder.status = socketContext.order.status

          changedOrders.push(changedOrder)
          setCompOrders(changedOrders)
        }
      }
    }, [socketContext.order])

    return <ExchangeLayout>
        <CreateOrderModal/>
        <Box>
            <Tabs value={value} onChange={handleChange}>
                <Tab value={'All'} label={'All'}/>
                <Tab value={'Open'} label={'Open'}/>
            </Tabs>
        </Box>
        {/* <pre>{JSON.stringify(currentOrder, null, 2)}</pre> */}
        <Box>
            <Table>
                <TableHead>

                    {
                        headers.map(item => <TableCell key={item}>
                            {item}
                        </TableCell>)
                    }
                    <TableCell>
                        <Button variant={'contained'} color={'error'} onClick={() => setAllorders(orders ? orders.filter(order => order.status === 'open') : null)}>
                            Cancel
                        </Button>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {
                        ordersComputed?.map((order, index) => <TableRow
                            key={index}>
                            <TableCell style={{color: '#fff'}}>
                                {moment(new Date(order.createdAt)).format('DD-MM-YYYY HH:mm:ss')}
                            </TableCell>
                            <TableCell style={{color: '#80848E'}}>
                                <p>{pairs[order.pairID]}</p>
                            </TableCell>
                            <TableCell style={{
                              color: order.side === 'buy' ? '#419E6A' : '#f44336'
                            }}>
                                {order.side[0].toUpperCase()}{order.side.substring(1)}
                            </TableCell>
                            <TableCell style={{color: '#80848E'}}>
                                {order.price}
                            </TableCell>
                            <TableCell style={{color: '#80848E'}}>
                                {order.size}
                            </TableCell>
                            <TableCell style={{color: '#80848E'}}>
                                {order.size}
                            </TableCell>
                            <TableCell style={{color: '#80848E'}}>
                                {order.filledSize}
                            </TableCell>
                            <TableCell style={{
                              color: order.status === 'open' ? '#419E6A' : order.status === 'filled' ? '#EFB008' : '#f44336'
                            }}>
                                {order.status[0].toUpperCase()}{order.status.substring(1)}
                            </TableCell>
                            <TableCell>
                                {
                                    (order.status === 'cancelled' || order.status === 'filled' || order.status === 'partial_filled') ?
                                    <div style={{height: '42.28px'}}></div> :
                                    <Button onClick={() => setCurrentOrder(order)}>
                                        <Cancel/>
                                    </Button> 
                                }
                            </TableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </Box>
        <StyledSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={Boolean(currentOrder)}
          onClose={handleClose}
          message={
            <div>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <StyledIconButton onClick={handleClose}>
                  <CloseRoundedIcon />
                </StyledIconButton>
              </Box>
              <StyledWrapper
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <StyledWarningRoundedIcon />
                <div>
                  <div>Cancel {currentOrder && pairs[currentOrder.pairID]} order.</div>
                  <div>Sent you an invite to connect.</div>
                </div>
              </StyledWrapper>

              <StyledButtonWrapper
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Button onClick={handleCancelDeposit} variant="outlined">
                  Confirm
                </Button>
                <Button onClick={handleClose}>Decline</Button>
              </StyledButtonWrapper>
            </div>
          }
        />
        <StyledSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={Boolean(allOrders)}
          onClose={handleClose}
          message={
            <div>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <StyledIconButton onClick={handleCloseClearAllOrders}>
                  <CloseRoundedIcon />
                </StyledIconButton>
              </Box>
              <StyledWrapper
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <StyledWarningRoundedIcon />
                <div>
                  <div>Cancel all orders.</div>
                  <div>Sent you an invite to connect.</div>
                </div>
              </StyledWrapper>

              <StyledButtonWrapper
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Button onClick={handleClearAllOrders} variant="outlined">
                  Confirm
                </Button>
                <Button onClick={handleCloseClearAllOrders}>Decline</Button>
              </StyledButtonWrapper>
            </div>
          }
        />
    </ExchangeLayout>
}

const headers = [
    'Date',
    'Market',
    'Side',
    'Price',
    'Amount',
    'Value',
    'Filled',
    'Status'
]

export default OrdersPage
