import '../affiliatesStyles.scss'

// import img from 'next/image'
import { FC, useState } from 'react'

import { referalApi } from 'api'
// import { withAuth } from '@openware/opendax-web-sdk'
import { AdditionalLayout } from 'components'
import { useFormik } from 'formik'
import { HTextarea, StyledButton } from 'pages/Helpdesk/Helpdesk.style'
import * as yup from 'yup'

import {
	Alert,
	Box,
	Button,
	FormControl,
	Snackbar,
	Typography,
} from '@mui/material'

import { StyledForm, StyledInput, StyledInputLabel } from './MakeRequest.style'

const AffiliatesMakeARequest: FC = (): JSX.Element | null => {
	const [openSuccess, setOpenSuccess] = useState(false)
	const [openError, setOpenError] = useState(false)

	const initialValues = {
		full_name: '',
		telegram: '',
		emailAddress: '',
		social: '',
		message: '',
	}

	const validationSchema = yup.object({
		full_name: yup.string().required('This Field is required'),
		telegram: yup.string(),
		emailAddress: yup
			.string()
			.matches(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				'E-mail is not valid'
			),
		social: yup.string(),
		message: yup.string().required('This Field is required'),
	})

	const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues,
		validationSchema,
		onSubmit(data) {
			console.log(data)
		},
	})

	const requestHandler = async () => {
		referalApi
			.makeARequest({
				full_name: values.full_name,
				telegram: values.telegram,
				social: values.telegram,
				message: values.message,
			})
			.then(res => {
				setOpenSuccess(true)
			})
			.catch(err => {
				setOpenError(true)
			})

		// setSendSuccess(false)
		// const valid = !errors.fullName && errors.email !== undefined;
		// if (valid) return setSendSuccess(true)
	}

	return (
		<AdditionalLayout>
			<div className='affiliatesRoot'>
				<div className='whiteBg'>
					<div className='affiliates'>
						<div className='affiliates__content'>
							<div className='title'>Affiliate - request </div>
							<div className='affiliates__content_subtitle'>
								Do you have a question or request for our affiliate manager?
								Fill in the questionaire below and we will get back to you as
								soon as possible!
							</div>

							<Box
								display='flex'
								style={{ marginTop: '60px' }}
								justifyContent='center'
								flexDirection='column'
							>
								<StyledForm onSubmit={handleSubmit}>
									<FormControl
										style={{ marginBottom: '25px' }}
										variant='standard'
									>
										<StyledInputLabel htmlFor='full_name'>
											Full name*
										</StyledInputLabel>
										<StyledInput
											onChange={handleChange}
											id='full_name'
											value={values.full_name}
										/>
										{errors && (
											<Typography sx={{ color: 'red' }}>
												{errors.full_name}
											</Typography>
										)}
									</FormControl>
									<FormControl
										style={{ marginBottom: '25px' }}
										variant='standard'
									>
										<StyledInputLabel htmlFor='telegram'>
											Telegram
										</StyledInputLabel>
										<StyledInput
											value={values.telegram}
											onChange={handleChange}
											id='telegram'
										/>
										{errors && (
											<Typography sx={{ color: 'red' }}>
												{errors.telegram}
											</Typography>
										)}
									</FormControl>
									<FormControl
										style={{ marginBottom: '25px' }}
										variant='standard'
									>
										<StyledInputLabel htmlFor='emailAddress'>
											Email address*
										</StyledInputLabel>
										<StyledInput
											value={values.emailAddress}
											onChange={handleChange}
											id='emailAddress'
										/>
										{errors && (
											<Typography sx={{ color: 'red' }}>
												{errors.emailAddress}
											</Typography>
										)}
									</FormControl>

									<FormControl
										style={{ marginBottom: '25px' }}
										variant='standard'
									>
										<StyledInputLabel htmlFor='social'>
											Social media link (Youtube, Twitter, Instagram, etc.)
										</StyledInputLabel>
										<StyledInput
											value={values.social}
											onChange={handleChange}
											id='social'
										/>
										{errors && (
											<Typography sx={{ color: 'red' }}>
												{errors.social}
											</Typography>
										)}
									</FormControl>
									<HTextarea
										value={values.message}
										onChange={handleChange}
										name='message'
										placeholder='Your question or request'
									/>
									{errors.message && (
										<p style={{ color: 'red' }}>
											Fill the question blank please
										</p>
									)}
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginTop: '50px',
										}}
									>
										<StyledButton
											size='small'
											variant='outlined'
											onClick={() => requestHandler()}
											disabled={
												!(
													Boolean(values.full_name) &&
													Boolean(values.emailAddress) &&
													Boolean(values.message)
												) ||
												Boolean(errors.full_name) ||
												Boolean(errors.emailAddress) ||
												Boolean(errors.message)
											}
										>
											Send message
										</StyledButton>
										{/* {
                  sendSuccess &&
                  <HSuccessMessage>
                      <CheckIcon />
                      <span>Message sent succesfully</span>
                  </HSuccessMessage>
              } */}
									</div>
								</StyledForm>
							</Box>
						</div>
					</div>
				</div>

				<div className='needToKnow whiteBg'>
					<h3 className='title title__center'>Need to know</h3>
					<div className='needToKnow__block'>
						<p className='needToKnow__block_subtitle'>Affiliate code</p>
						<p className='needToKnow__block_text'>
							The code you can share with your friends which they can enter when
							signing up
						</p>
					</div>

					<div className='needToKnow__block'>
						<p className='needToKnow__block_subtitle'>Affiliate link</p>
						<p className='needToKnow__block_text'>
							The link you can share with your friends which they can use to
							sign up to Bittrade
						</p>
					</div>

					<div className='needToKnow__block'>
						<p className='needToKnow__block_subtitle'>Commission</p>
						<p className='needToKnow__block_text'>
							The amount of commission you have earned in euro’s, this will be
							deposited in your wallet every 24H.
						</p>
					</div>

					<div className='needToKnow__block'>
						<p className='needToKnow__block_subtitle'>Reffered users</p>
						<p className='needToKnow__block_text'>
							The amount of new users brought to the Bittrade platform through
							your affiliate code/link
						</p>
					</div>

					<div className='needToKnow__block'>
						<p className='needToKnow__block_subtitle'>
							Earn up to 50% commission?
						</p>
						<p className='needToKnow__block_text'>
							(Super) affiliates are eligible for up to 50% commission and other
							rewards.
						</p>
					</div>

					<Button
						sx={{ p: '0 !important' }}
						variant={'text'}
						className='needToKnow__moreBtn'
					>
						<span>Make a request</span>
						<img
							src='/images/readMoreVectorIcon.svg'
							alt='request icon'
							width={13}
							height={11}
						/>
					</Button>

					<div className='needToKnow__block'>
						<p className='needToKnow__block_subtitle'>
							Affiliate terms & conditions
						</p>
						<Button
							sx={{ p: '0 !important' }}
							variant={'text'}
							className='needToKnow__moreBtn'
						>
							<span>Reed more</span>
							<img
								src='/images/readMoreVectorIcon.svg'
								alt='read more vector icon'
								width={13}
								height={11}
							/>
						</Button>
					</div>
				</div>
			</div>
			<Snackbar
				open={openSuccess}
				autoHideDuration={6000}
				onClose={setOpenSuccess.bind(null, false)}
			>
				<Alert
					onClose={setOpenSuccess.bind(null, false)}
					severity='success'
					sx={{ width: '100%' }}
				>
					Message sent succesfully
				</Alert>
			</Snackbar>

			<Snackbar
				open={openError}
				autoHideDuration={6000}
				onClose={setOpenError.bind(null, false)}
			>
				<Alert
					onClose={setOpenError.bind(null, false)}
					severity='error'
					sx={{ width: '100%' }}
				>
					{'An error has occurred'}
				</Alert>
			</Snackbar>
		</AdditionalLayout>
	)
}

export default AffiliatesMakeARequest
