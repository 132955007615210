import {
  CSSProperties,
  FC,
} from 'react';

interface IProps {
    coinName: string
    style?: CSSProperties
}

const iconsPng: string[] = ['APE','AVAS', 'AXS', 'GALA', 'KSM', 'SAND']

export const CoinIcon: FC<IProps> = ({coinName, style}) => {
    if (iconsPng.includes(coinName)) {
        return <img src={`/images/coins/${coinName}.png`}
                    style={{marginRight: "10px", width: 35, height: 35, ...style}}
                    alt={coinName}/>
    }
    return <img src={`/images/coins/${coinName.trim()}.svg`}
                style={{marginRight: "10px", width: 35, height: 35, ...style}}
                alt={coinName}/>
}

