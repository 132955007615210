import { useEffect, useState } from 'react'

import { walletsApi } from 'api'
import moment from 'moment'

import { Alert, Snackbar } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Copy from './Copy'
import { StyledTableCell, StyledTableRow } from './History.styles'

export const CryptoTab = (props: {
	selectedCurrency: string
	search: string
}) => {
	const [data, setData] = useState([])
	const [openCopied, setOpenCopied] = useState(false)
	const cutText = (str: string, cutStart: number, cutEnd: number) => {
		return str.substr(0, cutStart) + '...' + str.substr(cutEnd + 1)
	}
	const computedItem = (() => {
		if (!data) return []
		if (!props.search) return data
		return data.filter((item: any) =>
			item.currency?.toLowerCase().includes(props.search.toLowerCase())
		)
	})()
	const handlerTransactions = async () => {
		walletsApi.getTransactions().then(res => setData(res.data))
	}
	useEffect(() => {
		handlerTransactions()
	}, [])
	const [copiedDestination, setCopiedDestination] = useState<string>('')
	const [copiedTxID, setCopiedTxID] = useState<string>('')
	return (
		<>
			<TableContainer style={{ boxShadow: 'none' }} component={Paper}>
				<Table size='medium' aria-label='a dense table'>
					<TableHead>
						<TableRow
							style={{
								background: '#EFEDED',
								height: '36px',
								fontFamily: 'Poppins',
								fontStyle: 'normal',
								color: '#80848E',
								fontWeight: 400,
								fontSize: '12px',
								lineHeight: '18px',
							}}
						>
							<TableCell style={{ paddingLeft: '17px' }} width={'20%'}>
								Time
							</TableCell>
							<TableCell align='left'>Type</TableCell>
							<TableCell align='left'>Wallet source</TableCell>
							<TableCell align='left'>Asset</TableCell>
							<TableCell align='left'>Amount</TableCell>
							<TableCell align='left'>Destination</TableCell>
							<TableCell align='left'>TxID</TableCell>
							<TableCell align='left'>Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{computedItem
							.filter((s: any) =>
								s.type.includes(
									props.selectedCurrency === 'all' ? '' : props.selectedCurrency
								)
							)
							.map((row: any) => {
								return (
									<StyledTableRow
										style={{ borderBottom: '1px sold red' }}
										key={row.created_at}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<StyledTableCell
											style={{
												paddingLeft: '15px',
												fontFamily: 'Poppins',
												fontWeight: 400,
											}}
											component='th'
											scope='row'
										>
											{' '}
											{moment(row.created_at).format('DD-MM-YYYY h:mm')}
										</StyledTableCell>
										<StyledTableCell align='left'>{row.type}</StyledTableCell>
										<StyledTableCell align='left'>Spot wallet</StyledTableCell>
										<StyledTableCell align='left'>
											{row.currency}
										</StyledTableCell>
										<StyledTableCell align='left'>{row.amount}</StyledTableCell>
										<StyledTableCell align='left'>
											{cutText(row.to_address, 5, 35)}
											<Copy
												onClick={() => {
													setCopiedDestination(copiedDestination)
													setOpenCopied(true)
												}}
												text={row.to_address ? row.to_address : ''}
												title={''}
											/>
										</StyledTableCell>

										<StyledTableCell align='left'>
											{cutText(row.tx_hash, 5, 60)}
											<Copy
												title={''}
												text={row.tx_hash ? row.tx_hash : ' '}
												onClick={() => {
													setCopiedTxID(copiedTxID)
													setOpenCopied(true)
												}}
											/>
										</StyledTableCell>
										<StyledTableCell
											style={{ position: 'relative' }}
											align='left'
										>
											<span
												style={{
													position: 'absolute',
													marginTop: '5px',
													left: '-18px',
													borderRadius: '50%',
													width: '8px',
													height: '8px',
													backgroundColor:
														row.status === 'finished'
															? '#419E6A'
															: row.status === 'new'
															? '#EFB008'
															: '#9E77ED',
												}}
											></span>
											{row.status}
										</StyledTableCell>
									</StyledTableRow>
								)
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<Snackbar
				open={openCopied}
				autoHideDuration={6000}
				onClose={setOpenCopied.bind(null, false)}
			>
				<Alert
					onClose={setOpenCopied.bind(null, false)}
					severity='success'
					sx={{ width: '100%' }}
				>
					Copied!
				</Alert>
			</Snackbar>
		</>
	)
}
