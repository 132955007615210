import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    color: "#000000",
    fontSize: "16px !important",
    "& .MuiFilledInput-root": {
        background: "none"
      },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "&.MuiInput-root:hover": {
      bgcolor: "transparent"
    },
    '&:hover': {
        borderColor: 'grey',
        outline:'none',
        background: 'none',
      },
  }));

  export const StyledBTCTextField = styled(TextField)(({ theme }) => ({
    color: "#000000",
    fontSize: "16px !important",
    "& .MuiFilledInput-root": {
        background: "none"
      },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "&.MuiInput-root:hover": {
      bgcolor: "transparent"
    },
    '&:hover': {
        borderColor: 'grey',
        outline:'none',
        background: 'none',
      },
  }));
