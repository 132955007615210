import {instance} from "./axios"

export interface IPaymentReq {
    amount: number,
    currency: string
}

export const bankApi = {
    createPayment(data: IPaymentReq): Promise<any> {
        return instance.post('/api/v1/bank/payment', data)
    },
}
