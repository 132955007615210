import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import { AdditionalLayout } from 'components'
import { NavLink } from 'react-router-dom'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import EastIcon from '@mui/icons-material/East'
import { Alert, Button, DialogContent, Grid, Snackbar } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'

import { walletsApi } from '../../../../api'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import {
	getWalletsThunk,
	postTransferThunk,
} from '../../../../store/wallets/wallets.thunk'
import { ICurrency } from '../../../../types/exchange.types'
import {
	StyledCurrencySmallImage,
	StyledCurrencyValue,
	StyledCurrencyWrapper,
	StyledDialog,
	StyledDialogTitle,
	StyledDialogTitleQr,
	StyledFieldWrapper,
	StyledGridContainer,
	StyledGridItem,
	StyledGridItemTitle,
	StyledInfoAmount,
	StyledInfoFee,
	StyledInfoTitle,
	StyledInfoValue,
	StyledInfoWrapper,
	StyledInputSearch,
	StyledNetworkText,
	StyledNetworkTextQr,
	StyledNetworkWrapper,
	StyledSearchIcon,
	StyledSelectSubWrapper,
	StyledSelectTitle,
	StyledSelectValue,
	StyledSelectWrapper,
	StyledTextField,
	StyledWarningAmberRoundedIcon,
	StyledWarningWrapper,
	StyledWithdrawButton,
	StyledWithdrawWrapper,
	StyledWrapper,
	WorkItem,
	WorkItemText,
	WorkItemTitle,
} from '../CommonComponents.styles'
import { networks } from '../constants'

const Deposit: FC = (): JSX.Element | null => {
	const amountRef: React.RefObject<HTMLInputElement> = useRef(null)
	const selectedWithdrawWallet = useTypedSelector(
		state => state.wallets.selectedWithdrawWallet
	)
	const { wallets, isLoading } = useTypedSelector(state => state.wallets)
	const [currency, setCurrency] = useState<ICurrency | null>(
		wallets.data ? wallets.data[0] : null
	)
	const [network, setNetwork] = useState(networks[0])
	const [withdrawAmount, setWithdrawAmount] = useState<number>(0)
	const [addressOfReceiver, setAddressOfReceiver] = useState<string>('')
	const [search, setSearch] = useState<string>('')
	const [openCurrency, setOpenCurrency] = useState<boolean>(false)
	const [openNetwork, setOpenNetwork] = useState<boolean>(false)
	const [openSuccess, setOpenSuccess] = useState<boolean>(false)
	const [fee, setFee] = useState<any>(0)
	const [initialWallet, setInitialWallet] = useState<boolean>(true)
	const handleSelectCurrency = (currency: any) => {
		walletsApi
			.widthdrawFee({
				amount: +withdrawAmount,
				to_address: addressOfReceiver,
				currency: currency?.currency as string,
			})
			.then(res => {
				return setFee(res)
			})
		setCurrency(currency)
		setOpenCurrency(false)
		setNetwork(
			networks.find(network => network.value === currency.network) || network
		)
	}

	const handleSelectNetwork = (network: any) => {
		setNetwork(network)
		setOpenNetwork(false)
	}

	const handleChangeWithdrawAmount = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setWithdrawAmount(+e.target.value)
		},
		[]
	)

	const handleChangeAddressOfReceiver = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setAddressOfReceiver(e.target.value)
		},
		[]
	)

	const computedMarkets = (() => {
		if (!wallets.data) return []
		if (!search) return wallets.data
		return wallets?.data.filter(
			market =>
				market.currency?.toLowerCase().includes(search.toLowerCase()) ||
				market.currency_full?.toLowerCase().includes(search.toLowerCase())
		)
	})()

	const resWithoutEur = computedMarkets.filter(item => item.currency !== 'EUR')
	const dispatch = useAppDispatch()

	const handleSubmit = useCallback(() => {
		dispatch(
			postTransferThunk({
				amount: +withdrawAmount,
				to_address: addressOfReceiver,
				currency: currency?.currency as string,
			})
		)

		setCurrency(null)
		setWithdrawAmount(0)
		setAddressOfReceiver('')

		// setOpenSuccess(true)
	}, [dispatch, withdrawAmount, addressOfReceiver, currency?.currency])

	const [openQr, setOpenQr] = useState<boolean>(false)

	const modalCloseHandler = () => {
		setSearch('')
		setOpenCurrency(false)
	}

	useEffect(() => {
		dispatch(getWalletsThunk())
	}, [])

	const computedTotal = useMemo(() => {
		if (!withdrawAmount || !fee.fee) return ''
		return +withdrawAmount + +fee.fee
	}, [fee, withdrawAmount])

	useEffect(() => {
		if (computedMarkets.length) {
			if (initialWallet) {
				if (selectedWithdrawWallet !== null) {
					console.log('INIT', selectedWithdrawWallet, computedMarkets)
					const wallet = computedMarkets.find(
						market => market.currency === selectedWithdrawWallet
					)

					if (wallet) {
						handleSelectCurrency(wallet)
					}

					setInitialWallet(false)
				}
			}
		}
	}, [computedMarkets])

	const isIncorrectAddress = useMemo(
		() => () => {
			if (
				addressOfReceiver.match(/^0x[a-fA-F0-9]{40}$/g) ||
				addressOfReceiver.match(/^(bc1|[13])[a-km-zA-HJ-NP-Z1-9]{25,34}$/g) ||
				addressOfReceiver.match(/X[1-9A-HJ-NP-Za-km-z]{33}$/g) ||
				addressOfReceiver.match(/4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}$/g)
			) {
				return false
			} else {
				return true
			}
		},
		[addressOfReceiver]
	)

	return (
		<AdditionalLayout>
			<StyledGridContainer
				container
				style={{
					maxHeight: 'calc(100vh - 0)',
					overflow: ' auto',
					background: '#F5F5F5',
					display: 'flex',
					padding: '20px 32px 56px 0px',
					gap: '20px',
				}}
				justifyContent='space-between'
			>
				<StyledGridItem item xs={7.5}>
					<StyledWithdrawWrapper>
						<StyledGridItemTitle>Withdraw Crypto</StyledGridItemTitle>

						<StyledSelectWrapper>
							<StyledSelectTitle>Select coin</StyledSelectTitle>
							<StyledSelectSubWrapper
								display='flex'
								justifyContent='space-between'
								alignItems='center'
								onClick={setOpenCurrency.bind(null, true)}
							>
								<Box display='flex' alignItems='center'>
									{currency?.currency ? (
										<StyledCurrencySmallImage
											src={`/images/coins/${currency?.currency}.svg`}
										/>
									) : (
										false
									)}
									<div>{currency?.currency}</div>
								</Box>

								<ArrowDropDownIcon />
							</StyledSelectSubWrapper>
						</StyledSelectWrapper>

						<StyledFieldWrapper>
							<StyledSelectTitle>Address of receiver</StyledSelectTitle>
							<StyledTextField
								inputProps={{
									type: 'text',
									pattern: '[A-Za-z0-9]+',
								}}
								value={addressOfReceiver}
								onChange={handleChangeAddressOfReceiver}
								sx={{
									'&::before': {
										borderBottom: '1px solid #EEEEEE !important',
									},
								}}
								placeholder='Enter address'
							/>
							{isIncorrectAddress() && addressOfReceiver.length > 0 ? (
								<div
									style={{
										paddingTop: '10px',
										color: '#f44336',
									}}
								>
									Incorrect address of receiver
								</div>
							) : (
								false
							)}
						</StyledFieldWrapper>
						<StyledSelectWrapper>
							<StyledSelectTitle>Selected network</StyledSelectTitle>
							<StyledSelectSubWrapper
								display='flex'
								justifyContent='space-between'
								alignItems='center'
								// onClick={setOpenNetwork.bind(null, true)}
							>
								<Box display='flex' alignItems='center'>
									<div>
										<StyledSelectValue>{network.value}</StyledSelectValue>{' '}
										{network.label}
									</div>
								</Box>

								{/* <ArrowDropDownIcon /> */}
							</StyledSelectSubWrapper>
						</StyledSelectWrapper>

						<StyledFieldWrapper>
							<StyledSelectTitle>Withdraw amount</StyledSelectTitle>
							<StyledTextField
								type='number'
								onKeyDown={evt =>
									['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
								}
								value={withdrawAmount === 0 ? '' : String(withdrawAmount)}
								onChange={handleChangeWithdrawAmount}
								sx={{
									'&::before': {
										borderBottom: '1px solid #EEEEEE !important',
									},
								}}
								placeholder='Minimum 0.0001'
							/>
						</StyledFieldWrapper>

						<StyledInfoWrapper display='flex' justifyContent='space-between'>
							<div>
								<StyledInfoTitle>{currency?.currency} balance</StyledInfoTitle>
								<StyledInfoValue>
									{currency?.balance} {currency?.currency}
								</StyledInfoValue>
							</div>
							<div>
								<StyledInfoTitle>Network fee</StyledInfoTitle>
								<StyledInfoValue>
									{fee.fee} {currency?.currency}
								</StyledInfoValue>
							</div>
						</StyledInfoWrapper>

						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
						>
							<div>
								<StyledInfoAmount>
									{computedTotal} {currency?.currency}
								</StyledInfoAmount>
								<StyledInfoFee>
									{fee.fee} {currency?.currency} network fee included
								</StyledInfoFee>
							</div>
							<StyledWithdrawButton
								disabled={
									isLoading ||
									isIncorrectAddress() ||
									!currency ||
									!withdrawAmount
								}
								onClick={handleSubmit}
								variant='outlined'
							>
								Withdraw
							</StyledWithdrawButton>
						</Box>
					</StyledWithdrawWrapper>
				</StyledGridItem>

				<Grid item xs={4}>
					<Box display='flex' justifyContent='center'>
						<NavLink to='/dashboard/deposit'>
							<Button
								style={{
									border: '1.5px solid #9E77ED',
									fontWeight: '400',
									fontSize: '16px',
									textTransform: 'capitalize',
									width: 228,
									height: 49,
									padding: 0,
									color: '#9E77ED',
								}}
								variant='outlined'
								endIcon={<EastIcon />}
							>
								Deposit Fiat
							</Button>
						</NavLink>
					</Box>

					<StyledWrapper>
						<StyledGridItemTitle>How it works</StyledGridItemTitle>

						<WorkItem>
							<img
								src='/images/works/works1.svg'
								alt='Select the crypto you want to withdraw'
							/>
							<WorkItemTitle>
								Select the crypto you want to withdraw
							</WorkItemTitle>
							<WorkItemText>
								Choose out of the list of coins which you wish to withdraw.
							</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img
								src='/images/works/works2.svg'
								alt='Select the network you want to use'
							/>
							<WorkItemTitle>Select the network you want to use</WorkItemTitle>
							<WorkItemText>
								Select which network you would like to use for the withdrawl.
							</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img
								src='/images/works/works3.svg'
								alt='Input the amount and press withdraw'
							/>
							<WorkItemTitle>Input the amount and press withdraw</WorkItemTitle>
							<WorkItemText>
								Enter how much you would like to withdraw and press the withdraw
								button.
							</WorkItemText>
						</WorkItem>
					</StyledWrapper>
				</Grid>

				<StyledDialog open={openCurrency} onClose={modalCloseHandler}>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<StyledDialogTitle>Select crypto to deposit</StyledDialogTitle>
						<CloseIcon
							onClick={modalCloseHandler}
							style={{
								position: 'absolute',
								right: 12,
								cursor: 'pointer',
								top: 12,
							}}
						/>
					</div>

					<FormControl variant='standard'>
						<StyledInputSearch
							onChange={e => setSearch(e.target.value)}
							style={{ color: '#000', fontSize: '14px', lineHeight: '21px' }}
							id='input-with-icon-adornment'
							placeholder='Search crypto name'
							startAdornment={
								<InputAdornment position='start'>
									<StyledSearchIcon />
								</InputAdornment>
							}
						/>
					</FormControl>

					{resWithoutEur &&
						resWithoutEur.map(item => (
							<StyledCurrencyWrapper
								key={item.currency}
								onClick={handleSelectCurrency.bind(null, item)}
								display='flex'
								alignItems='center'
							>
								<StyledCurrencySmallImage
									src={`/images/coins/${item.currency}.svg`}
								/>
								<span>
									{item.currency_full}{' '}
									<span style={{ color: '#80848E', fontWeight: 500 }}>
										{item.currency}
									</span>
								</span>
							</StyledCurrencyWrapper>
						))}
				</StyledDialog>

				<StyledDialog
					open={openNetwork}
					onClose={setOpenNetwork.bind(null, false)}
				>
					<StyledDialogTitle>Select network</StyledDialogTitle>

					<StyledNetworkText>
						Make sure to select the right network to prevent assets from
						becoming lost!
					</StyledNetworkText>

					{networks.map(item => (
						<StyledNetworkWrapper
							key={item.value}
							onClick={handleSelectNetwork.bind(null, item)}
						>
							<div>{item.value}</div>
							<StyledCurrencyValue>{item.label}</StyledCurrencyValue>
						</StyledNetworkWrapper>
					))}
				</StyledDialog>

				<StyledDialog open={openQr} onClose={setOpenQr.bind(null, false)}>
					<StyledDialogTitleQr>QR Code</StyledDialogTitleQr>
					<DialogContent>
						<StyledNetworkTextQr>
							Scan the QR code for the address
						</StyledNetworkTextQr>
						<Box display='flex' justifyContent='center'>
							<img src='/images/qr.svg' alt='qr' />
						</Box>

						<StyledWarningWrapper
							display='flex'
							justifyContent='center'
							alignItems='center'
						>
							<StyledWarningAmberRoundedIcon />
							<span>Only send {currency?.currency} to this address!</span>
						</StyledWarningWrapper>
					</DialogContent>
				</StyledDialog>
			</StyledGridContainer>
			<Snackbar
				open={openSuccess}
				autoHideDuration={6000}
				onClose={setOpenSuccess.bind(null, false)}
			>
				<Alert
					onClose={setOpenSuccess.bind(null, false)}
					severity='success'
					sx={{ width: '100%' }}
				>
					withdrawl was successful
				</Alert>
			</Snackbar>
		</AdditionalLayout>
	)
}

export default Deposit
