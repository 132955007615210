import { CustomHeader } from 'components'

import { PropsWithChildren } from 'react'
import { additionalMenu } from 'utils/rounting'
import './additionalLayoutStyles.scss'
import MenuItem from './components/MenuItem'

export const AdditionalLayout = (
	props: PropsWithChildren<{ className?: string; hideMenu?: boolean }>
): JSX.Element => {
	const { hideMenu } = props

	return (
		<div className={'additionalLayout'}>
			<CustomHeader />
			<div className={!hideMenu ? 'additionalLayout__wrapper' : ''}>
				{!hideMenu ? (
					<div className='additionalLayout__menu'>
						<div className='additionalLayout__menu_nav'>
							{additionalMenu.map(item => {
								return (
									<MenuItem
										key={item.link}
										isActive={
											document.location.href.includes(item.link) ? true : false
										}
										{...item}
									/>
								)
							})}
						</div>

						<div className='additionalLayout__menu_contacts'>
							<p className='title'>Have a question?</p>
							<a className='subtitle' href='mailto:support@bittrade.com'>
								support@bittrade.com
							</a>
						</div>
					</div>
				) : (
					<div></div>
				)}
				<div className='additionalLayout__content'>{props.children}</div>
			</div>
		</div>
	)
}
