import {
  Currency,
  IMarket,
  IMarketsList,
  IOrder,
  IOrderCreateReq,
  ITicker,
  ITrending,
} from '../types/exchange.types';
import { instance } from './axios';

export const exchangeApi = {
    getOrders(): Promise<IOrder[]> {
        return instance.get('/api/v1/orders')
    },
    orderCreate(data: IOrderCreateReq) {
        return instance.post('/api/v1/orders', data)
    },
    orderCancel(id: number | string | undefined) {
        return instance.post(`/api/v1/orders/${id}/cancel`)
    },
    getCurrencies(): Promise<Currency[]>  {
        return instance.get("/api/v1/pairs")
    },
    getTradesPairHistory(id: number): Promise<Currency[]>  {
        return instance.get(`api/v1/pairs/${id}/trades`)
    },
    getBookPairHistory(id: number): Promise<Currency[]>  {
        return instance.get(`api/v1/pairs/${id}/book`)
    },
    getTickersPairHistory(id: number): Promise<ITicker[]>  {
        return instance.get(`api/v1/pairs/${id}/tickers`)
    },
    getPairs(params: any) {
        return instance.get(`/api/v1/pairs`);
    },
    getCandles(params: any) {
        return instance.get(`/api/v1/pairs/1/candles`, {
          params: {
            // access_key: config.key,
            // output: "JSON",
            ...params,
          },
        });
    },
    getMarket(): Promise<IMarket[]> {
        return instance.get('/api/v1/market/page')
    },
    getTrending(): Promise<ITrending[]> {
        return instance.get('/api/v1/market/trending')
    },
    getMarketList(): Promise<IMarketsList> {
        return instance.get('/api/v1/market/list')
    },
    simpleExchange(data: any) {
        return instance.post('/api/v1/exchange', data)
    },
    simpleExchangeQuoteCoins(currency: any) {
        return instance.get(`/api/v1/exchange/${currency}/coins`)
    },
    simpleExchangeQuote(params: {baseCurrency: string, selectedQuoteCurrency: string, operation: string}) {
        return instance.get(`/api/v1/exchange/quote`, {
            params: {
                base: params.baseCurrency,
                quote: params.selectedQuoteCurrency,
                side: params.operation
            }
        })
    }
   
}
