import {
  styled,
  Box,
  IconButton,
  TableRow,
  TableCell,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface IStyledOrderBookItem {
  variant: 'sell' | 'buy' | 'regular'
}

export const StyledBookItemBackground = styled('div')<{ width: number, color: string }>(({ width, color }) => ({
  marginBottom: 9,
  position: "absolute",
  top: 0,
  left: 0,
  background: color,
  height: '23px',
  width: `${width}%`,
}))

export const StyledTableRow = styled(TableRow)(() => ({
  marginBottom: 9,
  position: "relative",
  borderBottom: "5px solid transparent",
}));

export const StyledTableCellLeft = styled(TableCell)(() => ({
  paddingLeft: 10
}));

export const StyledTableCellRight = styled(TableCell)(() => ({
  paddingRight: 10
}));

export const StyledLoadingBook = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '600px',
  width: '100%'
}))

export const StyledOrderBookHeader = styled('div')(() => `
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 15px;

  span {
    font-family: Poppins;
    width: 33.3%;
    font-size: 12px;
    color: rgba(82, 85, 94, 1);
    letter-spacing: 1px;
  }
`)

export const StyledOrderBookWrapper = styled('div')(() => `
  display: flex;
  flex-direction: column;
  height: 275px;
  overflow-y: scroll;
`)

export const StyledOrderBookRow = styled('div')(() => `
  position: relative;
  display: flex;
`)

export const StyledOrderBookPrice = styled('div')(() => `
  font-family: Poppins;
  display: flex;
  align-items: center;
  width: 33.3%;
  height: 27px;
  font-size: 12px;
`)

export const StyledOrderBookAmount = styled('div')(() => `
  font-family: Poppins;
  display: flex;
  align-items: center;
  width: 33.3%;
  height: 27px;
  font-size: 12px;
  color: rgba(128, 132, 142, 1);
`)

export const StyledOrderBookTotal = styled('div')(() => `
  font-family: Poppins;
  display: flex;
  align-items: center;
  width: 33.3%;
  height: 27px;
  font-size: 12px;
  color: rgba(128, 132, 142, 1);
`)

export const StyledOrderBookProgress = styled('div')(() => `
  position: absolute;
  height: 27px;
  top: 0;
  left: 0;
`)

export const StyledOrderBookMarketPrice = styled('div')(() => `
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: rgba(65, 158, 106, 1);
  height: 50px;
`)