import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Box, IconButton, styled } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'

export const StyledSnackbar = styled(Snackbar)(() => ({
	background: '#011C56',
	width: 411,
	'& div': {
		background: '#011C56',
		color: '#FFFFFF',
		borderRadius: 0,
		width: 411,
	},
}))

export const StyledWarningRoundedIcon = styled(WarningRoundedIcon)(() => ({
	color: '#9E77ED',
	width: 40,
	height: 35,
	marginRight: 28,
}))

export const StyledWrapper = styled(Box)(() => ({
	fontSize: 14,
	lineHeight: '20px',
}))

export const StyledWrapperIcon = styled(Box)(() => ({
	width: '100%',
}))

export const StyledIconButton = styled(IconButton)(() => ({
	width: 38,
	marginRight: 20,
}))

export const StyledButtonWrapper = styled(Box)(() => ({
	padding: '0 50px',
	marginTop: 50,
}))
