import { storageService } from '../services/storage.service';
import {
  ICountry,
  IForgotPasswordEmailReq,
  ILogin,
  ILoginRes,
  IMe,
  IRefreshPasswordReq,
  IUserCreateReq,
  IUserCreateRes,
} from '../types/profile.types';
import { instance } from './axios';

export const authorize = (email: string, password: string) => {
    return instance.post('/auth', {email, password})
}

export const authApi = {
    me(): Promise<IMe> {
        return instance.get('/api/v1/me')
    },
    login(data: ILogin): Promise<ILoginRes> {
        return instance.post('/api/v1/login', data)
    },
    logout(token: string): Promise<string> {
        return instance.get("/api/v1/logout", {
          headers: { "x-refresh-token": token }
        });
    },
    userCreate(data: IUserCreateReq): Promise<IUserCreateRes> {
        return instance.post('/api/v1/user', data)
    },
    refreshToken(refreshToken: string): Promise<ILoginRes> {
        return instance.post('/api/v1/refresh',
            {
                token: refreshToken
            }, {
                headers: {
                    ['x-refresh-token']: storageService.getRefreshToken
                }
            })
            .catch((e) => {
                storageService.removeTokens()
                throw e
                return e
            })
    },
    userConfirm(code: string): Promise<ILoginRes> {
        return instance.post('/api/v1/user_confirm',
            {
                code
            },
            {
                params: {
                    code
                },
            })
    },
    resendConfirmation(email: string) {
        return instance.post('/api/v1/user_resend', {
            email
        })
    },
    getCountries(): Promise<ICountry[]> {
        return instance.get('/api/v1/country')
    },
    getPhone(): Promise<any> {
        return instance.get('/api/v1/phone')
    },
    changePhone(data: any): Promise<any> {
        return instance.patch('/api/v1/phone', data)
    },
    forgotPassword(data: IForgotPasswordEmailReq) {
        return instance.post('/api/v1/user_forgot', data)
    },
    refreshPassword(data: IRefreshPasswordReq) {
        return instance.post('/api/v1/refresh_password', data)
    },
    requestChangePassword() {
        return instance.get('/api/v1/request_change_password?kind=email')
    },
    changePassword(data: IRefreshPasswordReq) {
        return instance.post('/api/v1/change_password', data)
    },
    generateQRCode(): Promise<any>{
        return instance.get('/api/v1/otp_generate')
    },
    otpChange(data: {otp?: string, enable?: boolean }): Promise<any>{
        return instance.post('/api/v1/otp_change', {enable: data.enable, otp: data.otp})
    },    
    
}
