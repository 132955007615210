import {
  FC,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';

import { QRCodeCanvas } from 'qrcode.react';

import { DialogContent } from '@mui/material';
import Box from '@mui/material/Box';

import { useModalManager } from '../../hooks/useModalManager';
import {
  StyledDialogTitleQr,
  StyledNetworkTextQr,
  StyledWarningAmberRoundedIcon,
  StyledWarningWrapper,
} from '../../pages/Wallets/components/CommonComponents.styles';
import { EModalId } from '../../types/common.types';
import { AppModal } from './AppModal';

interface IProps {
    address: string,
    coin?: string,
}

export const WalletQrModal: FC<IProps> = ({address, coin}) => {


    const ref = useRef<any>()
    const {modalId} = useModalManager()

    useEffect(() => {
        if(modalId === EModalId.walletQr) {
            const selector = document.querySelector('#my-qr-code')
            if(selector) {
                ReactDOM.render(
                    <QRCodeCanvas value={address}/>,
                    selector
                )
            }
        }
    }, [address, modalId, ref.current])

    return <AppModal keepMounted modalId={EModalId.walletQr} sx={{background: 'white'}}>
        <Box>
            <StyledDialogTitleQr>QR Code</StyledDialogTitleQr>
            <DialogContent>
                <StyledNetworkTextQr>
                    Scan the QR code for the address
                </StyledNetworkTextQr>
                <Box ref={ref} id={'my-qr-code'} display={'flex'} justifyContent={'center'} alignItems={'center'}/>

                <StyledWarningWrapper
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <StyledWarningAmberRoundedIcon />
                    <span>Only send {coin} to this address!</span>
                </StyledWarningWrapper>
            </DialogContent>
        </Box>
    </AppModal>
}