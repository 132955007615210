import ClearIcon from '@mui/icons-material/Clear'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material'
import { SnackbarOrigin } from '@mui/material/Snackbar'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import {
	allOrderCancelThunk,
	orderCancelThunk,
} from 'store/exchange/exchange.thunk'
import { IOrder } from '../../../../types/exchange.types'
import { splitNumber } from '../../../../utils/common.utils'
import {
	StyledButtonWrapper,
	StyledIconButton,
	StyledSnackbar,
	StyledWarningRoundedIcon,
	StyledWrapper,
} from './ExchangeOrders.styles'

interface IProps {
	items: IOrder[]
	updateList: () => void
}

const tHeads = ['Date', 'Market', 'Price', 'Amount', 'Value', 'Filled']

interface State extends SnackbarOrigin {
	open: boolean
}

export const ExchangeOrders: FC<IProps> = ({ items, updateList }) => {
	const dispatch = useAppDispatch()

	const { loading, error, response } = useTypedSelector(
		state => state.exchange.cancelOrder
	)

	const pairs = useTypedSelector(state => state.exchange.marketsList?.order)

	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)

	const handleCancelDeposit = () => {
		dispatch(orderCancelThunk(currentOrder?.ID || currentOrder?.id))
		handleClose()
	}

	useEffect(() => {
		if (response) {
			// updateList()
		}
	}, [response])

	const [currentOrder, setCurrentOrder] = useState<any>(null)
	const [allOrders, setAllorders] = useState<any>(null)
	const [hideOther, setHideOther] = useState<boolean>(true)
	const [pairsList, setPairsList] = useState<Record<number, string>>({})

	useEffect(() => {
		if (pairs && pairs.length) {
			pairs.forEach(pair => {
				setPairsList(prev => ({
					...prev,
					[pair.pairId]: pair.pair,
				}))
			})
		}
	}, [pairs])

	const handleClose = () => setCurrentOrder(null)
	const handleCloseClearAllOrders = () => setAllorders(null)

	const handleClearAllOrders = () => {
		//console.log('clear all orders', allOrders)
		const orderIds: number[] = []
		allOrders.forEach((order: any) => orderIds.push(order.id))
		dispatch(allOrderCancelThunk(orderIds))
		handleCloseClearAllOrders()
	}

	return (
		<Box
			sx={{
				padding: '16px 19px',
				background: '#0049DB0D',
				marginTop: 5,
			}}
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
			flexDirection={'column'}
		>
			{/* <pre style={{color: '#fff'}}>{JSON.stringify(allOrders, null, 2)}</pre> */}
			<Box
				display={'flex'}
				alignItems={'center'}
				justifyContent={'left'}
				width={'100%'}
			>
				<Typography variant={'body2'}>Open orders</Typography>
				<FormControlLabel
					sx={{
						ml: 5,
						'.MuiFormControlLabel-label': {
							color: '#80848E',
						},
					}}
					control={
						<Checkbox
							checked={hideOther}
							onChange={() => setHideOther(!hideOther)}
						/>
					}
					label='Hide other pairs'
					value={hideOther}
				/>
			</Box>
			{/* <pre style={{color: '#fff'}}>{JSON.stringify(pairsList, null, 2)}</pre> */}
			<Table>
				<TableHead>
					<TableRow>
						{tHeads.map((head, i) => (
							<TableCell align={'left'} key={i}>
								{head}
							</TableCell>
						))}
						<TableCell align={'right'}>
							<Button
								sx={{ width: 95, height: 32 }}
								size={'small'}
								variant={'contained'}
								color={'error'}
								onClick={() =>
									setAllorders(
										items.filter(order => order.status === 'open') || null
									)
								}
							>
								Cancel all
							</Button>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody
					sx={{
						'&::before': {
							content: '"@"',
							display: 'block',
							lineHeight: '15px',
							color: 'transparent',
						},
					}}
				>
					{selectedPair &&
						items
							.filter(
								item =>
									item.status === 'open' &&
									(hideOther ? item.pairID === selectedPair!.ID : true) &&
									(Number(item.filledSize) /
										(Number(item.size) ? Number(item.size) : 1)) *
										100 !=
										100
							)
							.map((item, i) => (
								<TableRow key={item.id}>
									<TableCell>
										{moment(item.createdAt).format('DD-MM-YYYY h:m:ss')}
										{/* <span>{splitDate(item.CreatedAt).date}</span>
                <span style={{ color: "white", marginLeft: 10 }}>
                  {splitDate(item.CreatedAt).time}
                </span> */}
									</TableCell>

									<TableCell>
										{selectedPair && (
											<Typography color={'white'}>
												{pairsList[item.pairID]}
											</Typography>
										)}
									</TableCell>
									<TableCell>
										<Typography
											color={item.side == 'buy' ? '#419E6A' : '#D83232'}
										>
											{item.price}
										</Typography>
									</TableCell>

									<TableCell>
										<Typography component={'span'} color={'white'}>
											{splitNumber(item.size)[0]}
										</Typography>
										{splitNumber(item.size)[1] && (
											<Typography component={'span'} color={'secondary'}>
												.{splitNumber(item.size)[1]}
											</Typography>
										)}
									</TableCell>
									<TableCell>
										<Typography component={'span'} color={'white'}>
											{item.executedValue}{' '}
										</Typography>
										{
											<Typography component={'span'} color={'secondary'}>
												{selectedPair?.QuoteCurrency}
											</Typography>
										}
									</TableCell>
									<TableCell>
										<Typography color={'#419E6A'}>
											{/* {item.filled} */}
											{(Number(item.filledSize) /
												(Number(item.size) ? Number(item.size) : 1)) *
												100}
										</Typography>
									</TableCell>

									<TableCell align={'right'}>
										<IconButton onClick={setCurrentOrder.bind(null, item)}>
											<ClearIcon
												fill={'#80848E'}
												sx={{ color: '#80848E', width: 15, height: 15 }}
											/>
										</IconButton>
									</TableCell>
								</TableRow>
							))}
				</TableBody>
			</Table>

			<div>
				<StyledSnackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					open={Boolean(currentOrder)}
					onClose={handleClose}
					message={
						<div>
							<Box display={'flex'} justifyContent={'flex-end'}>
								<StyledIconButton onClick={handleClose}>
									<CloseRoundedIcon />
								</StyledIconButton>
							</Box>
							<StyledWrapper
								display={'flex'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<StyledWarningRoundedIcon />
								<div>
									<div>
										Cancel {selectedPair?.BaseCurrency}/
										{selectedPair?.QuoteCurrency} order.
									</div>
									<div>Sent you an invite to connect.</div>
								</div>
							</StyledWrapper>

							<StyledButtonWrapper
								display={'flex'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<Button onClick={handleCancelDeposit} variant='outlined'>
									Confirm
								</Button>
								<Button onClick={handleClose}>Decline</Button>
							</StyledButtonWrapper>
						</div>
					}
				/>
			</div>
			<div>
				<StyledSnackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					open={Boolean(allOrders)}
					onClose={handleClose}
					message={
						<div>
							<Box display={'flex'} justifyContent={'flex-end'}>
								<StyledIconButton onClick={handleCloseClearAllOrders}>
									<CloseRoundedIcon />
								</StyledIconButton>
							</Box>
							<StyledWrapper
								display={'flex'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<StyledWarningRoundedIcon />
								<div>
									<div>
										Cancel all {selectedPair?.BaseCurrency}/
										{selectedPair?.QuoteCurrency} orders.
									</div>
									<div>Sent you an invite to connect.</div>
								</div>
							</StyledWrapper>

							<StyledButtonWrapper
								display={'flex'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<Button onClick={handleClearAllOrders} variant='outlined'>
									Confirm
								</Button>
								<Button onClick={handleCloseClearAllOrders}>Decline</Button>
							</StyledButtonWrapper>
						</div>
					}
				/>
			</div>
		</Box>
	)
}
