import './dashboardStyles.scss';

// import Image from 'next/image'
import { FC } from 'react';

import { useTypedSelector } from 'hooks/useTypedSelector';
import { getCoinPath } from 'utils/common.utils';
import { formatPercentage } from 'utils/exchange.utils';

import styled from '@emotion/styled';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const TrendingBlock: FC = (): JSX.Element => {
    const trending = useTypedSelector(state => state.exchange.trending)
    const emptyArr = [{},{},{}]
    const useStyles = makeStyles({
        cell: {
          fontSize: "14px",
        },
      });
      const classes = useStyles()
    return (
        <div className="background mb20  trendingBlock">
            <h3 style={{paddingTop: 24, paddingLeft: 30}}>Trending</h3>
            <StyledTableContainer sx={{boxShadow: "none", background: "#white !important", paddingTop: 0}} className="paddingSmall">
      <Table style={{  borderCollapse: 'separate', background: "#fff  !important",  borderSpacing: "10px 16px" }} aria-label="simple table">
        <TableHead style={{color: "#80848E", marginBottom: 20, fontSize: "14 !important"}}>
          <TableRow style={{marginTop: "12px"}}>
            <TableCell  style={{color: "#80848E", marginBottom: 20}}><p className={classes.cell}>Coin</p></TableCell>
            <TableCell style={{color: "#80848E", marginBottom: 20, fontSize: 14}} align="left"><p className={classes.cell}>Price</p></TableCell>
            <TableCell style={{color: "#80848E", marginBottom: 20, fontSize: 14}} align="left"><p className={classes.cell}>24h change</p></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
                trending.loading &&
                emptyArr.length && emptyArr.map((_, index) => (
                    <div className="tableBlock_grid" key={index}>
                        <div className="tableBlock_td">
                            <div className="coinLogo">
                                <Skeleton variant='circular' width={21} height={21}/>
                            </div>
                            <Skeleton variant='text' width={70} height={24}/>
                        </div>
                        <p className="tableBlock_td">
                            <Skeleton variant='text' width={70} height={24}/>
                        </p>
                        <p className="tableBlock_td green textAlignRight">
                            <Skeleton variant='text' width={70} height={24}/>
                        </p>
                    </div>

          )) }
          {(trending && trending.data && trending.data || []).map((trendCoin, index) => (
            <TableRow
              key={index}
              style={{ borderBottom: "1px solid red"}}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell style={{width: "43%", textAlign: "left", display: "flex", alignItems: "center"}} component="th" scope="row">
              <div className="coinLogo" style={{marginRight: 14}}>
                                        <img
                                            src={`/images/coins/${getCoinPath(trendCoin.baseCurrency)}`}
                                            alt={`${trendCoin.baseCurrency}/${trendCoin.quoteCurrency}`}
                                            width={21}
                                            height={21}
                                        />
                                    </div>
               <span style={{fontFamily: 'Poppins', fontWeight: 400, fontSize: "14px", color: "#000", lineHeight: "21px"}}> {trendCoin.baseCurrency.trim()}/</span>
                <span style={{fontFamily: 'Poppins', fontWeight: 400, fontSize: "14px", color: "#80848E", lineHeight: "21px"}} className="currency">{trendCoin.quoteCurrency.trim()}</span>
              </TableCell>
              <TableCell style={{width: "27%", textAlign: "left"}} align="right"><p className="tableBlock_td">{Number(trendCoin.price).toLocaleString('en-US', {style: 'currency', currency: 'EUR'})}</p></TableCell>
              <TableCell style={{width: "30%", textAlign: "left"}} align="right"> <p className={`tableBlock_td textAlignRight ${Number(trendCoin.change24H) >= 0 ? 'green' : 'red'}`}>
                                    {Number(trendCoin.change24H) >= 0 ? `+${formatPercentage(Number(trendCoin.change24H))}%` : `${formatPercentage(Number(trendCoin.change24H))}%`}
                                </p>
                                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>


                    {/* <div className="tableBlock_grid">
                        <div className="tableBlock_td">
                            <div className="coinLogo">
                                <img
                                    src="/images/coins/bitcoin.svg"
                                    alt="bitocol bitcoin icon"
                                    width={21}
                                    height={21}
                                />
                            </div>
                            ETH/
                            <span className="currency">EUR</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td green textAlignRight">
                            +3.5%
                        </p>
                    </div>
                    <div className="tableBlock_grid">
                        <div className="tableBlock_td">
                            <div className="coinLogo">
                                <img
                                    src="/images/coins/bitcoin.svg"
                                    alt="bitocol bitcoin icon"
                                    width={21}
                                    height={21}
                                />
                            </div>
                            ETH/
                            <span className="currency">EUR</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td green textAlignRight">
                            +3.5%
                        </p>
                    </div>
                    <div className="tableBlock_grid">
                        <div className="tableBlock_td">
                            <div className="coinLogo">
                                <img
                                    src="/images/coins/bitcoin.svg"
                                    alt="bitocol bitcoin icon"
                                    width={21}
                                    height={21}
                                />
                            </div>
                            ETH/
                            <span className="currency">EUR</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td green textAlignRight">
                            +3.5%
                        </p>
                    </div> */}
                {/* </div>
            </div> */}
        </div>
    )
}

const StyledTableContainer = styled(TableContainer)`
   background: white !important;
   background-color: white !important;
`

export default TrendingBlock
