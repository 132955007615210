import { AdditionalLayout } from 'components'

import EastIcon from '@mui/icons-material/East'
import { Button } from '@mui/material'

import { StyledBox, StyledPageWrapper, StyledTitle } from './Trade.styles'

export default () => {
	return (
		<AdditionalLayout hideMenu>
			<StyledPageWrapper>
				<StyledBox display='flex' justifyContent='flex-end'>
					<Button variant='outlined' endIcon={<EastIcon />}>
						Advanced trading
					</Button>
				</StyledBox>
				<StyledTitle>Bittrade Converter</StyledTitle>
			</StyledPageWrapper>
		</AdditionalLayout>
	)
}
