import { useTypedSelector } from 'hooks/useTypedSelector'
import { NavLink } from 'react-router-dom'

import { Button } from '@mui/material'

import ArrowForwardIcon from '../../assets/ArrowForwardIcon.svg'
import FinishImage from '../../assets/finish.svg'
import Slippage from '../../assets/Less-slippage.svg'
import SwapImage from '../../assets/swap.svg'
import {
	StyledBottomContent,
	StyledContent,
	StyledContentFlex,
	StyledContentFlexBlock,
	StyledContentFlexText,
	StyledContentTitle,
	StyledExchangeContent,
	StyledExchangeTitle,
	StyledRedText,
} from '../../SampleExchange.styles'

const ExchangeContent = () => {
	const { insufficientError, sellInsufficientError } = useTypedSelector(
		state => state.exchange
	)
	return (
		<>
			<StyledExchangeContent>
				<StyledExchangeTitle>Bittrade simple exchange</StyledExchangeTitle>
				<StyledContent>
					<StyledContentTitle style={{ fontFamily: 'Poppins' }}>
						Convert Euro, USDT and more to 50+ cryptocurrencies
					</StyledContentTitle>
					<StyledContentFlex>
						<StyledContentFlexBlock>
							<img src={FinishImage} alt='' />
							<StyledContentFlexText>Guaranteed price</StyledContentFlexText>
						</StyledContentFlexBlock>

						<StyledContentFlexBlock>
							<img src={Slippage} alt='' />
							<StyledContentFlexText style={{ marginLeft: 8 }}>
								Less slippage
							</StyledContentFlexText>
						</StyledContentFlexBlock>

						<StyledContentFlexBlock>
							<img src={SwapImage} alt='' />
							<StyledContentFlexText>All pairs EURO</StyledContentFlexText>
						</StyledContentFlexBlock>
					</StyledContentFlex>
				</StyledContent>
				<StyledBottomContent>
					<StyledRedText
						style={{
							color:
								insufficientError || sellInsufficientError
									? '#D83232'
									: '#414E52',
						}}
					>
						Insufficient balance?
					</StyledRedText>
					<NavLink to='/dashboard/deposit'>
						<Button
							style={{
								border: 'none',
								textTransform: 'initial',
								fontWeight: '400',
								fontSize: '16px',
								padding: 0,
								color: '#9E77ED',
							}}
							variant='outlined'
							endIcon={<img src={ArrowForwardIcon} />}
						>
							Deposit Euro
						</Button>
					</NavLink>
					<div style={{ marginTop: 20 }}>
						<img
							src='/images/payments/visa.svg'
							style={{ marginRight: 20 }}
							alt=''
						/>
						<img src='/images/payments/mastercard.svg' alt='' />
					</div>
				</StyledBottomContent>
			</StyledExchangeContent>
		</>
	)
}

export default ExchangeContent
